<template>
  <div class="w-full flex flex-col items-center">
    <div
      :class="{ 'p-4 md:p-8 max-w-[1200px]': !generalS.isTheatreMode }"
      class="w-full flex flex-col items-center">
      <div class="flex flex-col w-full max-w-[calc(138vh)] 2xl:max-w-[calc(160vh)]">
        <div class="flex flex-col w-full h-full items-center">
          <!-- relative bg-[#0F212E] w-full  rounded-t-xl flex flex-row justify-center items-center h-full     -->
          <div
            id="softswiss_game_wrapper"
            class="relative w-full child:rounded-t-lg h-0 pt-[56.25%] child:absolute child:top-0 child:left-0 child:w-full child:h-full">
            
          </div>
          <game-actions
            :document-id="'softswiss_game_wrapper'"
            :game-data="gameData"
            class="bg-secondary-light rounded-b-lg" />
        </div>
        <game-details
          v-if="gamedata"
          :game="gamedata.title"
          :provider="gamedata.provider.name"
          class="mt-6 md:mt-12" />
      </div>
    </div>
  </div>
  <template class="flex flex-col items-center">
    <game-history-table class="hidden md:flex w-full max-w-[1200px]" />
  </template>
</template>

<script>
import { mapGetters } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';

import GameHistoryTable from '../GameHistory/BetsHistory.vue';
import GameActions from '../Shared/GameActions.vue';
import { oaksStore } from '@/store/pinia/oaksStore';
import FiatDropdown from '@/components/Bank/FiatDropdown.vue';
import { balanceStore } from '@/store/pinia/balanceStore';
import { generalStore } from '@/store/pinia/generalStore';
import GameDetails from '@/components/Shared/GameDetails.vue';

export default {
  name: 'oaks',
  components: {
    GameDetails,
    FiatDropdown,
    GameHistoryTable,
    GameActions,
  },
  setup() {
    const oaksS = oaksStore();
    const balS = balanceStore();
    const generalS = generalStore();
    return { oaksS, balS, generalS };
  },
  data() {
    return {
      loading: false,
      gameDispatcher: null,
      demoLoaded: false,
      gamedata: null,
      game: '',
      provider: '',
    };
  },
  async created() {
    await this.init();
    await this.launchGame(false, false);
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
    ...mapGetters('Sound', ['isSoundMuted']),
    gameData() {
      if (this.gamedata) {
        return { id: this.gamedata.id, name: null, software: '3oaks' };
      }
      return { id: null, name: null };
    },
  },
  watch: {
    '$route.params.name': {
      async handler() {
        await this.init();
        //auto launch demo mode on game started
        //await this.launchGame(true, true)
      },
      // force eager callback execution
      immediate: true,
    },
    'balS.defaultFiat': {
      async handler() {
        // const isDemo = this.pragmaticGame ? this.pragmaticGame.has_live : true
        // await this.launchGame(isDemo, !this.gameDispatcher)
        await this.launchGame(false, true);
      },
      // force eager callback execution
      immediate: true,
    },
  },
  methods: {
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.pause();
        sound.currentTime = 0;
        sound.play();
      }
    },
    async init() {
      try {
        this.gamedata = null;
        this.loading = true;
        this.parseGameName(this.$route.params.name);
        const { data } = await this.oaksS.fetchGame(`${this.game}`);
        this.gamedata = data;
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    parseGameName(game) {
      

      this.provider = game.split('-')[0];
      this.game = game
        .split('-')
        .slice(1)
        .map((element) => {
          return element[0] + element.slice(1);
        })
        .join('');
    },
    async launchGame(isDemo = true, isInitial = false) {
      if (!isDemo && !this.isLoggedIn) {
        await this.$router.push('/auth/login');
        return;
      }

      if (!this.gamedata || isDemo) {
        return;
      }

      try {
        if (this.demoLoaded && isDemo) {
          this.gameDispatcher = 'gameDispatcher';
          return;
        }

        if (!isInitial) {
          this.loading = true;
        }

        const { data } = await this.oaksS.createGameSession(
          isMobileOnly,
          this.gamedata.game_id,
          isDemo,
        );

        const gameLaunchOptions = { target_element: 'softswiss_game_wrapper', ...data };
        const onSuccess = (gameDispatcher) => {
          if (!isInitial) {
            this.gameDispatcher = 'gameDispatcher';
          } else {
            this.demoLoaded = true;
          }
          this.loading = false;
        };
        const onError = (error) => {
          this.loading = false;
        };
        window.sg.launch(gameLaunchOptions, onSuccess, onError);
      } catch (e) {
        const { data } = e;
        if (data) {
          if ('message' in data) {
            if (data.code === 700) {
              // this.pragmaticSwisGame.has_live = true
              if (isDemo) {
                return;
              }
            }
            this.$toast.error(data.message);
          } else if ('error' in data) {
            if((typeof data.error !=  undefined ))
              this.$toast.error(data.error);
          }
        } else {
          this.$toast.error('Unable to launch game. Refresh then try again.');
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.justify-content-start {
  justify-content: flex-start;
}

.centralizer {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 100%;
  padding-bottom: 1em;
}

.control.has-text-centered {
  text-align: center;
}

.is-uppercase {
  text-transform: uppercase;
}

.has-pointer-cursor {
  cursor: pointer;
}

.has-south-east-stickiness {
  align-self: flex-end;
}

.panel table {
  margin-bottom: 0px;
}
</style>

<style scoped>
iframe {
}
</style>
