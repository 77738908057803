<template>
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 sm:px-6 max-[480px]:pt-0">
        <div class="p-0 md:p-4 text-sm rounded-lg border-slate-300 text-slate-300">
          <p>
            Welcome to Chanced's new Referral Bonus Program! The new program aims 
            to provide a more rewarding experience for both new and existing players.
            The new referral bonus is rewarded on top of the old commission based bonus.
            Share your referral key and enjoy both bonuses for your referral.
            Here's how it works for the new bonus:
          </p>
          <br />
  
          <p>
            For every new player you refer, you will receive 100,000 Gold Coins and 
            15 Sweep Coins when your referral accumulates $20 in Gold Coin package purchases. 
            You can use your Gold Coins or Sweep Cash to play or redeem!
          </p>
          <br />
  
          <div class="relative">
            <div class="absolute inset-y-0 flex items-center pl-3 right-2">
              <button :disabled="!me.id" class="mt-4" v-on:click="copyAffiliateCode">
                <c-clipboard class="w-6 h-6" />
              </button>
            </div>
            <span class="pb-2 text-slate-300">Referral Link</span>
            <span class="block h-12 w-full rounded-lg border border-primary bg-primary p-2.5 pr-12 text-sm font-semibold text-slate-300 placeholder:text-text-dim-color focus:border-accent focus:ring-accent">
              {{ code }}
            </span>
          </div>
  
          <div class="relative flex justify-between pt-12 pb-12">
            <div class="flex-col items-center">
              <span class="text-slate-300 text-2xl font-bold items-center">{{signups}}</span> <br />
              <span class="text-slate-300">Sign-ups</span>
            </div>
            <div class="flex-col items-center">
              <span class="text-slate-300 text-2xl font-bold items-center">{{qualifiedReferrals}}</span> <br />
              <span class="pb-2 text-slate-300">Qualified Referrals (max 10)</span>
            </div>
            <div class="flex-col items-center">
              <span class="text-amber-500 text-2xl font-bold items-center">GC {{totalAwardedGC}}</span> <br />
              <span class="pb-2 text-slate-300">Total Awarded</span>
            </div>
            <div class="flex-col items-center">
              <span class="text-purple-400 text-2xl font-bold items-center">SC {{totalAwardedSC}}</span> <br />
              <span class="pb-2 text-slate-300">Total Awarded</span>
            </div>
          </div>
  
          <div class="relative flex justify-between bg-primary rounded-lg p-2.5">
            <div class="flex-col">
              <span class="text-slate-300 text-xl font-bold items-center">How does it work?</span> <br />
              <ol class="list-decimal list-inside space-y-2 pl-4 pt-4">
                <li class="text-slate-300 font-bold pb-1">Send an invitation</li>
                <span class="text-slate-300">Share your unique referral link with your friends and invite them to join Chanced.</span>
                <li class="text-slate-300 font-bold pb-1 pt-2">Your friend signs up and gets qualified</li>
                <span class="text-slate-300">Your friend should sign up using the link, verify their account and make a qualifying purchase.</span>
                <li class="text-slate-300 font-bold pb-1 pt-2">Collect your rewards</li>
                <span class="text-slate-300">Enjoy your </span>
                <span class="text-amber-500">100,000 GC</span>
                <span class="text-slate-300"> and </span>
                <span class="text-purple-400">15 SC</span>
                <span class="text-slate-300"> when your referral accumulates $20 in Gold Coin package purchases.</span>
              </ol>
            </div>
            <div class="flex-col p-8">
              <img 
                src="/src/assets/sidebar/newreleases.png" 
                alt="Referral Bonus Program" 
                class="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { affiliatesStore } from '@/store/pinia/affiliatesStore';
  import { mapGetters, mapActions } from 'vuex';
  import { copyToClipboard } from '@/lib/utils';
  import CClipboard from '../Shared/CopyToClipboard.vue';
  
  export default {
    name: 'NewReferralBonus',
    components: {
      CClipboard,
    },
    setup() {
      const affStore = affiliatesStore();
      return { affStore };
    },
    data() {
      return {
        code: null,
        referralCodes: [],
        signups: 0,
        qualifiedReferrals: 0,
        totalAwardedGC: 0,
        totalAwardedSC: 0,
      };
    },
    mounted() {
      const userId = this.me.id;
      if (userId) {
        this.fetchAll(userId);
        this.getReferralCodes(userId).then((res) => {
          this.referralCodes = res.data;
          if (this.referralCodes.length > 0) {
            this.code = import.meta.env.VITE_FRONTEND_URL + 'c/' + this.referralCodes[0];
          } else {
            this.code = import.meta.env.VITE_FRONTEND_URL + 'c/' + userId;
          }
        });
      }
    },
    methods: {
      async fetchAll(userId) {
        try {
          const response = await this.affStore.fetchNewBonusStats(userId);
          this.signups = response.data.total_signups;
          this.qualifiedReferrals = response.data.total_qualified_referrals;
          this.totalAwardedGC = response.data.total_awarded_gc;
          this.totalAwardedSC = response.data.total_awarded_sc;
        } catch (error) {
          this.$toast.error('Cannot fetch affiliate data! <br>' +  ((typeof error.data.error !=="undefined")?error.data.error:'') );
        }
      },
  
      copyAffiliateCode() {
        copyToClipboard(this.code);
      },
      ...mapActions(['getReferralCodes']),
    },
    computed: {
      ...mapGetters(['me']),
    },
  };
  </script>