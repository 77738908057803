<template>
  <div class="flex w-full">
    <div class="flex w-full flex-row items-start py-2 sm:px-0 md:px-2">
      <perfect-scrollbar
        ref="hScrollbar"
        class="no-scrollbar flex overflow-x-scroll rounded-full bg-secondary">
        <div
          class="w-fsull ovesrflow-x-scroll grid w-full auto-cols-max grid-flow-col grid-cols-none grid-rows-1 flex-nowrap gap-0.5 sm:gap-6 p-2">
          <template v-for="(item) in getHomeAndPageCategories" :key="item.slug">
            <button
              v-on:click="changeTab(item.id, item.slug)"
              class="flex h-10 w-full min-w-fit items-center justify-center space-x-2 rounded-full px-4 py-2.5 text-sm font-medium leading-5 text-white focus:outline-none"
              :class="[
                selectedTab === item.id
                  ? 'bg-accent shadow-btn'
                  : 'text-white hover:bg-secondary-light/70 hover:text-white',
              ]">
              <span
                class="flex w-full items-center justify-center gap-2 whitespace-nowrap">
                <img
                  :src="getCategoryIcon(item)"
                  @error="loadFallbackImage"
                  :data-fallback="getDefaultCategoryIcon()"
                  class="h-6 sm:h-8 justify-center"
                  :alt="item.name" />
                {{ item.name }}
              </span>
            </button>
          </template>
        </div>
      </perfect-scrollbar>
      <TabGroup
        :selectedIndex="selectedTab"
        @change="changeTab"
        as="div"
        class="min-w-md hidden max-w-3xl flex-1 overflow-x-auto">
      </TabGroup>
    </div>
  </div>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import { icons } from '../Shared/icons';
import { mapGetters } from 'vuex';
import {getCategoryIcon, getDefaultCategoryIcon, loadFallbackImage} from "@/utils";

const tabs = [
  //{ key: 'originals', icon: icons.Originals, name: 'Chanced Originals' },
  { key: 'live', icon: icons.Live, name: 'Live Dealers' },
  { key: 'slots', icon: icons.Slots, name: 'Featured Slots' },
  // {key: 'game-shows', icon: icons.GameShows,name: 'Game Shows'}
];
export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  props: {
    refreshSelectedProviders: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      items: tabs,
      tab: 'all',
      selectedTab: 0,
    };
  },
  computed: {
    ...mapGetters(['categoryOptions', 'categoryOptionsFetched']),
    getHomeAndPageCategories() {
      return this.categoryOptions.filter((category) => {
        return category.show_in_menu && category.show_in_home && !category.data?.hide_pills
      })
    }
  },
  mounted() {
    this.scrollToSelected();
  },
  methods: {
    getCategoryIcon,
    loadFallbackImage,
    getDefaultCategoryIcon,
    changeTab(id, slug) {
      this.selectedTab = id;
      if (slug !== 'undefined' && id !== 'undefined') {
        // this.scrollToSelected();
        this.tab = id;
        this.refreshSelectedProviders();

        this.$router.push({
          path: '/casino/group/' + slug,
          query: { ...this.$route.query },
        });
      }
    },
    scrollToSelected() {
      const element = this.$refs.hScrollbar.$el;
      let scrollBarWidth = element.scrollWidth;
      const tab = Math.max(0, this.selectedTab - 1);
      const scrollTo = (tab / 4) * scrollBarWidth;
      element.scrollTo({
        left: scrollTo,
      });
    },
    async tabChangeHandler() {
      try {
        const selectedTab = this.categoryOptions.find(
            (item) => item.slug === this.$route.params.name,
        );
        this.selectedTab = 0;
        this.changeTab(selectedTab.id, selectedTab.slug);
      } catch (e) {
      }
    }
  },
  watch: {
    '$route.params.name': {
      handler: async function () {
        this.tabChangeHandler()
      },
      deep: true,
      immediate: true,
    },
    categoryOptionsFetched: async function (newState) {
      if(newState)
        this.tabChangeHandler();
    },
    searchText() {
      this.debouncedSearch();
    },
  },
};
</script>
