<template>
  <punt-modal size="max-w-xl " show-close="true">
    <template #title>
      <span></span>
    </template>
    <template #body>
      <div
        class="text-sm font-medium text-center text-gray-500 border-b border-line-color">
        <ul class="flex flex-wrap -mb-px space-x-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="white"
            stroke-width="1">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
          </svg>
          <li class="text-white">
            <span style="font-size: 18px">Statistics</span>
          </li>
        </ul>
        <br />
      </div>
      <div class="flex flex-col">
        <div v-if="!loading">
          <p class="mt-4 block text-sm font-medium text-white text-center">
            {{ this.me.username }}
          </p>
          <p class="block mb-2 text-sm font-medium text-text-dim-color text-center pr-2">
            Joined {{ this.formatDate(this.me.created_at) }}
          </p>

          <section class="min-h-[380px] flex flex-col py-4">
            <div class="flex mb-4">
              <div class="w-1/2 px-4 h-12">
                <a
                  href="#"
                  class="block p-4 max-w-sm bg-side-color rounded-lg text-center">
                  <span class="mt-1 inline text-sm text-white">
                    {{ this.userStat.total_bets ? this.userStat.total_bets : 0 }}
                  </span>
                  <p
                    class="mt-4 block mb-2 text-sm font-medium text-text-dim-color text-center">
                    Games Played
                  </p>
                </a>
              </div>
              <div class="w-1/2 px-4 h-12">
                <a
                  href="#"
                  class="block p-4 max-w-sm bg-side-color rounded-lg text-center">
                  <span class="mt-1 inline text-sm text-white">
                    {{ balS.getCurrencyContext(parseFloat(this.userStat.wagered)) }}
                    <img
                      :src="
                        getBalanceIcon(
                          balS.displayInFiat ? balS.defaultFiat : balS.default,
                        )
                      "
                      alt="Currency Icon"
                      class="inline h-4 w-4" />
                  </span>
                  <p
                    class="mt-4 block mb-2 text-sm font-medium text-text-dim-color text-center">
                    Total Played
                  </p>
                </a>
              </div>
            </div>
            <div class="flex mb-4 py-12">
              <div class="w-1/2 px-4 h-12">
                <a
                  href="#"
                  class="block p-4 max-w-sm bg-side-color rounded-lg text-center">
                  <span class="mt-1 inline text-sm text-white">
                    {{ this.userStat.won ? this.userStat.won : 0 }}
                  </span>
                  <p
                    class="mt-4 block mb-2 text-sm font-medium text-text-dim-color text-center">
                    Total Wins
                  </p>
                </a>
              </div>
              <div class="w-1/2 px-4 h-12">
                <a
                  href="#"
                  class="block p-4 max-w-sm bg-side-color rounded-lg text-center">
                  <span class="mt-1 inline text-sm text-white">
                    {{ this.userStat.lost ? this.userStat.lost : 0 }}
                  </span>
                  <p
                    class="mt-4 block mb-2 text-sm font-medium text-text-dim-color text-center">
                    Total Loss
                  </p>
                </a>
              </div>
            </div>
            <div class="flex mb-4">
              <div class="w-1/2 px-4 h-12">
                <a
                  href="#"
                  class="block p-4 max-w-sm bg-side-color rounded-lg text-center">
                  <span class="mt-1 inline text-sm text-white">
                    {{ balS.getCurrencyContext(parseFloat(this.userStat.big_wager)) }}
                    <img
                      :src="
                        getBalanceIcon(
                          balS.displayInFiat ? balS.defaultFiat : balS.default,
                        )
                      "
                      alt="Currency Icon"
                      class="inline h-4 w-4" />
                  </span>
                  <p
                    class="mt-4 block mb-2 text-sm font-medium text-text-dim-color text-center">
                    Highest Play
                  </p>
                </a>
              </div>
              <div class="w-1/2 px-4 h-12">
                <a
                  href="#"
                  class="block p-4 max-w-sm bg-side-color rounded-lg text-center">
                  <span class="mt-1 inline text-sm text-white">
                    {{ balS.getCurrencyContext(parseFloat(this.userStat.big_win)) }}
                    <img
                      :src="
                        getBalanceIcon(
                          balS.displayInFiat ? balS.defaultFiat : balS.default,
                        )
                      "
                      alt="Currency Icon"
                      class="inline h-4 w-4" />
                  </span>
                  <p
                    class="mt-4 block mb-2 text-sm font-medium text-text-dim-color text-center">
                    Highest Win
                  </p>
                </a>
              </div>
            </div>
          </section>
        </div>
        <div
          v-else
          class="flex flex-row flex-grow p-8 justify-center items-center min-h-40 rounded-md h-full">
          <svg
            class="animate-spin -ml-1 mr-3 h-10 w-10 text-white"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"></circle>
            <path
              class="opacity-75"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import axios from '../../lib/myAxios';

import PuntModal from '@/components/Shared/PuntModal.vue';
import { convertGameMode, defaultConvertor } from '../../lib/rsgp';
import {
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  Switch,
  SwitchGroup,
  SwitchLabel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/vue';
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';
import { mapGetters } from 'vuex';

const pages = ['userstats'];
const items = [
  { key: 'stats', name: 'Statistics' },
  { key: 'trophies', name: '' },
];

export default {
  name: 'UserStats',
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    Switch,
    SwitchGroup,
    SwitchLabel,
    PopoverOverlay,
    PuntModal,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  setup() {
    const balS = balanceStore();
    return { balS, getBalanceIcon };
  },
  data() {
    return {
      activeTab: 'userstats',
      amounts: { rs3: 0, osrs: 0 },
      contributions: [],
      loading: true,
      items: items,
      userStat: {},
    };
  },
  async created() {
    await this.fetchStats();
    this.loading = false;
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
  methods: {
    formatDate(myDate) {
      return new Date(myDate).toLocaleDateString('en-us', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },

    getIcon(currency) {
      return getBalanceIcon(currency);
    },
    close() {
      this.$router.go(-1);
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    currency(mode) {
      return convertGameMode(mode);
    },
    async fetchStats() {
      try {
        await axios
        .get('userstats')
        .then((res) => {
          if (res.data !== null || res.data !== 'undefined') {
            this.userStat = res.data[0];
            this.loading = false;
          }
        })
        .catch((data) => {
          this.loading = false;
        });
      } catch(error) {
        console.log("issue while fetching status");
      }
    },
  },
};
</script>
