<template>
  <div id="games" class="flex flex-col casino-games-section section min-[993px]:flex-row">
    <div class="container relative flex flex-col min-[993px]:flex-row">
      <div
        class="relative flex min-[993px]:w-7/12 flex-col items-left justify-center slider-col enjoy_slider">
        <div class="z-40 w-full content-wrapper medium big max-w-[475px] mobile">
          <h2 class="title">PLAY <span>FREE-TO-PLAY</span> GAMES!</h2>
        </div>
        <div class="swiper-outer grid">
          <swiper
            class="games-slider"
            :modules="modules"
            grabCursor="true"
            :loop="true"
            :autoplay="{
              delay: 4000,
            }"
            :loopedSlides="2"
            :speed="500"
            :navigation="{
              enabled: true,
              nextEl: '.game-slider-next',
              prevEl: '.game-slider-prev',
            }"
            :effect="'creative'"
            :creativeEffect="{
              prev: {
                translate: ['-65%', 0, -400],
              },
              next: {
                translate: ['65%', 0, -400],
              },
            }">
            <swiper-slide class="game-slide">
              <div class="image-wrapper">
                <img src="/images/home/game-1.webp" alt="" />
                <h4 class="i-title">Banana Town</h4>
              </div>
            </swiper-slide>
            <swiper-slide class="game-slide">
              <div class="image-wrapper">
                <img src="/images/home/game-2.webp" alt="" />
                <h4 class="i-title">Gates of Olympus</h4>
              </div>
            </swiper-slide>
            <swiper-slide class="game-slide">
              <div class="image-wrapper">
                <img src="/images/home/game-3.webp" alt="" />
                <h4 class="i-title">Twilight Princess</h4>
              </div>
            </swiper-slide>
            <swiper-slide class="game-slide">
              <div class="image-wrapper">
                <img src="/images/home/game-4.webp" alt="" />
                <h4 class="i-title">Sweet Bonanza</h4>
              </div>
            </swiper-slide>
            <swiper-slide class="game-slide">
              <div class="image-wrapper">
                <img src="/images/home/game-5.webp" alt="" />
                <h4 class="i-title">Wanted Dead or a Wild</h4>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="relative flex min-[993px]:w-5/12 flex-col items-end justify-center">
        <div class="z-40 w-full content-wrapper medium big max-w-[475px]">
          <h2 class="title desktop">
            PLAY <span>FREE-TO-PLAY</span> GAMES!
          </h2>
          <div class="navigation-button">
            <div class="arrow left game-slider-prev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none">
                <path
                  d="M1 7L7 1M1 7L7 13M1 7H17"
                  stroke="#E9FF5F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
            <div class="arrow right game-slider-next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none">
                <path
                  d="M17 7L11 1M17 7L11 13M17 7H1"
                  stroke="#E9FF5F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-1 rotatecasinocoin">
        <img src="/images/home/game-s-1.webp" alt="" />
      </div>
      <div class="bg-2">
        <img src="/images/home/game-s-2.webp" alt="" />
      </div>
    </div>
    <div class="bg-3">
      <img src="/images/home/game-s-3.webp" alt="" />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-creative';
import { EffectCreative, Navigation, Autoplay } from 'swiper';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [EffectCreative, Navigation, Autoplay],
    };
  },
  name: 'CasinoGames',
};
</script>
