<template>
  <div
    class="flex justify-center w-full h-full md:fixed md:top-0 md:bottom-0 md:left-0 md:right-0 bg-[rgb(55_65_81_/_40%)] backdrop-filter backdrop-blur-[3px] max-[575px]:h-[calc(100%_-_125px)]"
    style="z-index: 1000000"
    v-on:click="clearAndClose">
    <div
      class="h-full w-full text-center md:mt-[6%] md:mr-[2%] md:h-[75%] md:w-[60%] md:rounded-lg md:bg-primary"
      v-on:click.stop>
      <div class="flex flex-col w-full h-full max-h-full px-2 py-2 md:px-8 md:py-4">
        <div
          class="relative flex flex-wrap items-center justify-between w-full gap-2 px-3 py-4 text-slate-300 md:px-6 max-[767px]:pt-2">
          <div class="flex flex-row items-center gap-1.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="hidden w-5 h-5 rotate-90 md:block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
            </svg>
            <span class="hidden text-sm md:block"> Filter By </span>
            <CategoryFilters class="pr-1"
              :categories="categories"
              :toggle-category="toggleCategory"
              :clear-all="clearCategories"
              :selected-categories="selectedCategories" />
            <StatusFilters class="pr-4"
              :statuses="statuses"
              :toggle-status="toggleStatus"
              :selected-status="status" />
            <button
              v-if="showMarkRead"
              class="text-sm flex flex-row border border-slate-300 rounded px-1 py-1 transition-colors duration-200"
              :class="{'hover:bg-red-500': !isMobile}"
              @click="updateStatus('read')">Mark Read</button>
            <button
              v-if="showMarkStarred"
              class="text-sm flex flex-row border border-slate-300 rounded px-1 py-1 transition-colors duration-200"
              :class="{'hover:bg-red-500': !isMobile}"
              @click="updateStatus('starred')">Mark Starred</button>
          </div>
          <div class="flex flex-row items-center gap-1.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="hidden w-5 h-5 rotate-90 md:block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
            </svg>
            <span class="hidden text-sm md:block"> Sort By </span>
            <SortFilters :sort="sortOrder" :change-sort-order="changeSortOrder" />
          </div>
        </div>
        <perfect-scrollbar
          ref="scrollbar"
          class="ps" 
          v-if="this.notifications.length > 0 || loading">
          <div class="flex items-center justify-center h-5 pt-6" v-if="loading">
            <svg
              class="h-10"
              id="L4"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve">
              <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1" />
              </circle>
              <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2" />
              </circle>
              <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3" />
              </circle>
            </svg>
          </div>
          <table class="w-full table-auto" v-if="this.notifications.length > 0">
            <thead class="">
              <tr>
                <th class="text-medium font-bold text-gray-400 text-left" scope="col">
                  <input type="checkbox" @click="toggleAllNotifications" :checked="allNotificationsSelected">
                </th>
                <th class="text-medium font-bold text-gray-400 text-left" scope="col">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in notifications" v-bind:key="index">
                <td class="py-4 flex items-center">
                  <input type="checkbox" v-model="selectedNotifications" :value="item">
                </td>
                <td colspan="3" class="py-4 text-sm font-medium text-slate-300 text-left">
                  <div class="flex flex-col gap-1">
                    <span class="text-white" v-html="item.description"></span>
                    <div class="flex flex-col text-sm text-slate-300 text-left">
                      <span>Category: {{ item.category }}</span>
                      <span>Created at: {{ formatDate(item.created_at) }}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </perfect-scrollbar>
        <div v-if="this.lastPage > 1 && !loading"
          class="flex justify-center items-center mt-4 space-x-2">
          <button
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
            class="px-3 py-1 rounded-md bg-gray-700 text-white disabled:opacity-50"
            >
              Previous
            </button>
            <span class="text-white">
              Page {{ currentPage }} of {{ lastPage }}
            </span>
            <button
              @click="changePage(currentPage + 1)"
              :disabled="currentPage === lastPage"
              class="px-3 py-1 rounded-md bg-gray-700 text-white disabled:opacity-50"
            >
              Next
          </button>
        </div>
        <span v-if="this.notifications.length === 0"
          class="flex items-center justify-center flex-1 w-full h-full text-lg font-bold text-slate-300/70">
          No {{this.status}} notifications
        </span>
      </div>
    </div>
  </div> 
</template>

<script>

import axios from '@/lib/myAxios';
import { generalStore } from '@/store/pinia/generalStore';
import { mapGetters } from 'vuex';
import CategoryFilters from '@/components/Notifications/CategoryFilters.vue';
import SortFilters from '@/components/Notifications/SortFilters.vue';
import StatusFilters from '@/components/Notifications/StatusFilters.vue';
import { isMobile } from 'mobile-device-detect';
import { getErrorCodeMessage } from '@/utils';

export default {
  name: 'NotificationCenter',
  components: {
    CategoryFilters,
    StatusFilters,
    SortFilters,
  },

  setup() {
    const generalS = generalStore();
    return { generalS };
  },

  data() {
    return {
      loading: false,
      notifications: [],
      selectedNotifications: [],
      categories: [],
      selectedCategories: [],
      statuses: [],
      status: 'unread',
      sortOrder: 'desc',
      showMarkRead: true,
      showMarkStarred: true,
      allNotificationsSelected: false,
      currentPage: 0,
      lastPage: 0,
      perPage: 25,
      isMobile: isMobile,
    };
  },

  computed: {
    ...mapGetters(['me']),
  },

  mounted() {
    this.fetchFilters();
    this.fetchNotifications();
  },

  unmounted() {
    this.clearAndClose();
  },

  methods: {
    async fetchFilters() {
      const { data } = await axios.get(`notifications/filters`);
      this.categories = data.categories;
      this.statuses = data.statuses;
    },

    async fetchNotifications(loadMore = false) {
      try {
        this.loading = true;
        const params = {
          id: this.me.id,
          categories: this.selectedCategories.join(','),
          status: this.status,
          sort_order: this.sortOrder,
          page: loadMore ? this.currentPage : 1,
          per_page: this.perPage
        };
        const { data } = await axios.get(`users/${params.id}/notifications`, { params });
        this.notifications = data.data;
        this.currentPage = data.current_page;
        this.lastPage = data.last_page;
      } catch (e) {
        const { message } = getErrorCodeMessage(e, 'Unable to fetch notifications!');
        this.$toast.error(message);
      } finally {
        this.loading = false;
      }
    },

    async updateStatus(status) {
      if (this.selectedNotifications.length === 0) {
        return;
      }
      try {
        const params = {
          notificationIds: this.selectedNotifications.map((x) => x.id),
          status: status,
        };
        await axios.post(`notifications/update-status`, { params });
        this.resetScrollAndCheckboxes();
        await this.fetchNotifications();
      } catch (e) {
        const { message } = getErrorCodeMessage(e, 'Unable to update notifications status!');
        this.$toast.error(message);
      }
    },

    async toggleStatus(status) {
      if (status == 'read') {
        this.showMarkRead = false;
        this.showMarkStarred = true;
      } else if (status == 'starred') {
        this.showMarkRead = true;
        this.showMarkStarred = false;
      } else {
        this.showMarkRead = true;
        this.showMarkStarred = true;
      }
      this.status = status;
      this.resetScrollAndCheckboxes();
      await this.fetchNotifications();
    },

    async toggleCategory(category) {
      const selected = this.selectedCategories.find((x) => {
        return x === category;
      });
      if (selected) {
        this.selectedCategories = this.selectedCategories.filter((x) => x !== category);
      } else {
        this.selectedCategories.push(category);
      }
      this.resetScrollAndCheckboxes();
      await this.fetchNotifications();
    },

    async clearCategories() {
      this.selectedCategories = [];
      this.resetScrollAndCheckboxes();
      await this.fetchNotifications();
    },

    async changeSortOrder(order) {
      if (this.sortOrder !== order) {
        this.sortOrder = order;
        this.resetScrollAndCheckboxes();
        await this.fetchNotifications();
      }
    },

    async changePage(page) {
      this.currentPage = page;
      this.resetScrollAndCheckboxes();
      await this.fetchNotifications(true);
    },

    toggleAllNotifications() {
      if (this.allNotificationsSelected) {
        this.resetScrollAndCheckboxes();
      } else {
        this.selectedNotifications = this.notifications;
        this.allNotificationsSelected = true;
      }
    },

    formatDate(date) {
      return new Intl.DateTimeFormat('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC'
      }).format(new Date(date));
    },

    clearAndClose() {
      this.selectedCategories = [];
      this.resetScrollAndCheckboxes();
      this.status = 'unread';
      this.sortOrder = 'desc';
      this.generalS.showNotificationCenter = false;
    },

    resetScrollAndCheckboxes() {
      this.allNotificationsSelected = false;
      this.selectedNotifications = [];
      if (this.$refs.scrollbar) {
        const element = this.$refs.scrollbar.$el;
        element.scrollTop = 0;
      }
    },    
  },
};
</script>