import _, { clamp } from 'lodash';
import axios from '@/lib/myAxios';

const RouletteMutation = {
  PLACING_BETS: 'PLACING_BETS',
  REMOVING_BETS: 'REMOVING_BETS',
  PLACE_BETS: 'PLACE_BETS',
  SCALE_BETS: 'SCALE_BETS',
  REMOVE_BETS: 'REMOVE_BETS',
  CLEAR_BETS: 'CLEAR_BETS',
  CLEAR_BALL: 'CLEAR_BALL',
  UPDATE_BETS_PER_CHIP: 'UPDATE_BETS_PER_CHIP',
  SPINNING_BALL: 'SPINNING_BALL',
  ENABLE_BETTING: 'ENABLE_BETTING',
  DISABLE_BETTING: 'DISABLE_BETTING',
  SAVE_PLACED_BETS: 'SAVE_PLACED_BETS',
  RESTORE_PLACED_BETS: 'RESTORE_PLACED_BETS',
  UNDO_BETS: 'UNDO_BETS',
  ADD_TO_HISTORY: 'ADD_TO_HISTORY',
};

export default {
  namespaced: true,
  state: {
    animatingBall: false,
    bettingEnabled: true,
    removingBets: false,
    placedBets: Array(51).fill(0),
    betHistory: [],
    betsPerChip: 1,
    backedUpBets: null,
  },
  getters: {
    animatingBall: (state) => state.animatingBall,
    allowPlacingBets: (state, getters, rootState) =>
      getters.totalBets + state.betsPerChip <= rootState.user.balance,
    bettingEnabled: (state) => state.bettingEnabled,
    removingBets: (state) => state.removingBets,
    placedBets: (state) => state.placedBets,
    totalChips: (state) => state.placedBets.reduce((c, b) => c + b, 0),
    totalBets: (state) => state.betsPerChip * state.placedBets.reduce((c, b) => c + b, 0),
    totalInsideBets: (state) =>
      state.betsPerChip *
      state.placedBets.reduce((c, b, i) => (i < 39 && i >= 0 ? c + b : 0), 0),
    totalOutsideBets: (state) =>
      state.betsPerChip * state.placedBets.reduce((c, b, i) => (i > 38 ? c + b : 0), 0),
    betsPerChip: (state) => state.betsPerChip,
  },
  mutations: {
    [RouletteMutation.PLACING_BETS](state) {
      state.removingBets = false;
    },

    [RouletteMutation.REMOVING_BETS](state) {
      state.removingBets = true;
    },

    [RouletteMutation.PLACE_BETS](state, slot) {
      state.placedBets[slot.slot] = state.placedBets[slot.slot] + slot.chips;
    },

    [RouletteMutation.SCALE_BETS](state, slot) {
      const newValue = state.placedBets[slot.slot] * slot.value;
      state.placedBets[slot.slot] = clamp(newValue, 0, newValue);
    },

    [RouletteMutation.UPDATE_BETS_PER_CHIP](state, bpc) {
      state.betsPerChip = bpc;
    },

    [RouletteMutation.REMOVE_BETS](state, slot) {
      state.placedBets[slot] = 0;
    },

    [RouletteMutation.CLEAR_BETS](state, slot) {
      state.placedBets = Array(51).fill(0);
    },

    [RouletteMutation.SAVE_PLACED_BETS](state) {
      state.backedUpBets = _.clone(state.placedBets);
    },
    [RouletteMutation.UNDO_BETS](state) {
      if (state.betHistory.length > 0) {
        state.placedBets = state.betHistory.pop();
      }
    },

    [RouletteMutation.ADD_TO_HISTORY](state) {
      let clone = _.clone(state.placedBets);
      state.betHistory.push(clone);
    },

    [RouletteMutation.RESTORE_PLACED_BETS](state) {
      state.placedBets = _.clone(state.backedUpBets);
    },

    [RouletteMutation.SPINNING_BALL](state) {
      state.animatingBall = true;
      state.tableLocked = true;
    },
    [RouletteMutation.ENABLE_BETTING](state) {
      state.animatingBall = false;
      state.bettingEnabled = true;
    },
    [RouletteMutation.DISABLE_BETTING](state) {
      state.animatingBall = true;
      state.bettingEnabled = false;
    },
  },
  actions: {
    enablePlacingBets({ commit }) {
      commit(RouletteMutation.PLACING_BETS);
    },
    enableRemovingBets({ commit }) {
      commit(RouletteMutation.REMOVING_BETS);
    },
    backupBets({ commit }) {
      commit(RouletteMutation.SAVE_PLACED_BETS);
    },
    restoreBets({ commit, getters, state, rootState }) {
      if (!state.bettingEnabled || state.removingBets) {
        return;
      }

      if (!getters.allowPlacingBets) {
        return;
      }

      if (state.backedUpBets !== null && state.backedUpBets.length !== 0) {
        let newWager = state.backedUpBets.reduce((c, b) => c + b, 0) * state.betsPerChip;
        if (newWager > rootState.user.balance) {
          window.swal(
            'Uh-oh',
            'Your last play exceeds your current balance. Failed to restore last play',
          );
          return;
        }

        commit(RouletteMutation.RESTORE_PLACED_BETS);
      }
    },

    undoBets({ commit, state }) {
      if (!state.bettingEnabled) {
        return;
      }
      commit(RouletteMutation.UNDO_BETS);
    },
    placeBets({ commit, getters, state }, slot) {
      commit(RouletteMutation.ADD_TO_HISTORY);
      commit(RouletteMutation.PLACE_BETS, slot);
    },
    scaleWagers({ commit, getters, state }, value) {
      for (let i = 0; i < 51; i++) {
        commit(RouletteMutation.SCALE_BETS, { slot: i, value });
      }
    },
    removeBets({ commit, state }, slot) {
      if (!state.bettingEnabled) {
        return;
      }

      commit(RouletteMutation.REMOVE_BETS, slot);
    },
    betsPerChip({ commit, getters, state, rootState }, bpc) {
      if (!state.bettingEnabled) {
        return;
      }

      let newWager = state.placedBets.reduce((c, b) => c + b, 0) * bpc;
      if (newWager > rootState.user.balance) {
        return;
      }

      commit(RouletteMutation.UPDATE_BETS_PER_CHIP, bpc);
    },
    clearBets({ commit, state }) {
      if (!state.bettingEnabled) {
        return;
      }

      commit(RouletteMutation.CLEAR_BETS);
    },
    animateBall({ commit }) {
      commit(RouletteMutation.SPINNING_BALL);
    },
    enableBetting({ commit }) {
      commit(RouletteMutation.ENABLE_BETTING);
    },
    disableBetting({ commit }) {
      commit(RouletteMutation.DISABLE_BETTING);
    },
    executePlay({ commit, getters, dispatch, state }) {
      commit(RouletteMutation.DISABLE_BETTING);
      const gameData = {
        bets: {},
        bpc: 1,
      };
      state.placedBets.map((v, i) => {
        gameData.bets[`bet_${i}`] = (v * 1) / 1e8;
      });
      return axios.post('roulette', gameData).catch(({ data, status }) => {
        commit(RouletteMutation.ENABLE_BETTING);
    
        if ( (typeof data.error != 'undefined')  && status === 412 && data.error === 'Client seed must be set!') {
          return dispatch('promptClientSeed');
        } else {
          if ((typeof data.error != 'undefined')  && data.error === 'Unauthenticated.') {
            window.location.replace(window.location.href + '?tab=login');
          } else {
            window.swal(
              'Uh-oh',
              'Something went wrong when placing your play: ' + ((typeof data.error != 'undefined')? data.error:''),
              'error',
            );
          }
        }
      });
    },
  },
};
