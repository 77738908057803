<template>
  <div id="76795-b5g7fhnj" class="sw_container"></div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  var website_url = 'https://sweepwidget.com',
    tokenKey = 'sw_token_key';
  const store = {
    store: window.localStorage,
    get(e) {
      const t = this.store.getItem(e);
      return null == t ? '' : t;
    },
    set(e, t) {
      this.store.setItem(e, t);
    },
  };
  function addListener() {
    var e, t;
    window.addEventListener
      ? ((e = window.addEventListener), (t = 'message'))
      : ((e = window.attachEvent), (t = 'onmessage')),
      e(t, function (e) {
        if (e.data && 'string' == typeof e.data && e.data.includes('command')) {
          const t = JSON.parse(JSON.parse(e.data));
          if (void 0 !== t.command)
            if ('SET' == t.command) store.set(tokenKey, t.token);
            else if ('GET' == t.command) {
              const t = `{"responseType": "token", "token": "${store.get(tokenKey)}"}`;
              e.source.postMessage(t, '*');
            }
        }
      });
  }
  function loadScript(e, t) {
    var n = document.createElement('script');
    (n.type = 'text/javascript'),
      n.readyState
        ? (n.onreadystatechange = function () {
            ('loaded' != n.readyState && 'complete' != n.readyState) ||
              ((n.onreadystatechange = null), t());
          })
        : (n.onload = function () {
            t();
          }),
      (n.src = e),
      document.getElementsByTagName('head')[0].appendChild(n);
  }
  function iframe_resizer() {
    loadScript(website_url + '/external/ui/iframe/js/iframeResizer.js', function () {
      iFrameResize({ checkOrigin: !1, heightCalculationMethod: 'lowestElement' });
    });
  }
  function init_widget(e, t, n) {
    n = document.getElementsByClassName('sw_container')[t].id;
    var a = document.getElementsByClassName('sw_container')[t].className,
      s =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('referral-hash') || '',
      o =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('data-email-prefill') || '',
      i =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('data-name-prefill') || '',
      r =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('data-auto-login-prefill') || '',
      l =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('data-if-widget-popup') || '',
      d =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('data-widget-popup-delay') || '',
      c =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('data-language') || '',
      m = (a.split(' ')[1], window.location.href),
      u = e + '/c/' + n + '-embedded';
    s && (u += '/' + s),
      (u += '?parent_url_full=' + encodeURIComponent(m)),
      (o || i) && (u += '&email=' + o + '&name=' + i + '&auto_login=' + r),
      c && (u += '&language=' + c),
      iframe_resizer();
    var g = document.getElementById(n);
    1 == l &&
      ((g.style.position = 'absolute'),
      (g.style.zIndex = '9999999999999999'),
      (g.style.paddingTop = '50px'),
      (g.style.top = '0px'),
      (g.style.left = '0px'),
      (g.style.width = '100%'),
      (g.style.height = '100%'),
      (g.style.display = 'none'),
      (d = 1e3 * parseInt(d)));
    var p = document.createElement('iframe');
    p.setAttribute('src', u),
      p.setAttribute('id', 'sw_iframe_' + n),
      p.setAttribute('width', '100%'),
      p.setAttribute('height', 600),
      p.setAttribute('scrolling', 'no'),
      p.setAttribute('frameborder', 'no'),
      g.appendChild(p),
      1 == l &&
        setTimeout(function () {
          (g.style.display = 'block'),
            (document.body.scrollTop = document.documentElement.scrollTop = 0);
        }, d);
  }
  addListener();
  for (
    var sw_class_count = parseInt(document.querySelectorAll('.sw_container').length, 10),
      i = 0;
    i < sw_class_count;
    i++
  ) {
    var competition_url_full = document.getElementsByClassName('sw_container')[i].id;
    init_widget(website_url, i, competition_url_full);
  }
});
</script>
