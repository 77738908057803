<template>
  <Popover v-slot="{ open }" class="relative max-h-full">
    <PopoverButton
      :class="open ? '' : 'text-opacity-90'"
      class="bg-[rgb(37_37_70_/_60%)] px-2 py-2 md:px-4 rounded-lg font-semibold flex flex-inline items-center transition ease-in-out delay-0 duration-150">
      <div class="flex flex-row space-x-2 items-center text-slate-300">
        <span class="text-sm pl-1 flex flex-row capitalize">
          {{ sortOrder(sort) }}
        </span>
        <img alt="icon" src="../../assets/svg/chevron.svg" class="mx-2" />
      </div>
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0">
      <PopoverPanel
        class="absolute md:px-4 mt-3 md:transform md:-translate-x-1/2 md:left-1/2 z-50 min-w-[175px] max-w-[175px] -right-8 px-4 mt-3">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="overflow-y-auto relative bg-slate-100 rounded-lg mx-2">
            <popover-button
              v-on:click="debouncedSortOrderChanged('multiplier')"
              class="w-full px-2 md:px-4 py-1 cursor-pointer transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
              <div
                :class="[isSelected('multiplier') ? 'text-onprimary' : 'text-black/70 ']"
                class="flex flex-row space-x-2 w-full justify-start text-[0.9rem]">
                <p>Multiplier</p>
              </div>
            </popover-button>
            <popover-button
              v-on:click="debouncedSortOrderChanged('prize')"
              class="w-full px-2 md:px-4 py-1 cursor-pointer transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
              <div
                :class="[isSelected('prize') ? 'text-onprimary' : 'text-black/70 ']"
                class="flex flex-row text-[0.9rem] space-x-2 w-full justify-start items-center">
                <p>Prize</p>
              </div>
            </popover-button>
            <popover-button
              v-on:click="debouncedSortOrderChanged('created')"
              class="w-full px-2 md:px-4 py-1 cursor-pointer text-[0.9rem] transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
              <div
                :class="[isSelected('created') ? 'text-onprimary' : 'text-black/70 ']"
                class="flex flex-row space-x-2 w-full justify-start text-[0.9rem] items-center">
                <p>Created</p>
              </div>
            </popover-button>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import {
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  Switch,
  SwitchGroup,
  SwitchLabel,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import SelectFiat from '@/components/Bank/SelectFiat.vue';
import { debounce } from 'lodash';

export default {
  name: 'ChalengeSort',
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    Switch,
    SwitchGroup,
    SwitchLabel,
    PopoverOverlay,
    SelectFiat,
  },
  setup() {
    return {};
  },
  props: {
    sort: {
      default: 'desc',
    },
    changeSortOrder: {
      default: () => {},
    },
  },
  data() {
    return { debouncedSortOrderChanged: null };
  },
  created() {
    this.debouncedSortOrderChanged = debounce(this.changeSortOrder, 50, {
      leading: true,
      trailing: false,
    });
  },
  computed: {},
  methods: {
    isSelected(order) {
      return order === this.sort;
    },
    sortOrder(order) {
      return order;
    },
  },
};
</script>
