import axios from 'axios';
import store from '../store';

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
axios.defaults.withCredentials = false;
axios.defaults.validateStatus = function (status) {
  return true;
};

/*
*
* if (window.Echo && window.Echo.socketId()) {
      request.headers.set('X-Socket-ID', window.Echo.socketId())
    }
    if (store.getters.authToken) {
      request.headers.set('Authorization', 'Bearer ' + store.getters.authToken)
    }*/

axios.interceptors.request.use(
  (config) => {
    config.headers.Accept = `application/json`;
    if (!config.headers.Authorization) {
      const token = store.getters.authToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    if (!config.headers['X-Socket-ID'] && window.Echo) {
      config.headers['X-Socket-ID'] = window.Echo.socketId();
    }

    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['Access-Control-Allow-Methods'] = '*';
    config.headers['Access-Control-Max-Age'] = '86400';

    return config;
  },
  (error) => Promise.reject(error),
);
axios.interceptors.response.use(
  function (response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    if (response.status === 401) {
      // delete window.localStorage['nexus-token']
    }
    if (response.status === 503) {
      window.location.href = "/maintenance.html";
    }
    return Promise.reject(response);
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default axios;
