<template>
  <div
    id="promotions"
    class="flex flex-col bg-no-repeast bg-contain mt-16 h-[800px] 3xl:h-screen relative">
    <div>
      <img
        class="absolute scale-75 3xl:scale-[200%] md:scale-100 bottom-[25%] top-[35%] lg:top-[10%] left-0 3xl:left-[10%] 3xl:top-[20%] h-80"
        src="/images/tourny_left.webp" />
      <img
        class="absolute scale-75 md:scale-100 3xl:scale-[200%] bottom-[25%] top-[35%] lg:top-[10%] right-0 3xl:right-[10%] h-80 3xl:top-[20%]"
        src="/images/tourney_right.webp" />
      <div
        class="uppercase flex flex-col top-[5%] text-[40px] md:text-[60px] 3xl:text-90px] font-bold items-center py-4">
        <span class="text-center">ENJOY FREE</span>
        <span class="text-center">BONUSES </span>
        <span class="text-center"> & </span>
        <span class="text-center text-accent">GIVEAWAYS</span>
      </div>
    </div>
    <img
      class="w-[40%] 3xl:h-[50%] xl:h-[30%] h-[20%] z-20 absolute bottom-8 lg:bottom-0 left-1/2 transform -translate-x-1/2"
      src="/images/chest.webp"
      alt="Chest" />
    <img
      class="absolute bottom-0 block w-full lg:hidden -left-0 -right-16"
      src="/images/bg_gems_md.webp"
      alt="Chest" />
    <img
      class="absolute bottom-0 hidden w-full lg:block -left-0 -right-16"
      src="/images/bg_gems.webp"
      alt="Chest" />
  </div>
</template>

<script>
export default {
  name: 'Promotions',
};
</script>
