<template>
  <div class="roulette-table scale-y-90 top-4">
    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-1-18"
      v-on:click="tableCellClicked(RouletteBets.RANGE_1_TO_18)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_1_TO_18] > 0"
        :value="tableBets[RouletteBets.RANGE_1_TO_18]"></Chip>
      <span class="label span-text">1 to 18</span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-even"
      v-on:click="tableCellClicked(RouletteBets.RANGE_EVEN)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_EVEN] > 0"
        :value="tableBets[RouletteBets.RANGE_EVEN]"></Chip>

      <span class="label span-text">even</span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-red"
      v-on:click="tableCellClicked(RouletteBets.RANGE_RED)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_RED] > 0"
        :value="tableBets[RouletteBets.RANGE_RED]"></Chip>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-black"
      v-on:click="tableCellClicked(RouletteBets.RANGE_BLACK)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_BLACK] > 0"
        :value="tableBets[RouletteBets.RANGE_BLACK]"></Chip>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-odd"
      v-on:click="tableCellClicked(RouletteBets.RANGE_ODD)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_ODD] > 0"
        :value="tableBets[RouletteBets.RANGE_ODD]"></Chip>

      <span class="label span-text">odd</span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-19-36"
      v-on:click="tableCellClicked(RouletteBets.RANGE_19_TO_36)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_19_TO_36] > 0"
        :value="tableBets[RouletteBets.RANGE_19_TO_36]"></Chip>

      <span class="label span-text">19 to 36</span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-first-12"
      v-on:click="tableCellClicked(RouletteBets.RANGE_FIRST_12)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_FIRST_12] > 0"
        :value="tableBets[RouletteBets.RANGE_FIRST_12]"></Chip>

      <span class="label span-text"> 1 to 12 </span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-second-12"
      v-on:click="tableCellClicked(RouletteBets.RANGE_SECOND_12)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_SECOND_12] > 0"
        :value="tableBets[RouletteBets.RANGE_SECOND_12]"></Chip>

      <span class="label span-text"> 13 to 24 </span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-third-12"
      v-on:click="tableCellClicked(RouletteBets.RANGE_THIRD_12)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_THIRD_12] > 0"
        :value="tableBets[RouletteBets.RANGE_THIRD_12]"></Chip>

      <span class="label span-text"> 25 to 36 </span>
    </button>

    <button
      style="grid-row: 14 / span 1"
      :class="{ enabled: !locked }"
      class="slot slot-range-row-1"
      v-on:click="tableCellClicked(RouletteBets.RANGE_ROW_ONE)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_ROW_ONE] > 0"
        :value="tableBets[RouletteBets.RANGE_ROW_ONE]"></Chip>

      <span class="label">2:1</span>
    </button>

    <button
      style="grid-row: 14 / span 1"
      :class="{ enabled: !locked }"
      class="slot slot-range-row-2"
      v-on:click="tableCellClicked(RouletteBets.RANGE_ROW_TWO)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_ROW_TWO] > 0"
        :value="tableBets[RouletteBets.RANGE_ROW_TWO]"></Chip>

      <span class="label">2:1</span>
    </button>

    <button
      style="grid-row: 14 / span 1"
      :class="{ enabled: !locked }"
      class="slot slot-range-row-3"
      v-on:click="tableCellClicked(RouletteBets.RANGE_ROW_THREE)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_ROW_THREE] > 0"
        :value="tableBets[RouletteBets.RANGE_ROW_THREE]"></Chip>

      <span class="label">2:1</span>
    </button>

    <div class="slot-zero">
      <button class="slot slot-green" v-on:click="tableCellClicked(RouletteBets.SLOT_00)">
        <Chip
          v-if="tableBets[RouletteBets.SLOT_00] > 0"
          :value="tableBets[RouletteBets.SLOT_00]"></Chip>

        <span class="label">00</span>
      </button>

      <button class="slot slot-green" v-on:click="tableCellClicked(RouletteBets.SLOT_0)">
        <Chip
          v-if="tableBets[RouletteBets.SLOT_0] > 0"
          :value="tableBets[RouletteBets.SLOT_0]"></Chip>

        <span class="label">0</span>
      </button>
    </div>

    <button
      v-for="slot in SLOT_NUMBERS"
      :style="
        'grid-row: ' +
        Number.parseInt((slot + 2) / 3 + 1) +
        ' / span 1; grid-column: ' +
        Number.parseInt(((slot + 2) % 3) + 3) +
        ' / span 1;'
      "
      class="slot slot-group w-12 h-8"
      :class="{
        enabled: !locked,
        'slot-red': slotColor(slot) == ROULETTE_COLORS.RED,
        'slot-black': slotColor(slot) == ROULETTE_COLORS.BLACK,

        'slot-even': slot % 2 === 0 && slot > 0,
        'slot-odd': slot % 2 !== 0,

        'slot-row-1': slot % 3 === 0,
        'slot-row-2': slot % 3 === 2,
        'slot-row-3': slot % 3 === 1,

        'slot-1-to-18': slot >= 1 && slot <= 18,
        'slot-19-to-36': slot >= 19 && slot <= 36,

        'slot-first-12': slot >= 1 && slot <= 12,
        'slot-second-12': slot >= 13 && slot <= 24,
        'slot-third-12': slot >= 25 && slot <= 37,
      }"
      :key="slot + 1"
      v-on:click="tableCellClicked(slot)">
      <Chip v-if="tableBets[slot] > 0" :value="tableBets[slot]"></Chip>

      <span class="label">{{ slot }}</span>
    </button>
  </div>
</template>

<script>
import { GetSlotColor, RouletteColors } from './RouletteLayouts.js';
import { mapActions, mapGetters } from 'vuex';
import Chip from './ChipMobile.vue';
import { rouletteStore } from '@/store/pinia/rouletteStore';

const RouletteBets = {
  SLOT_0: 0,
  SLOT_1: 1,
  SLOT_2: 2,
  SLOT_3: 3,
  SLOT_4: 4,
  SLOT_5: 5,
  SLOT_6: 6,
  SLOT_7: 7,
  SLOT_8: 8,
  SLOT_9: 9,
  //
  SLOT_10: 10,
  SLOT_11: 11,
  SLOT_12: 12,
  SLOT_13: 13,
  SLOT_14: 14,
  SLOT_15: 15,
  SLOT_16: 16,
  SLOT_17: 17,
  SLOT_18: 18,
  SLOT_19: 19,
  //
  SLOT_20: 20,
  SLOT_21: 21,
  SLOT_22: 22,
  SLOT_23: 23,
  SLOT_24: 24,
  SLOT_25: 25,
  SLOT_26: 26,
  SLOT_27: 27,
  SLOT_28: 28,
  SLOT_29: 29,
  //
  SLOT_30: 30,
  SLOT_31: 31,
  SLOT_32: 32,
  SLOT_33: 33,
  SLOT_34: 34,
  SLOT_35: 35,
  SLOT_36: 36,
  //
  SLOT_00: 37,
  SLOT_000: 38,
  //
  RANGE_ODD: 39,
  RANGE_EVEN: 40,
  RANGE_BLACK: 41,
  RANGE_RED: 42,
  //
  //
  RANGE_ROW_ONE: 43,
  RANGE_ROW_TWO: 44,
  RANGE_ROW_THREE: 45,
  //
  RANGE_FIRST_12: 46,
  RANGE_SECOND_12: 47,
  RANGE_THIRD_12: 48,
  //
  RANGE_1_TO_18: 49,
  RANGE_19_TO_36: 50,
};

export default {
  name: 'RouletteTable',
  components: {
    Chip,
  },
  setup() {
    const rouletteS = rouletteStore();
    return { rouletteS };
  },
  data: () => ({}),
  muted: false,
  props: {
    locked: {
      type: Boolean,
      default: false,
    },
    removeChips: {
      type: Boolean,
      default: false,
    },
    viewOnly: {
      default: false,
    },
    selected: {},

    bets: {
      default: () => [],
    },
  },
  methods: {
    slotColor: (i) => GetSlotColor(i),
    ...mapActions('Roulette', ['placeBets', 'removeBets']),
    tableCellClicked(slot) {
      const selected = this.rouletteS.selectedChip;
      if (!this.locked || this.viewOnly) {
        if (this.removingBets) {
          this.$emit('removingChips', { slot });
          this.removeBets(slot);
        }
        this.placeBets({ slot, chips: selected });
        this.$emit('placingChips', { slot });
      }
    },
  },
  computed: {
    ROULETTE_COLORS: () => RouletteColors,
    SLOT_NUMBERS: () => {
      return [
        ...Array.from({ length: 12 }, (x, i) => (i + 1) * 3),
        ...Array.from({ length: 12 }, (x, i) => (i + 1) * 3 - 1),
        ...Array.from({ length: 12 }, (x, i) => (i + 1) * 3 - 2),
      ];
    },
    RouletteBets: () => RouletteBets,
    ...mapGetters('Roulette', ['removingBets', 'placedBets', 'removingBets']),
    tableBets() {
      if (this.viewOnly) {
        return this.bets;
      }

      return this.placedBets;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables';

@mixin on-hover {
  border-color: gold;
}

.slot-range-bottom > .span-text {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  line-height: 1;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(255, 255, 255);
  white-space: nowrap;
  text-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
  font-weight: 600;
  font-size: small;
  writing-mode: vertical-lr;
}

.roulette-table {
  width: 100%;
  flex: 1 1 100%;
  position: relative;
  display: grid;

  grid-template-rows: repeat(14, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 0;

  .slot-zero {
    grid-row: 1;
    grid-column: 1 / span 5;

    display: flex;
    flex-flow: row nowrap;

    .slot {
      flex: 1 1 50%;
      max-height: none;

      &:first-child {
        border-radius: 0.5rem 0 0 0;
      }

      &:last-child {
        border-radius: 0 0.5rem 0 0;
      }
    }
  }

  .slot-row-1,
  .slot-range-row-1 {
    grid-column: 5;
  }

  .slot-row-2,
  .slot-range-row-2 {
    grid-column: 4;
  }

  .slot-row-3,
  .slot-range-row-3 {
    grid-column: 3;
  }

  .slot {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;

    position: relative;

    min-width: 2.5rem;
    min-height: 1.5 em;
    line-height: 0.5rem;

    border: 0.1rem solid white;
    background-color: $table-green;

    color: #ffffff;
    font-size: 1rem;

    cursor: pointer;

    transition: all 150ms ease-out;

    .label {
      text-align: center;
      user-select: none;
    }

    .chip {
      z-index: 1;
    }

    .chip ~ .label {
      align-self: flex-end;
      justify-self: flex-end;

      font-size: 0.85rem;
      position: absolute;
      bottom: 0.3rem;
      right: 0.2rem;
    }

    &.slot-red {
      &:hover {
        @include on-hover;
        box-shadow:
          0.1rem 0.1rem 0 0 gold,
          0.1rem -0.1rem 0 0 gold,
          -0.1rem 0.1rem 0 0 gold,
          -0.1rem -0.1rem 0 0 gold;
        z-index: 1;
      }

      background-color: $slot-red !important;
    }

    &.slot-green {
      &:hover {
        @include on-hover;
        box-shadow:
          0.1rem 0.1rem 0 0 gold,
          0.1rem -0.1rem 0 0 gold,
          -0.1rem 0.1rem 0 0 gold,
          -0.1rem -0.1rem 0 0 gold;
        z-index: 1;
      }
    }

    &.slot-black {
      &:hover {
        @include on-hover;
        box-shadow:
          0.1rem 0.1rem 0 0 gold,
          0.1rem -0.1rem 0 0 gold,
          -0.1rem 0.1rem 0 0 gold,
          -0.1rem -0.1rem 0 0 gold;
        z-index: 1;
      }

      background-color: $slot-black;
    }

    &.slot-range-1-18 {
      grid-row: 2 / span 2;
      grid-column: 1 / span 1;
      border-radius: 0 0 0 0.5rem;
    }

    &.slot-range-1-18 > span {
      -webkit-writing-mode: vertical-lr;
      writing-mode: vertical-lr;
    }

    &.slot-range-19-36 {
      grid-row: 12 / span 2;
      grid-column: 1 / span 1;
      border-radius: 0 0 0.5rem 0;
    }

    &.slot-range-even {
      grid-row: 4 / span 2;
      grid-column: 1 / span 1;
    }

    &.slot-range-red {
      background-color: $slot-red !important;
      grid-row: 6 / span 2;
      grid-column: 1 / span 1;
    }

    &.slot-range-black {
      background-color: $slot-black;
      grid-row: 8 / span 2;
      grid-column: 1 / span 1;
    }

    &.slot-range-odd {
      grid-row: 10 / span 2;
      grid-column: 1 / span 1;
    }

    &.slot-range-first-12,
    &.slot-range-second-12,
    &.slot-range-third-12 {
      grid-column: 2;
    }

    &.slot-range-first-12 {
      grid-row: 2 / span 4;
    }

    &.slot-range-second-12 {
      grid-row: 6 / span 4;
    }

    &.slot-range-third-12 {
      grid-row: 10 / span 4;
    }

    &.slot-range-row-1 {
      border-radius: 0.5rem 0.5rem 0 0;
    }

    &.slot-range-row-3 {
      border-radius: 0 0 0.5rem 0;
    }

    &.enabled {
      &.slot-range-odd:hover ~ .slot-odd,
      &.slot-range-even:hover ~ .slot-even,
      &.slot-range-black:hover ~ .slot-black,
      &.slot-range-red:hover ~ .slot-red,
      &.slot-range-first-12:hover ~ .slot-group.slot-first-12,
      &.slot-range-second-12:hover ~ .numbered-slots > .slot-group.slot-second-12,
      &.slot-range-third-12:hover ~ .slot-group.slot-third-12,
      &.slot-range-1-18:hover ~ .slot-1-to-18,
      &.slot-range-19-36:hover ~ .slot-19-to-36,
      &.slot-range-row-1:hover ~ .slot-row-1,
      &.slot-range-row-2:hover ~ .slot-row-2,
      &.slot-range-row-3:hover ~ .slot-row-3 {
        @include on-hover;
      }

      &.slot-range-odd:hover ~ :not(.slot-odd),
      &.slot-range-even:hover ~ :not(.slot-even),
      &.slot-range-black:hover ~ :not(.slot-black),
      &.slot-range-red:hover ~ :not(.slot-red),
      &.slot-range-first-12:hover ~ :not(.slot-first-12),
      &.slot-range-second-12:hover ~ :not(.slot-second-12),
      &.slot-range-third-12:hover ~ :not(.slot-third-12),
      &.slot-range-1-18:hover ~ :not(.slot-1-to-18),
      &.slot-range-19-36:hover ~ :not(.slot-19-to-36),
      &.slot-range-row-1:hover ~ :not(.slot-row-1),
      &.slot-range-row-2:hover ~ :not(.slot-row-2),
      &.slot-range-row-3:hover ~ :not(.slot-row-3) {
        opacity: 0.35 !important;
      }
    }
  }
}
</style>

<style lang="scss">
@import 'variables';

.slot {
  border-color: #26444f !important;
  border-radius: 5px;
  margin: 2px;
  background-color: #0d1f28 !important;

  &.slot-black,
  &.slot-range-black {
    background-color: #26444f !important;
  }

  &.slot-green {
    background-color: '#1EE563' !important;
  }
}
</style>
