<template>
  <div
    class="h-screen fixed top-0 left-0 z-[999999] bg-tertiary w-full flex items-center justify-center">
    <div
      class="w-full loading-icon-body bg-tertiary px-4 py-12 md:px-8 md:py-12 justify-center text-center">
      <div class="progress-overlay">
        <img :src="bg" alt="" />
      </div>
      <div class="loader-coin-images">
        <img class="coin-1" :src="coin1" alt="" />
        <img class="coin-2" :src="coin2" alt="" />
        <img class="coin-3" :src="coin3" alt="" />
        <img class="coin-4" :src="coin4" alt="" />
        <img class="coin-5" :src="coin5" alt="" />
      </div>

      <img :src="logo" alt="Chanced" class="logo m-auto mb-2 mt-10 rotate" />
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import coin1 from '../../assets/loader/loader-coin-1.png';
import coin2 from '../../assets/loader/loader-coin-2.png';
import coin3 from '../../assets/loader/loader-coin-3.png';
import coin4 from '../../assets/loader/loader-coin-4.png';
import coin5 from '../../assets/loader/loader-coin-5.png';
import bg from '../../assets/loader/loader-bg.png';
import logo from '../../assets/loader/loader-image.svg';

export default {
  name: 'FullScreenLoader',
  props: {
    start: {
      default: false,
    },
    onSuccess: {
      default: null,
    },
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
    top() {
      return this.width * 2 - 100;
    },
  },
  created() {
    this.now = new Date().getTime();
    this.page_load_time = this.now - window.performance.timing.navigationStart;
  },
  setup() {
    return {
      page_load_time: '',
      now: '',
      coin1,
      coin2,
      coin3,
      coin4,
      coin5,
      bg,
      logo,
    };
  },
  data() {
    return {
      intervalid1: 0,
      width: 0,
    };
  },
  mounted: function () {
    if (this.start) this.calculated();
  },
  methods: {
    onLoad() {},
    calculated() {
      /*var width = 100; // width of a progress bar in percentage
      const  perfData = window.performance.timing; // The PerformanceTiming interface
      const EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart); // Calculated Estimated Time of Page Load which returns negative value.
      const time = parseInt((EstimatedTime/1000)%60)*100; //Converting EstimatedTime from miliseconds to seconds.
      const second_per_pec = width/time;
       
     
      this.intervalid1 = setInterval(() => {
          this.width +=1;
          
          if(this.width >=100)
          {
           clearInterval(this.intervalid1);
           if(this.onSuccess)
           this.onSuccess();
          }
           
      }, 1); 
      */
    },
  },
  watch: {
    start: function (newVal, oldVal) {
      // watch it
      if (newVal) {
        this.calculated();
      }
    },
  },
};
</script>

<style scoped>
/* if we need to add rotating image */
/* .rotate {
  animation: rotation 5s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
} */
</style>
