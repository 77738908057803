<template>
  <technical-warning
    :wtitle="'Please contact Live Support on the left hand side of Chanced.'"
    v-if="
      !me.can_purchase /* me.username!=null && me.username!='stagetester' &&me.username != 'zangtop1' && me.username != 'Smite' && me.username != 'vishal123' */
    " />

  <div v-else class="w-full flex flex-col text-slate-300">
    <div v-if="!showPayments" class="">
      
      <div class="special-offer py-10  mb-5" v-if="this.me.is_black_friday_sale_available">

       <p class="text-center relative  mb-5 font-bold text-2xl text-center sm:text-3xl"
        >
          <span class="relative z-10 bg-primary px-1">Your Special Offer</span>
          <hr class="border-b-2   z-0 border-slate opacity-20 absolute top-[50%] w-[100%] left-0" />

        </p
        >
        <p class="text-center"
        >Claim Your One-Time Offer Today</p >
          <div class="grid min-[350px]:grid-cols-2 md:grid-cols-3 sm:gap-4 gap-2 grid-cols-1 mt-5 ">
            <div
              v-for="(bundle, i) in blackFridayBundles"
              class="flex flex-col rounded-xl bg-secondary hover:opacity-75 cursor-pointer "
              :key="i"
              :class="[
                isDisabled(bundle.price) ? 'opacity-50' : '',
                hasBlackFridaySale(bundle) ? 'border-4 border-yellow/80' : ''
              ]"
              v-on:click="onBundleClicked(bundle)"
              :disabled="isOnBreak || isDisabled(bundle.price)"
            >
              <div
                class="flex flex-row relative mt-4 sm:ml-6 ml-2 py-0.5 bg-secondary-light sm:text-sm text-[11px]" >
                <img
                  alt="USD Coin"
                  class="h-8 w-8 absolute transform -translate-y-1/2 top-1/2 -left-2 max-[480px]:w-5 max-[480px]:h-5"
                  src="../../assets/coins/cash.png" />
                <span class="ml-8 max-[480px]:ml-5"
                >{{ getBundleValue(bundle) }} free Sweeps Coins</span
                >
              </div>

              <div class="flex flex-col items-center justify-center p-2 ">
                <img
                  class="w-24 max-[480px]:w-16 max-[480px]:mb-2"
                  :src="bundle.img"
                  alt="coins"
                />
                <span>{{ (getBundleValue(bundle) * 10000).toLocaleString() }}</span>
                <span class="text-xs text-primary-text block">Gold Coins</span>

                <button
                 
                  class="btn-bet bg-accent text-white shadow-btn rounded rounded-2xl px-4 py-3 mt-3 w-3/4 max-[575px]:px-3 max-[575px]:mt-2 max-[575px]:w-1/1 max-[575px]:text-sm max-[575px]:rounded-lg">
                  
                  <div  >
                    <span class="line-through-large mr-2">${{ bundle.price }}</span>
                    <span class="text-lg">${{ getBundlePrice(bundle) }}</span>
                    
                  </div>
                   
                </button>
              </div>
            </div>
          </div>
      </div>


      <div class="flex flex-col gap-2 p-4 pt-2 sm:pt-4">
        <span class="font-bold text-2xl text-center sm:text-3xl">Bundles</span>
        <span class="text-center"
        >Please choose one of the following bundle options</span
        >
      </div>


      <div class="grid min-[350px]:grid-cols-2 md:grid-cols-3 sm:gap-4 gap-2 grid-cols-1">
        <div
          v-for="(bundle, i) in bundles"
           
          class="flex flex-col rounded-xl bg-secondary hover:opacity-75 cursor-pointer "
          :key="i"
          :class="[
            isDisabled(bundle.price) ? 'opacity-50' : '',
            hasPromotion(bundle)  ? 'border-4 border-yellow/80' : '',
            hasBlackFridaySale(bundle)?'hidden':''
  
          ]"
          v-on:click="onBundleClicked(bundle)"
          :disabled="isOnBreak || isDisabled(bundle.price)"
        > 
          <div
            class="flex flex-row relative mt-4 sm:ml-6 ml-2 py-0.5 bg-secondary-light sm:text-sm text-[11px]" >
            <img
              alt="USD Coin"
              class="h-8 w-8 absolute transform -translate-y-1/2 top-1/2 -left-2 max-[480px]:w-5 max-[480px]:h-5"
              src="../../assets/coins/cash.png" />
            <span class="ml-8 max-[480px]:ml-5"
            >{{ getBundleValue(bundle) }} free Sweeps Coins</span
            >
          </div>

          <div class="flex flex-col items-center justify-center p-2 ">
            <img
              class="w-24 max-[480px]:w-16 max-[480px]:mb-2"
              :src="bundle.img"
              alt="coins"
            />
            <span>{{ (getBundleValue(bundle) * 10000).toLocaleString() }}</span>
            <span class="text-xs text-primary-text block">Gold Coins</span>

            <button
             
              class="btn-bet bg-accent text-white shadow-btn rounded rounded-2xl hover:opacity-75 px-4 py-3 mt-3 w-3/4 max-[575px]:px-3 max-[575px]:mt-2 max-[575px]:w-1/2 max-[575px]:text-sm max-[575px]:rounded-lg">
              <div v-if="hasUpgradePromotion(bundle)">
                <span class="line-through-large mr-2">${{ getBundleValue(bundle) }}</span>
                <span class="text-lg">${{ bundle.price }}</span>
              </div>
              <div v-else-if="hasBlackFridaySale(bundle)" >
                <span class="line-through-large mr-2">${{ bundle.price }}</span>
                <span class="text-lg">${{ getBundlePrice(bundle) }}</span>
                
              </div>
              <div v-else>
                ${{ getBundlePrice(bundle) }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex flex-col items-center justify-center mt-4 lg:mt-8">
        <div class="mx-auto w-full max-w-sm">
          <div class="flex items-center justify-between">
            <h2 class="mb-4 mr-4 text-xl lg:text-2xl font-semibold">Order Summary:</h2>
            <h2 class="mb-4 text-xl lg:text-2xl font-semibold">
              &dollar; {{ getBundlePrice(this.selectedBundle).toFixed(2) }}
            </h2>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-center justify-center mt-4 lg:mt-8">
        <div class="mx-auto w-full max-w-sm bg-secondary rounded-xl p-4">
          <div class="mb-4 col-span-3 flex flex-row justify-center space-x-2">
            <img class="h-5 w-5" :src="getIcon('GC')" />
            <h2 class="text-x font-bold">
              {{ (getBundleValue(this.selectedBundle) * 10000).toLocaleString() }}
            </h2>
          </div>

          <div class="mb-4 col-span-3 flex flex-row justify-center space-x-2">
            <img class="h-5 w-5" :src="getIcon('SC')" />
            <h2 class="text-x font-bold font-semibold">
              {{ getBundleValue(this.selectedBundle) }} FOR FREE
            </h2>
          </div>
        </div>

        <div class="mx-auto w-full max-w-sm" v-bind:style="{ paddingTop: '40px' }">
          <h2 class="mb-4 mr-4 text-x font-semibold">Select Method:</h2>

          <button
            class="mx-auto w-full max-w-sm"
            v-on:click="paySafeDeposit"
            v-if="!me.is_frozen">
            <div
              class="flex items-center rounded-xl bg-secondary flex-wrap gap-[15px] justify-center"
              v-bind:style="{ marginBottom: '20px', padding: '20px' }">
              <div class="row flex">
                <img
                  class="h-8"
                  style="padding: 2px"
                  src="../../assets/paymentmethods/Visa.svg" />
                <img
                  class="h-8"
                  style="padding: 2px"
                  src="../../assets/paymentmethods/Mastercard.svg" />
                <img
                  class="h-8"
                  style="padding: 2px"
                  src="../../assets/paymentmethods/Amex.svg" />
                <img
                  class="h-8"
                  style="padding: 2px"
                  src="../../assets/paymentmethods/Discover.svg" />
              </div>
              <div>
                <div class="flex">
                  <h2
                    class="text-x font-bold font-semibold"
                    v-bind:style="{ paddingRight: '8px' }">
                    Credit/Debit Card
                  </h2>
                  <img
                    src="@/assets/svg/rightarrow.svg"
                    v-bind:style="{ height: '25px', color: '#fff' }" />
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import Arrow from '../../assets/svg/rightarrow.svg';
import { balanceStore } from '@/store/pinia/balanceStore';
import { getBalanceIcon } from '@/lib/icons';
import TechnicalWarning from '@/components/TechnicalWarning.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Bundles',
  components: {
    TechnicalWarning,
    Arrow,
  },
  props: {
    showBuy: {
      default: true,
    },
    isOnBreak: {
      default: true,
    }
  },
  setup() {
    const balS = balanceStore();
    balS.init();
    const bundles = computed(() => balS.getAvailableBundle);
    const blackFridayBundles = computed(() => balS.getBlackFridayBundles);
    const activePromotions = computed(() => balS.getActivePromotions);
    return { bundles, balS, activePromotions,blackFridayBundles };
  },
  data() {
    return { showPayments: false, selectedBundle: null };
  },
  async created() {
    if (this.me && !this.me.username) {
      await this.fetchUser();
    }
  },
  async mounted() {
    if(import.meta.env.VITE_NODE_ENV == 'production') {
      this.$gtm.trackEvent({
        event: 'open_wallet',
        email: this.me.email,
      });
    }
    await this.fetchUser();
  },
  methods: {
    ...mapActions(['fetchUser']),
    onBundleClicked(bundle) {
      if (this.isOnBreak) {
        this.$toast.error('Your "Break-In-Play" is active till: ' + this.me.break_till_date);
        return;
      }
      this.selectedBundle = bundle;
      this.showPayments = true;
      this.balS.bundleSelected = Math.round(bundle.price * 100) / 100;
    },
    getIcon(currency) {
      return getBalanceIcon(currency);
    },
    paySafeDeposit() {
      const page = 'buy';
      const promotionId = this.getPromotionId(this.selectedBundle);
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          ...{ action: 'paysafe', bundle: this.getBundlePrice(this.selectedBundle), page, promotionId },
        },
      });

    },
    getPromotionId(bundle) {
      return this.activePromotions[bundle.price]?.promotion_id || null;
    },
    isDisabled(amount) {

      return this.me.purchase_limit_bundles.includes(amount);
    },
    hasPromotion(bundle) {
      return !!this.activePromotions[bundle.price];
    },
    hasBlackFridaySale(bundle) {
     
      return   this.me.is_black_friday_sale_available && !!this.blackFridayBundles[bundle.price];
    },
    getBundleValue(bundle) {
      const promotion = this.activePromotions[bundle.price];
      if (promotion) {
        const upgradeReward = promotion.rewards.find(r => r.reward_type === 'Bundle Upgrade');
        if (upgradeReward) {
          return Math.floor(upgradeReward.reward_amount);
        }
      }
      return bundle.price;
    },
    hasUpgradePromotion(bundle) {
      const promotion = this.activePromotions[bundle.price];
      return promotion && promotion.rewards.some(r => r.reward_type === 'Bundle Upgrade');
    },
    getBundlePrice(bundle)
    {
      const blackfridaySaleactive = this.blackFridayBundles[bundle.price];
 
      if( this.me.is_black_friday_sale_available && blackfridaySaleactive )
        return this.blackFridayBundles[bundle.price].discounted_price;
      else
        return bundle.price;
    }
  },
  computed: {
    ...mapGetters(['me']),
  },
  watch: {
    '$route.query.bundle': {
      handler(newState) {
        const selectedBundle = this.bundles.find(item => item.price == newState);
        if (selectedBundle && this.selectedBundle !== selectedBundle) {
          this.onBundleClicked(selectedBundle);
        }
      },
      immediate: true,
    },
  }
};
</script>

<style scoped>
.line-through-large {
  text-decoration: line-through;
  text-decoration-thickness: 3px;
}
</style>
