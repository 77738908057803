<template>
  <header class="site-header section">
    <div class="container m-auto">
      <div class="row flex justify-between items-center gap-4 max-[400px]:gap-2">
        <div class="">
          <router-link to="/" class="logo">
            <img src="../../../assets/logo.png" alt="site-logo" class="site-logo" />
          </router-link>
        </div>
<!--        <div class="">
          <div class="menu-links">
            <ul class="flex justify-between gap-[clamp(1.25rem,3.125vw_-_0.75rem,3rem)]">
              <li class="active">
                <button href="" @click="scrollToElement('main')">Home</button>
              </li>
              <li><button href="" @click="scrollToElement('games')">Games</button></li>
              <li>
                <button href="" @click="scrollToElement('latestWinner')">Winners</button>
              </li>
              <li>
                <button href="" @click="scrollToElement('realPeople')">Reactions</button>
              </li>
              <li>
                <button href="" @click="scrollToElement('testimonials')">
                  Testimonials
                </button>
              </li>
            </ul>
          </div>
        </div>-->
        <div class="header-buttons flex">
          <ul class="flex justify-between item-center">
            <li>
              <button
                class="header-btn btn-login"
                href=""
                v-on:click="$router.push({ path: $route.path, query: { tab: 'login' } })">
                Log in
              </button>
            </li>
            <li>
              <button
                class="header-btn btn-singup ml-2"
                href=""
                v-on:click="
                  $router.push({ path: $route.path, query: { tab: 'register' } })
                ">
                Sign up
              </button>
            </li>
          </ul>
          <!-- <button class="toggle-button" @click="openMenu" :class="(menuopen)?'active':''">
                <div class="bar-wrapper">
                  <div class="bar bar-1"></div>
                  <div class="bar bar-2"></div>
                  <div class="bar bar-3"></div>
                </div>
            </button> -->
        </div>
      </div>
    </div>
    <!-- <div class="mobile-menu"  :class="(menuopen)?'active':''">
  <div class="mobile-menu-links">
        <ul class="flex justify-between gap-[48px]">
          <li class="active">
              <a href="">Home</a></li>
          <li><a href="">Games</a></li>
          <li><a href="">Winners</a></li>
          <li><a href="">Reactions</a></li>
          <li><a href="">Testimonials</a></li>
        </ul>
  </div>
  <div class="header-buttons flex">
            <ul class="flex justify-between">
              <li>
                <button class="header-btn btn-login" href="" v-on:click="$router.push({ path: $route.path, query: { tab: 'login' } })">Log in</button>
              </li>
              <li>
                <button class="header-btn btn-singup ml-2" href="" v-on:click="$router.push({ path: $route.path, query: { tab: 'register' } })">Sign up</button>
              </li>
            </ul>
    </div>
    <div class="bg-1">
        <img src="/images/home/mobile-menu-bg-1.png" alt="">
    </div>
    <div class="bg-2">
        <img src="/images/home/mobile-menu-bg-2.png" alt="">
    </div>
    <div class="bg-3">
        <img src="/images/home/mobile-menu-bg-3.png" alt="">
    </div>
</div> -->
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'PHeader',
  data() {
    return {
      menuopen: false,
    };
  },
  methods: {
    openMenu() {
      this.menuopen = !this.menuopen;
    },
    onPlayNowClicked() {
      const route = this.isLoggedIn ? '/' : '/?tab=register';
      this.$router.push(route);
    },
    scrollToElement(id) {
      const container = document.getElementById(id);
      container.scrollIntoView({ behavior: 'smooth' });
    },
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },
};
</script>
