<template>
  <div
    v-if="isVisible"
    class="notification-bar"
  >
    <div>
      <strong>Scheduled Maintenance</strong> -
      The website will be undergoing scheduled maintenance on {{ date }} at {{ time }}
      for approximately {{ duration }} hours. We apologize for any inconvenience caused during this time.
    </div>
    <div class="close" @click="hideBanner">×</div>
  </div>
</template>

<script>
export default {
  name: "MaintenanceBanner",
  props: {
    on: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    if (this.on && sessionStorage.getItem("notificationBar") !== "1") {
      this.isVisible = true;
    }
  },
  methods: {
    hideBanner() {
      sessionStorage.setItem("notificationBar", "1");
      this.isVisible = false;
    },
  },
};
</script>

<style scoped>
.notification-bar {
  width: 100%;
  padding: 10px 5%;
  color: #31708f;
  background: #d9edf7;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  font-size: 14px;
  transition: all 0.5s ease;
  z-index: 999999;
}

.close {
  top: 10px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}

html, body {
  margin: 0;
  font-family: sans-serif;
}
</style>