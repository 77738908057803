<template>
  <router-view></router-view>

  <div
    class="bg-grecen-500/10 flex md:h-screen w-full flex-col items-center p-0 pt-3 md:p-8 main_original_wrapper">
    <div class="w-full lg:max-w-[1200px] max-[767px]:max-w-[400px] h-full">
      <div class="flex h-full w-full flex-col-reverse lg:flex-row">
        <game-actions
          :pre-extras="gameActions"
          class="block rounded-b-xl bg-tertiary md:hidden" />
        <dicing-bets
          class="rounded-xl w-full bg-side-color p-2 lg:w-[325px] md:rounded-bl-xl md:rounded-br-none md:rounded-tl-xl md:p-4"
          :autoBetChanged="autoBetChanged"
          :playHandler="play"
          :playerProfit="profit"
          :currentState="currentState" />

        <div
          class="flex flex-1 flex-col md:h-auto md:min-h-[auto] md:flex-grow md:rounded-tr-xl">
          <div class="h-full w-full bg-dice bg-no-repeat bg-cover bg-center">
            <div class="h-full w-full bg-primary/60">
              <div class="flex flex-col items-center">
                <div class="flex-1 py-4">
                  <!-- <span>Grid Here</span> -->
                  <div class="flex h-6 w-full items-center gap-2 md:h-12">
                    <button
                      v-for="(game, index) in previousGames"
                      :key="game.id"
                      :class="[
                        game.won
                          ? 'bg-[#00e403] hover:bg-accent text-black'
                          : 'bg-onprimary',
                      ]"
                      class="min-w-8 rounded-2xl px-3 py-2 text-xs font-bold md:text-sm"
                      :style="
                        'opacity: ' + (5 - previousGames.length + 1 + index) * 0.2 + ';'
                      ">
                      <router-link
                        class="label is-unselectable no-underline"
                        :to="{
                          name: 'HouseBetInfo',
                          params: { gameId: game.id },
                        }">
                        {{ game.roll }}
                      </router-link>
                    </button>
                  </div>
                </div>
              </div>

              <!-- The Game -->
              <div
                class="flex min-h-[320px] w-full flex-col overflow-x-hidden md:min-h-[500px]"
                id="js-canvas-root">
                <div class="flex flex-grow">
                  <div
                    class="flex flex-grow flex-col items-center justify-center px-5 md:px-20">
                    <label
                      :class="[
                        currentState === 'finished'
                          ? this.won
                            ? 'text-[#00e403]'
                            : 'text-onprimary'
                          : '',
                      ]"
                      class="opacity-1 text-5xl font-bold"
                      >{{ currentState === 'finished' ? result : '0.00' }}</label
                    >
                    <div class="game-canvas">
                      <div class="range-container">
                        <div
                          class="result-container mb-[8px]"
                          :style="'transform: translate(' + result + '%, 0px);'">
                          <div class="svg-img h-8 w-8" :class="showStyle">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 45.55 51.32">
                              <defs />
                              <title />
                              <g id="games">
                                <path
                                  style="fill: #fff"
                                  d="M43.28,11.13,25.05.61a4.56,4.56,0,0,0-4.55,0L2.28,11.13A4.55,4.55,0,0,0,0,15.07v21a4.55,4.55,0,0,0,2.28,3.94L20.5,50.58a4.56,4.56,0,0,0,4.55,0L43.28,40.06a4.56,4.56,0,0,0,2.27-3.94v-21A4.56,4.56,0,0,0,43.28,11.13Z" />
                                <path
                                  style="fill: #e9f0f5"
                                  d="M21.13,24.64,1.85,13.51A1.23,1.23,0,0,0,0,14.57v22a3.69,3.69,0,0,0,1.84,3.19l19.09,11a3.69,3.69,0,0,0,1.85.49,34.48,34.48,0,0,0,0-23.82A3.3,3.3,0,0,0,21.13,24.64Z" />
                                <path
                                  style="fill: #d3dee6"
                                  d="M43.7,13.51,24.42,24.64a3.3,3.3,0,0,0-1.64,2.86V51.32a3.68,3.68,0,0,0,1.84-.49l19.09-11a3.69,3.69,0,0,0,1.84-3.19v-22A1.23,1.23,0,0,0,43.7,13.51Z" />
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div class="">
                          <div :class="leftStyle"></div>
                          <div
                            :class="rightStyle"
                            :style="'width: ' + target + '%;'"></div>
                        </div>
                        <input
                          v-model="target"
                          type="range"
                          min="1.01"
                          max="100"
                          class="range-style"
                          step="0.00000001"
                          @input="changeTarget(target)" />
                      </div>
                      <div class="helper-container">
                        <span class="number-wrap" style="left: 0">
                          <span class="number-span is-unselectable">0</span>
                        </span>
                        <span class="number-wrap" style="left: 25%">
                          <span class="number-span is-unselectable">25</span>
                        </span>
                        <span class="number-wrap" style="left: 50%">
                          <span class="number-span is-unselectable">50</span>
                        </span>
                        <span class="number-wrap" style="left: 75%">
                          <span class="number-span is-unselectable">75</span>
                        </span>
                        <span class="number-wrap" style="left: 100%">
                          <span class="number-span is-unselectable">100</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Floating actions -->
                <div class="flex-none pb-3 lg:pb-10 lg:mx-4">
                  <div
                    class="flex flex-none flex-row justify-between gap-2 rounded-xl bg-side-color p-4 lg:mb-4">
                    <div class="flex-1">
                      <label
                        for="payout"
                        class="mb-2 block text-sm font-medium text-text-dim-color"
                        >Multiplier</label
                      >
                      <div class="relative">
                        <input
                          type="text"
                          id="payout"
                          :disabled="autoBetRunning"
                          v-model="multiplier"
                          @change="changeMultiplier(multiplier)"
                          class="input-bet"
                          placeholder="0" />
                        <div
                          class="pointer-events-auto absolute inset-y-0 right-0 flex items-center space-x-2 pr-1.5">
                          <button type="button" class="btn-multiplier">
                            <svg
                              width="18"
                              height="18"
                              viewBox="-2 -2 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1.74606 0.929688C1.55414 0.929688 1.35344 0.994192 1.20696 1.14044C0.914086 1.43369 0.914086 1.92568 1.20696 2.21893L5.91794 6.92969L1.20696 11.6404C0.914086 11.9337 0.914086 12.4257 1.20696 12.7189C1.49991 13.0114 1.99221 13.0114 2.28516 12.7189L6.99606 8.00819L11.707 12.7189C11.9999 13.0114 12.4922 13.0114 12.7852 12.7189C13.078 12.4257 13.078 11.9337 12.7852 11.6404L8.07419 6.92969L12.7852 2.21893C13.078 1.92568 13.078 1.43369 12.7852 1.14044C12.6387 0.994192 12.4379 0.929688 12.2461 0.929688C12.0541 0.929688 11.8535 0.994192 11.707 1.14044L6.99606 5.85118L2.28516 1.14044C2.13869 0.994192 1.93799 0.929688 1.74606 0.929688Z"
                                fill="#7EA0AD" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="flex-1">
                      <label
                        for="roll-over"
                        class="mb-2 block text-sm font-medium text-text-dim-color">
                        {{ over ? 'Roll Over&nbsp;&nbsp;' : 'Roll Under' }}
                      </label>
                      <div class="relative">
                        <input
                          type="text"
                          id="roll-over"
                          v-model="target"
                          @change="changeMultiplier(multiplier)"
                          disabled
                          readonly
                          class="block h-10 w-full rounded-xl border border-secondary bg-primary p-2.5 pr-16 max-[575px]:pr-10 text-sm font-semibold text-slate-300 placeholder:text-text-dim-color focus:border-green-600 focus:ring-green-600"
                          placeholder="0" />
                        <div
                          class="pointer-events-auto absolute inset-y-0 right-0 flex items-center space-x-2 pr-1.5">
                          <button
                            type="button"
                            v-on:click="toggleDirection"
                            :disabled="autoBetRunning"
                            class="btn-multiplier">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M16.4959 12.0037C16.4959 11.8117 16.4314 11.6115 16.2852 11.4645L13.4959 8.69922L12.4414 9.75372L13.9174 11.2537H2.99592C2.58192 11.2537 2.24592 11.5897 2.24592 12.0037C2.24592 12.4177 2.58192 12.7537 2.99592 12.7537H13.9174L12.4414 14.2537L13.4959 15.3082L16.2852 12.543C16.4314 12.396 16.4959 12.1957 16.4959 12.0037ZM15.7459 6.00372C15.7459 5.58972 15.4099 5.25372 14.9959 5.25372H4.07367L5.55043 3.75372L4.49592 2.69922L1.70668 5.46448C1.41418 5.75773 1.41418 6.24972 1.70668 6.54297L4.49592 9.30823L5.55043 8.25372L4.07367 6.75372H14.9959C15.4099 6.75372 15.7459 6.41772 15.7459 6.00372Z"
                                fill="#7EA0AD" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="flex-1">
                      <label
                        for="payout"
                        class="mb-2 block text-sm font-medium text-text-dim-color"
                        >Win Chance</label
                      >
                      <div class="relative">
                        <input
                          type="text"
                          id="payout"
                          v-model="chance"
                          @change="changeWinChance(chance)"
                          :disabled="autoBetRunning"
                          class="input-bet"
                          placeholder="0" />
                        <div
                          class="pointer-events-auto absolute inset-y-0 right-0 flex items-center space-x-2 pr-1.5">
                          <button type="button" class="btn-multiplier">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M5.25081 2.92969C4.00806 2.92969 3.00081 3.93694 3.00081 5.17968C3.00081 6.42243 4.00806 7.42968 5.25081 7.42968C6.49356 7.42968 7.50081 6.42243 7.50081 5.17968C7.50081 3.93694 6.49356 2.92969 5.25081 2.92969ZM14.2508 2.92969C14.0588 2.92969 13.8586 2.99419 13.7116 3.14044L3.21156 13.6404C2.91906 13.9337 2.91906 14.4257 3.21156 14.7189C3.50481 15.0114 3.9968 15.0114 4.29005 14.7189L14.79 4.21893C15.0825 3.92568 15.0825 3.43369 14.79 3.14044C14.643 2.99419 14.4428 2.92969 14.2508 2.92969ZM5.25081 4.42969C5.66481 4.42969 6.00081 4.76569 6.00081 5.17968C6.00081 5.59368 5.66481 5.92968 5.25081 5.92968C4.83681 5.92968 4.50081 5.59368 4.50081 5.17968C4.50081 4.76569 4.83681 4.42969 5.25081 4.42969ZM12.7508 10.4297C11.5081 10.4297 10.5008 11.4369 10.5008 12.6797C10.5008 13.9224 11.5081 14.9297 12.7508 14.9297C13.9936 14.9297 15.0008 13.9224 15.0008 12.6797C15.0008 11.4369 13.9936 10.4297 12.7508 10.4297ZM12.7508 11.9297C13.1648 11.9297 13.5008 12.2657 13.5008 12.6797C13.5008 13.0937 13.1648 13.4297 12.7508 13.4297C12.3368 13.4297 12.0008 13.0937 12.0008 12.6797C12.0008 12.2657 12.3368 11.9297 12.7508 11.9297Z"
                                fill="#7EA0AD" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <game-actions
            :pre-extras="gameActions"
            class="hidden rounded-br-xl bg-tertiary md:block" />
        </div>
      </div>

      <game-details
        game="Dice"
        edge="1"
        provider="Chanced Originals"
        class="mt-6 md:mt-12" />
    </div>
  </div>

  <game-history-table class="hidden md:flex" />
</template>

<script>
import { betSound, winSound, looseSound, dicingSound } from './sounds';
import { clamp, round } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import AnimatedNumber from 'vue-animated-number';

import BetButton from './BetButton.vue';
import { defaultConvertor } from '../../lib/rsgp';
import ProvablyFairCard from './ProvablyFairCard.vue';
import EditableNumberInput from './EditableNumberInput.vue';

import DicingBets from './DicingBets.vue';
import GameHistoryTable from '../GameHistory/BetsHistory.vue';
import GameActions from '../Shared/GameActions.vue';
import GameDetails from '../Shared/GameDetails.vue';
import axios from '@/lib/myAxios';

export default {
  name: 'Dicing',
  components: {
    AnimatedNumber,
    BetButton,
    EditableNumberInput,
    ProvablyFairCard,
    GameHistoryTable,
    DicingBets,
    GameActions,
    GameDetails,
  },
  data() {
    return {
      betAmount: 5000,
      wager: '5K',
      form: {
        editing: '',
      },
      state: {
        pot: 10e3,
        placingBet: false,
      },
      target: 50,
      chance: null,
      multiplier: null,
      over: true,

      result: 50.0,
      gameId: 0,
      profit: 0,
      won: false,
      autoBetRunning: false,
      resultStyle: 'result-span',
      showStyle: 'result-wrap',

      previousGames: [],
      currentState: 'notStarted',

      houseEdgePercent: 3,
      maxMultiplier: 100,

      //svg color change
      svgResultColor: 'silver',
      gameActions: [
        ['/casino/dice/rules', 'Rules'],
        // ['/casino/dice/fairness', 'Fairness'],
      ],
    };
  },
  created() {
    try 
    {
      axios
      .get(`dicing/limits`)
      .then((res) => res.data)
      .then((limits) => {
        this.houseEdgePercent = limits.houseEdge;
        this.maxMultiplier = limits.maxMultiplier;
        this.changeTarget(50);
      })
      .catch(() => {
        /*window.swal(
          'Uh-oh',
          'Error initializing game. Please refresh page',
          'error'
        )*/
      });
    } catch(error) {
      console.log('fetching failed to dicinglimit');
    }
  },
  computed: {
    ...mapGetters(['me', 'userBalance', 'clientSeed']),
    ...mapGetters('Sound', ['isSoundMuted']),
    leftStyle() {
      return this.over ? 'left-over-style' : 'left-under-style';
    },
    rightStyle() {
      return this.over ? 'right-over-style' : 'right-under-style';
    },
  },
  watch: {
    userBalance(x, last) {},
    multiplier(x) {
      this.state.pot = x * this.betAmount;
    },
    wager(x) {
      this.state.pot = this.multiplier * defaultConvertor.parse(x).amount;
    },
    tab() {
      this.page = 1;
    },
  },
  methods: {
    lastGameStyle(game) {
      const backgroundColor =
        'background-color: #' + (game.won ? '1EE563' : '293C49') + ';';
      const color = 'color: #' + (game.won ? '000000' : 'ffffff') + ';';
      return (
        'border-radius: 999px; margin: 2px; text-align: center; vertical-align: middle; ' +
        color +
        backgroundColor
      );
    },
    autoBetChanged(running) {
      this.autoBetRunning = running;
    },
    changeTarget(newTarget) {
      this.target = newTarget;
      this.chance = round(
        clamp(this.over ? 100 - this.target : this.target, 0.01, 99.99),
        2,
      );
      this.multiplier = round(
        clamp((100.0 - this.houseEdgePercent) / this.chance, 1.01, this.maxMultiplier),
        2,
      );
      this.chance = round(
        clamp((100.0 - this.houseEdgePercent) / this.multiplier, 0.01, 99.99),
        2,
      );
      this.target = round(this.over ? 100 - this.chance : this.chance, 2);
    },
    toggleDirection() {
      this.over = !this.over;
      this.target = round(100 - this.target, 2);
    },
    changeWinChance(newChance) {
      this.chance = round(clamp(newChance, 0.01, 99.99), 2);
      this.multiplier = round(
        clamp((100.0 - this.houseEdgePercent) / this.chance, 1.01, this.maxMultiplier),
        2,
      );
      this.chance = round(
        clamp((100.0 - this.houseEdgePercent) / this.multiplier, 0.01, 99.99),
        2,
      );
      this.target = round(this.over ? 100 - this.chance : this.chance, 2);
    },
    changeMultiplier(newMultiplier) {
      this.multiplier = round(clamp(newMultiplier, 1.01, this.maxMultiplier), 2);
      this.chance = round(
        clamp((100.0 - this.houseEdgePercent) / this.multiplier, 0.01, 99.99),
        2,
      );
      this.target = round(this.over ? 100 - this.chance : this.chance, 2);
    },
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.play();
      }
    },
    play(wager, endEarly) {
      if (this.houseEdgePercent === null) {
        window.swal('Uh-oh', 'Error initializing play. Please refresh page', 'error');
        return;
      }
      this.playSound(betSound);
      this.currentState = 'inprogress';

      try {
        axios
        .post(`dicing`, {
          wager: wager,
          over: this.over,
          multiplier: this.multiplier,
          target: this.target,
        })
        .then((res) => res.data)
        .then((state) => {
          this.playSound(dicingSound);
          this.profit = state.profit;
          this.won =
            (this.over && state.roll > this.target) ||
            (!this.over && state.roll < this.target);
          this.gameId = state.gameId;
          this.result = state.roll.toFixed(2);

          if (this.profit > 0) {
            this.resultStyle = 'result-span-win';
            this.svgResultColor = '#1EE563';
          } else {
            this.resultStyle = 'result-span-loss';
            this.svgResultColor = '#FF243E';
          }
          this.showStyle = 'result-wrap-show';

          setTimeout(
            function (self) {
              if (self.profit > 0) {
                self.playSound(winSound);
              } else {
                self.playSound(looseSound);
              }
            },
            200,
            this,
          );

          setTimeout(
            function (self, state) {
              self.previousGames.push({
                roll: state.roll.toFixed(2),
                id: state.gameId,
                won: self.won,
              });
              if (self.previousGames.length > 5) {
                self.previousGames.shift();
              }
            },
            700,
            this,
            state,
          );

          setTimeout(
            function (self, endEarly) {
              self.currentState = 'finished';
              self.svgResultColor = self.won === 'finished' ? 'text-[#1EE563]' : '';
              if (!endEarly) {
                self.resultStyle = 'result-span';
                self.showStyle = 'result-wrap';
              } else {
                setTimeout(
                  function (self) {
                    if (self.currentState === 'finished') {
                      self.resultStyle = 'result-span';
                      self.showStyle = 'result-wrap';
                    }
                  },
                  550,
                  self,
                );
              }
            },
            endEarly ? 350 : 850,
            this,
            endEarly,
          );
        })
        .catch(({ data, status }) => {
          this.currentState = 'failed';
        
          if (status === 403 && typeof data.error != 'undefined' ) {
            window.swal('Uh-oh', 'Not allowed to play: ' + ((typeof data.error != 'undefined') ? data.error:''), 'error');
          } else {
            if (typeof data.error != 'undefined'  && data.error === 'Unauthenticated.') {
              this.$router.replace('?tab=login');
            } else {
              window.swal(
                'Uh-oh',
                'Something went wrong when placing your play: ' + ((typeof data.error != 'undefined') ? data.error:''),
                'error',
              );
            }
          }
        });
      } catch(error) {
        console.log('Some error ');
      }
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    ...mapActions(['placeBet', 'showProvablyFair', 'refreshSeeds']),
    ...mapActions('Sound', ['toggleSound']),
    scaleWager(x) {
      const desiredWager = x * this.betAmount;
      if (desiredWager <= this.userBalance) {
        this.wager = this.rsgp(desiredWager);
      } else {
        this.wager = this.rsgp(this.userBalance);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.justify-content-start {
  justify-content: flex-start;
}

.centralizer {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 100%;
  padding-bottom: 1em;
}

.control.has-text-centered {
  text-align: center;
}
.is-uppercase {
  text-transform: uppercase;
}
.has-pointer-cursor {
  cursor: pointer;
}
.has-south-east-stickiness {
  align-self: flex-end;
}
.panel table {
  margin-bottom: 0px;
}
</style>

<style type="text/css" scoped>
.no-underline {
  border-bottom: none;
}
</style>

<style type="text/css">
.text-label {
  fill: #363636;
}
.dark .text-label {
  fill: #fff;
}

.game-canvas {
  position: relative;
  width: 100%;
  max-width: 730px;
  text-align: left;
  border-radius: 100px;
  padding: 1.5em;
  background: #252546;
  margin: 4em 0px;
}

.helper-container {
  position: absolute;
  bottom: 100%;
  left: calc(1.5em + 4px);
  right: calc(1.5em + 4px);
}

.number-wrap {
  position: absolute;
  text-align: center;
  transform: translate(-50%, 0px);
}

.number-wrap::after {
  content: '';
  bottom: 0px;
  position: absolute;
  transform: translate(-50%, 0px);
  width: 0px;
  height: 0px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: rgb(46, 68, 82);
  border-left-color: transparent;
  border-width: 0px 4px 5px;
  border-style: solid;
}

.number-span {
  position: absolute;
  font-weight: 600;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  font-family: proxima-nova, sans-serif;
}

.range-container {
  position: relative;
  height: 8px;
}

.result-container {
  position: absolute;
  display: flex;
  bottom: 50%;
  left: 0px;
  right: 0px;
  z-index: 5;
  pointer-events: none;
  will-change: transform;
  transition: transform 400ms ease 0s;
}

.result-wrap {
  position: relative;
  transform-origin: center bottom;
  transform: translateX(-50%) scale(0.8);
  opacity: 0;
  transition: all 0.3s ease 0s;
  animation: 0s ease 0s 1 normal none running none;
}

.result-wrap-show {
  position: relative;
  transform: translateX(-50%);
  transform-origin: center bottom;
  transition: all 0.3s ease 0s;
  animation: 500ms ease 0s 1 normal none running gGjlxm;
}

.svg-img {
  width: 7ch;
  height: auto;
  filter: drop-shadow(rgba(25, 25, 25, 0.1) 0px 0px 3px);
}

.result-span {
  color: rgb(183, 191, 214);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: rgb(255, 255, 255) 0px 1px 0px;
  font-weight: 600;
  transition: all 100ms ease 0s;
}

.result-span-win {
  color: rgb(0, 228, 3);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: rgb(255, 255, 255) 0px 1px 0px;
  font-weight: 600;
  transition: all 100ms ease 0s;
}

.result-span-loss {
  color: rgb(255, 34, 71);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: rgb(255, 255, 255) 0px 1px 0px;
  font-weight: 600;
  transition: all 100ms ease 0s;
}

.background-wrap {
  pointer-events: none;
  height: 8px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  box-shadow: rgb(4, 29, 39) 0px 0px 0px 10px;
  border-radius: 100px;
  transition: opacity 300ms ease 0s;
  overflow: hidden;
}

.range-style {
  -webkit-appearance: none;
  width: 100%;
  position: absolute;
  z-index: 10;
  top: 0px;
  height: 8px;
  pointer-events: none;
  background: rgba(0, 0, 0, 0);
}

.range-style:focus {
  outline: none;
}

.left-over-style {
  position: absolute;
  height: 8px;
  width: 100%;
  right: 0px;
  top: 0px;
  background: rgb(0, 228, 3);
  border-radius: 100px;
}

.right-over-style {
  position: absolute;
  height: 8px;
  top: 0px;
  left: 0px;
  background: rgb(255, 34, 71);
  border-radius: 100px;
}

.right-under-style {
  position: absolute;
  height: 8px;
  top: 0px;
  left: 0px;
  background: rgb(0, 228, 3);
  border-radius: 100px;
}

.left-under-style {
  position: absolute;
  height: 8px;
  width: 100%;
  right: 0px;
  top: 0px;
  background: rgb(255, 34, 71);
  border-radius: 100px;
}

.range-style::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  pointer-events: none;
  background: rgba(0, 0, 0, 0);
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
}

input[type='range' i]::-webkit-slider-runnable-track {
  min-width: 0px;
  align-self: center;
  box-sizing: border-box;
  display: block;
  -webkit-user-modify: read-only !important;
  flex: 1 1 0%;
}

input[type='range' i]::-webkit-slider-container {
  -webkit-appearance: inherit;
  min-width: 0px;
  box-sizing: border-box;
  display: flex;
  -webkit-user-modify: read-only !important;
  flex: 1 1 0%;
}

input[type='range' i]::-webkit-slider-thumb,
input[type='range' i]::-moz-range-thumb {
  box-sizing: border-box;
  display: block;
  -webkit-user-modify: read-only !important;
}

.range-style::-moz-range-thumb {
  margin-top: -8px;
  height: 36px;
  width: 36px;
  background-color: rgb(48, 122, 246);
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22640%22%20height%3D%22640%22%20viewBox%3D%220%200%20640%20640%22%3E%3Cpath%20fill%3D%22%232a67d1%22%20d%3D%22M544%200v640h-64v-640h64zM352%200v640h-64v-640h64zM160%200v640h-64v-640h64z%22%20%2F%3E%3C%2Fsvg%3E');
  background-size: 40%;
  cursor: grab;
  -webkit-appearance: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
  transform: translateY(-8px);
  pointer-events: auto;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 3px;
}

.range-style::-webkit-slider-thumb {
  margin-top: -8px;
  height: 36px;
  width: 36px;
  background-color: rgb(48, 122, 246);
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22640%22%20height%3D%22640%22%20viewBox%3D%220%200%20640%20640%22%3E%3Cpath%20fill%3D%22%232a67d1%22%20d%3D%22M544%200v640h-64v-640h64zM352%200v640h-64v-640h64zM160%200v640h-64v-640h64z%22%20%2F%3E%3C%2Fsvg%3E');
  background-size: 40%;
  cursor: grab;
  -webkit-appearance: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
  transform: translateY(-8px);
  pointer-events: auto;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 3px;
}
</style>
