<template>
  <div class="w-full flex flex-col items-center">
    <div
      :class="{ 'p-4 md:p-8 max-w-[1200px]': !generalS.isTheatreMode }"
      class="w-full flex flex-col items-center">
      <div class="flex flex-col w-full max-w-[calc(138vh)] 2xl:max-w-[calc(160vh)]">
        <div class="flex flex-col w-full h-full items-center">
          <!-- relative bg-[#0F212E] w-full  rounded-t-xl flex flex-row justify-center items-center h-full     -->
          <div
            id="softswiss_game_wrapper"
            class="relative w-full child:rounded-t-lg md:h-0 pt-[56.25%] child:absolute child:top-0 child:left-0 child:w-full child:h-full">
            <!--  <div v-if="gameDispatcher == null"  class="absolute w-full h-full inset-0 z-10 bg-[#0e1224]/90 flex flex-row justify-center items-center rounded-t-xl">
              <div class="flex flex-col items-center z-50 justify-center gap-4" v-if="!loading">
                <fiat-dropdown :show-balance-label="true"/>
                <div class="flex flex-row gap-4">
                  <button class="flex flex-row items-center  bg-[#00e701] text-black font-semibold px-4 py-3 rounded-md"
                          v-on:click="launchGame(false)">
                    {{ !isLoggedIn ? 'Login' : 'Real Play' }}
                  </button>
                  <button v-if="this.softSwisGame && !this.softSwisGame.has_live" class="bg-[#2f4553] px-4 py-2 rounded-md font-semibold" v-on:click="launchGame(true)">
                    Fun Play
                  </button>
                </div>
              </div>
              <div v-else class="flex flex-row text-gray-200">
                <svg class="animate-spin -ml-1 mr-3 h-10 w-10 text-white" fill="none" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        fill="currentColor"></path>
                </svg>
              </div>
            </div> -->
          </div>
          <game-actions
            :document-id="'softswiss_game_wrapper'"
            :game-data="gameData"
            class="bg-secondary-light rounded-b-lg" />
        </div>
        <game-details
          v-if="softSwisGame"
          :game="softSwisGame.title"
          :edge="softSwisGame.house_edge"
          :provider="softSwisGame.provider.name"
          class="mt-6 md:mt-12" />
      </div>
    </div>
  </div>
  <template class="flex flex-col items-center">
    <game-history-table class="hidden md:flex w-full max-w-[1200px]" />
  </template>
</template>

<script>
import { mapGetters } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import { getErrorCodeMessage } from '@/utils';

import GameHistoryTable from '../GameHistory/BetsHistory.vue';
import GameActions from '../Shared/GameActions.vue';
import { softswissStore } from '@/store/pinia/softswissStore';
import FiatDropdown from '@/components/Bank/FiatDropdown.vue';
import { balanceStore } from '@/store/pinia/balanceStore';
import { generalStore } from '@/store/pinia/generalStore';
import GameDetails from '@/components/Shared/GameDetails.vue';

export default {
  name: 'Softswiss',
  components: {
    GameDetails,
    FiatDropdown,
    GameHistoryTable,
    GameActions,
  },
  setup() {
    const softS = softswissStore();
    const balS = balanceStore();
    const generalS = generalStore();
    return { softS, balS, generalS };
  },
  data() {
    return {
      loading: false,
      gameDispatcher: null,
      demoLoaded: false,
      softSwisGame: null,
      game: '',
      provider: '',
    };
  },
  async created() {
    await this.init();
    await this.launchGame(false, false);
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
    ...mapGetters('Sound', ['isSoundMuted']),
    gameData() {
      if (this.softSwisGame) {
        return { id: this.softSwisGame.id, name: null, software: 'softswiss' };
      }
      return { id: null, name: null };
    },
  },
  watch: {
    '$route.params.name': {
      async handler() {
        await this.init();
        //auto launch demo mode on game started
        //await this.launchGame(true, true)
      },
      // force eager callback execution
      immediate: true,
    },
    'balS.defaultFiat': {
      async handler() {
        const isDemo = this.softSwisGame ? this.softSwisGame.has_live : true;
        // await this.launchGame(isDemo, !this.gameDispatcher)
        await this.launchGame(false, true);
      },
      // force eager callback execution
      immediate: true,
    },
  },
  methods: {
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.pause();
        sound.currentTime = 0;
        sound.play();
      }
    },
    async init() {
      try {
        this.softSwisGame = null;
        this.loading = true;
        this.parseGameName(this.$route.params.name);
        const { data } = await this.softS.fetchGame(`${this.provider}:${this.game}`);
        this.softSwisGame = data;
      } catch (e) {
        const { message } = getErrorCodeMessage(e, 'Unable to fetch game!');
        this.$toast.error('Something went wrong: ' + message);
      } finally {
        this.loading = false;
      }
    },
    parseGameName(game) {
      this.provider = game.split('-')[0];
      this.game = game
        .split('-')
        .slice(1)
        .map((element) => {
          return element[0] + element.slice(1);
        })
        .join('');
    },
    async launchGame(isDemo = true, isInitial = false) {
      if (!isDemo && !this.isLoggedIn) {
        await this.$router.push('/auth/login');
        return;
      }

      if (!this.softSwisGame || (this.softSwisGame.has_live && isDemo)) {
        return;
      }

      try {
        if (this.demoLoaded && isDemo) {
          this.gameDispatcher = 'gameDispatcher';
          return;
        }

        if (!isInitial) {
          this.loading = true;
        }

        const { data } = await this.softS.createGameSession(
          isMobileOnly,
          this.softSwisGame.id,
          isDemo,
          this.balS.defaultFiat,
        );
        
     
        let gameLaunchOptions = { target_element: 'softswiss_game_wrapper', ...data };

        // this is hotfix as softswiss always returning detect
        gameLaunchOptions.launch_options.strategy='iframe';
        console.log(gameLaunchOptions);
        const onSuccess = (gameDispatcher) => {
          if (!isInitial) {
            this.gameDispatcher = 'gameDispatcher';
          } else {
            this.demoLoaded = true;
          }
          this.loading = false;
        };
        const onError = (error) => {
          this.loading = false;
        };
        window.sg.launch(gameLaunchOptions, onSuccess, onError);
      } catch (e) {
        const { message, code } = getErrorCodeMessage(e, 'Unable to launch game!');
        if (code === 700) {
          this.softSwisGame.has_live = true;
          if (isDemo) {
            return;
          }
        }
        this.$toast.error('Something went wrong: ' + message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.justify-content-start {
  justify-content: flex-start;
}

.centralizer {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 100%;
  padding-bottom: 1em;
}

.control.has-text-centered {
  text-align: center;
}

.is-uppercase {
  text-transform: uppercase;
}

.has-pointer-cursor {
  cursor: pointer;
}

.has-south-east-stickiness {
  align-self: flex-end;
}

.panel table {
  margin-bottom: 0px;
}

@media (max-width:767px)
{
  #softswiss_game_wrapper
  {
    height: calc(100dvh - 65px - 79px - 33px);
    padding: 0;
  }
}
@media (max-width:480px)
{
  #softswiss_game_wrapper
  {
    height: calc(100dvh - 54px - 60px - 33px);
    padding: 0;
  }
}

</style>

<style scoped>
iframe {
}
</style>
