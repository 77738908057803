import map from 'lodash/map';
import getProp from 'lodash/get';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import axios from '@/lib/myAxios';

const ChatTypes = {
  CONNECTED: 'chat/CONNECTED',
  DISCONNECTED: 'chat/DISCONNECTED',
  ADD_MESSAGE: 'chat/ADD_MESSAGE',
  CREATE_ROOM: 'chat/CREATE_ROOM',
  CHANGE_ROOM: 'chat/CHANGE_ROOM',
  LEAVE_ROOM: 'chat/LEAVE_ROOM',
  ADD_ROOM_LIST: 'chat/ADD_ROOM_LIST',
  REMOVE_ROOM_LIST_MESSAGE: 'chat/REMOVE_ROOM_LIST_MESSAGE',
  IGNORE_USER: 'chat/IGNORE_USER',
  UNIGNORE_USER: 'chat/UNIGNORE_USER',
  USER_LIST: 'chat/USER_LIST',
  USER_JOIN: 'chat/USER_JOIN',
  USER_LEAVE: 'chat/USER_LEAVE',
  UPDATE_SEARCH: 'chat/UPDATE_SEARCH',
  SET_TITLES: 'chat/SET_TITLES',
  HIDE_MESSAGE: 'chat/HIDE_MESSAGE',
  SET_ROSTER_VISIBLE: 'chat/SET_ROSTER_VISIBLE',
};

export default {
  state: {
    connected: false,
    currentRoom: 'lobby',
    ignoredUsers: {},
    draft: '',
    messages: [],
    rooms: [],
    roomUsers: {},
    titles: {},
    allRoomsList: [],
    rosterOpen: false,
    searchText: '',
  },
  actions: {
    chatConnect({ commit }) {
      commit(ChatTypes.CONNECTED);
      if ('localStorage' in window) {
        const saved = window.localStorage.getItem('ignored-users');
        if (saved) {
          saved.split(',').forEach((id) => commit(ChatTypes.IGNORE_USER, +id));
        }
      }
    },
    chatDisconnect({ commit }) {
      commit(ChatTypes.CONNECTED);
    },
    createRoom({ commit }, { id, name }) {
      commit(ChatTypes.CREATE_ROOM, { id, name });
    },
    changeRoom({ commit }, id) {
      commit(ChatTypes.CHANGE_ROOM, id);
    },
    ignoreUser({ commit, state }, user) {
      window
        .swal({
          title: 'Are you sure?',
          text: `You will no longer be able to see messages from ${user.username}.`,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        })
        .then(() => {
          commit(ChatTypes.IGNORE_USER, user.id);
          if ('localStorage' in window) {
            window.localStorage.setItem(
              'ignored-users',
              Object.keys(state.ignoredUsers).join(','),
            );
          }
        }, window.swal.noop);
    },
    leaveRoom({ commit }, id) {
      commit(ChatTypes.LEAVE_ROOM, id);
    },
    joinChat({ commit }, { roomId, user }) {
      commit(ChatTypes.USER_JOIN, { roomId, user });
    },
    leaveChat({ commit }, { roomId, user }) {
      commit(ChatTypes.USER_LEAVE, { roomId, user });
    },
    roomJoined({ commit }, { roomId, users }) {
      commit(ChatTypes.USER_LIST, { roomId, users });
    },
    toggleRoster({ commit, state }) {
      if (state.rosterOpen) {
        commit('setBzFlag', false, { root: true });
      }

      commit(ChatTypes.SET_ROSTER_VISIBLE, !state.rosterOpen);
    },
    setRosterVisibility({ commit }, flag) {
      commit(ChatTypes.SET_ROSTER_VISIBLE, flag);
    },
    addMessage({ commit, rootState }, msg) {
      commit(ChatTypes.ADD_MESSAGE, { roomId: msg.message.room_id, ...msg });

      commit(ChatTypes.ADD_ROOM_LIST, {
        id: msg.message.room_id,
        last_chat_message: Object.assign(Object.assign({}, msg.message), {
          user: msg.sender,
        }),
        recipient:
          rootState.user && msg.sender.id !== rootState.user.id
            ? Object.assign({}, msg.sender)
            : null,
      });
    },
    deleteMessage({ commit }, { roomId, messageId }) {
      try {
        axios
        .delete('chat_messages/' + messageId, { roomId })
        .then(({ data }) => {
          commit(ChatTypes.HIDE_MESSAGE, { roomId, messageId });

          commit(ChatTypes.REMOVE_ROOM_LIST_MESSAGE, { roomId, messageId });
        })
        .catch(({ data }) => {
        });
      } catch(error) {
        console.log('issue while deleting message');
      }
    },
    hideMessage({ commit }, { roomId, messageId }) {
      commit(ChatTypes.HIDE_MESSAGE, { roomId, messageId });

      commit(ChatTypes.REMOVE_ROOM_LIST_MESSAGE, { roomId, messageId });
    },
    fetchScrollback({ commit }, roomId) {
      return axios.get('chat_messages/' + roomId).then(({ data }) => {
        data.data.reverse().forEach((msg) => commit(ChatTypes.ADD_MESSAGE, msg));
      });
    },
    fetchAllChatRooms({ commit }) {
      try {
        axios.get('chat_rooms').then(({ data }) => {
          data.data.sort((a, b) => {
            const aDate = new Date(
              a.last_chat_message ? a.last_chat_message.created_at : a.created_at,
            ).getTime();
            const bDate = new Date(
              b.last_chat_message ? b.last_chat_message.created_at : b.created_at,
            ).getTime();
  
            return aDate - bDate;
          });
  
          data.data.forEach((msg) => commit(ChatTypes.ADD_ROOM_LIST, msg));
        });
      } catch(error) {
        console.log('some issue while fetching chat rooms');
      }
    },
  },
  getters: {
    chatTitles: (state) => state.titles,
    rosterVisible: (state) => state.rosterOpen,
    chatRooms: (state) => [
      ...state.rooms.filter((x) => x.id === 'lobby'),
      ...state.rooms.filter((x) => x.id !== 'lobby'),
    ],
    activeRoom: (state) => state.currentRoom,
    chatRoomUsers: (state) => {
      if (state.currentRoom in state.roomUsers) {
        return state.roomUsers[state.currentRoom].length;
      }

      return -1;
    },
    privateChatRoomsMessageList: (state) =>
      state.allRoomsList.filter((room) => room.last_chat_message && room.id !== 'lobby'),
    isConnected: (state) => state.connected,
    chatMessages: (state) => {
      return state.messages.filter(
        (x) =>
          x.message.room_id === state.currentRoom && !(x.sender.id in state.ignoredUsers),
      );
    },
    rosterSearch: (state) => state.searchText,
    roster: (state) => {
      const q = state.searchText.toLowerCase();
      if (q) {
        return (state.roomUsers[state.currentRoom] || []).filter((x) =>
          x.username.toLowerCase().includes(q),
        );
      }
      return state.roomUsers[state.currentRoom] || [];
    },
    rosterByTitle: (state, { roster }) => {
      const partitioned = groupBy(roster, (x) => getProp(x, 'chatTitle', 'user'));
      const transformGroup = (users, title) => ({
        ...(state.titles[getProp(users, [0, 'chatTitle'])] || {
          title: 'User',
          slug: 'user',
          rank: {
            order: 999,
          },
        }),
        users,
      });
      return orderBy(map(partitioned, transformGroup), ['rank.order'], ['asc']);
    },
  },
  mutations: {
    [ChatTypes.SET_ROSTER_VISIBLE](state, flag) {
      state.rosterOpen = flag;
    },
    [ChatTypes.HIDE_MESSAGE](state, { roomId, messageId }) {
      state.messages = state.messages.filter((x) => x.message.id !== messageId);
    },
    [ChatTypes.ADD_MESSAGE](state, { roomId, ...msg }) {
      const messages = [...state.messages.filter((x) => x.message.id !== msg.message.id)];
      if (messages.length >= 35) {
        messages.shift();
      }
      messages.push(msg);
      state.messages = messages;
    },
    [ChatTypes.CREATE_ROOM](state, { id, name }) {
      state.roomUsers[id] = [];
      state.rooms = [...state.rooms.filter((x) => x.id !== id), { id, name }];
    },
    [ChatTypes.USER_JOIN](state, { roomId, user }) {
      state.roomUsers = {
        ...state.roomUsers,
        [roomId]: [
          ...(state.roomUsers[roomId] || []).filter((x) => x.id !== user.id),
          user,
        ],
      };
    },
    [ChatTypes.UPDATE_SEARCH](state, q) {
      state.searchText = q;
    },
    [ChatTypes.USER_LEAVE](state, { roomId, user }) {
      state.roomUsers = {
        ...state.roomUsers,
        [roomId]: (state.roomUsers[roomId] || []).filter((x) => x.id !== user.id),
      };
    },
    [ChatTypes.USER_LIST](state, { roomId, users }) {
      state.roomUsers = {
        ...state.roomUsers,
        [roomId]: users,
      };
    },
    [ChatTypes.IGNORE_USER](state, id) {
      state.ignoredUsers = { [id]: true, ...state.ignoredUsers };
    },
    [ChatTypes.CHANGE_ROOM](state, id) {
      state.currentRoom = id;
    },
    [ChatTypes.LEAVE_ROOM](state, id) {
      state.rooms = state.rooms.filter((x) => x.id !== id);
      state.currentRoom = 'lobby';
    },
    [ChatTypes.ADD_ROOM_LIST](state, room, rootState) {
      let recipient;

      const allRoomsList = [...state.allRoomsList];

      for (let i = 0; i < allRoomsList.length; i++) {
        if (allRoomsList[i].id === room.id) {
          const existingRoom = allRoomsList[i];
          const existingRoomMessageTime = existingRoom.last_chat_message
            ? new Date(existingRoom.last_chat_message).getTime()
            : null;

          if (
            room.last_chat_message &&
            (!existingRoomMessageTime ||
              new Date(room.last_chat_message.created_at).getTime() >
                existingRoomMessageTime)
          ) {
            recipient = null;
            if (room.recipient) {
              recipient = room.recipient;
            } else if (existingRoom.recipient) {
              recipient = Object.assign({}, existingRoom.recipient);
            }

            allRoomsList.splice(i, 1);

            allRoomsList.unshift(Object.assign(room, { recipient }));

            state.allRoomsList = allRoomsList;
          }

          return;
        }
      }

      allRoomsList.unshift(room);

      state.allRoomsList = allRoomsList;
    },
    [ChatTypes.REMOVE_ROOM_LIST_MESSAGE](state, { roomId, messageId }) {
      const allRoomsList = [...state.allRoomsList];

      for (let i = 0; i < allRoomsList.length; i++) {
        if (allRoomsList[i].id === roomId) {
          if (
            allRoomsList[i].last_chat_message &&
            allRoomsList[i].last_chat_message.id === messageId
          ) {
            allRoomsList[i].last_chat_message.body = null;

            state.allRoomsList = allRoomsList;
          }

          return;
        }
      }
    },
    [ChatTypes.CONNECTED](state) {
      state.connected = true;
    },
    [ChatTypes.DISCONNECTED](state) {
      state.connected = false;
    },
    [ChatTypes.SET_TITLES](state, titles) {
      state.titles = titles;
    },
  },
};
