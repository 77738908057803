import { clamp, round } from 'lodash';
import axios from '@/lib/myAxios';

// const { postFetch } = require('./http')

const DicingMutations = {
  APPEND_ROWS: 'dicing/APPEND_ROWS',
  DISABLE_BETTING: 'dicing/DISABLE_BETTING',
  ENABLE_BETTING: 'dicing/ENABLE_BETTING',
  SET_MULTIPLIER: 'dicing/SET_MULTIPLIER',
  SET_OVER_TARGET: 'dicing/SET_OVER_TARGET',
  SET_WIN_CHANCE: 'dicing/SET_WIN_CHANCE',
};

export default {
  state: {
    controls: {
      overTarget: true,
      multiplier: 2.0,
      winChance: 45.0,
    },
    tables: {
      all: [],
      big: [],
      mine: [],
    },
    enabled: true,
  },
  getters: {
    dicingMultiplier: (state) => state.controls.multiplier,
    dicingOverTarget: (state) => state.controls.overTarget,
    dicingWinChance: (state) => state.controls.winChance,
    canPlaceBet: (state) => state.enabled,
  },
  actions: {
    clampDicingControls({ commit, state }, desired) {
    },
    updateDicingMultiplier({ commit }, multiplier) {
      commit(DicingMutations.SET_WIN_CHANCE, round(clamp(90 / multiplier, 0.018, 98), 3));
      commit(DicingMutations.SET_MULTIPLIER, round(clamp(multiplier, 1.01, 5000), 3));
    },
    updateDicingOverTarget({ commit }, flag) {
      commit(DicingMutations.SET_OVER_TARGET, !!flag);
    },
    updateDicingWinChance({ commit }, chance) {
      commit(DicingMutations.SET_MULTIPLIER, round(clamp(90 / chance, 1.01, 5000), 3));
      commit(DicingMutations.SET_WIN_CHANCE, round(clamp(chance, 0.018, 98), 3));
    },
    placeBet({ commit, getters, dispatch }, wager) {
      commit(DicingMutations.DISABLE_BETTING);
      return axios
        .post('dicing', {
          wager,
          over: getters.dicingOverTarget,
          multiplier: getters.dicingMultiplier,
          target: 50,
          seeds: {
            client: getters.clientSeed,
            seed_lease_token: getters.seedLeaseToken,
          },
        })
        .then(() => {
          commit(DicingMutations.ENABLE_BETTING);
        })
        .catch(({ data, status }) => {
          commit(DicingMutations.ENABLE_BETTING);
        
          if ((typeof data.error != 'undefined')   && status === 412 && data.error === 'Client seed must be set!') {
            return dispatch('promptClientSeed');
            // return window.swal('', '', 'info')
            //   .then(() => , window.swal.noop)
          } else {
            if ((typeof data.error != 'undefined')  && data.error === 'Unauthenticated.') {
              window.location.replace(window.location.href + '?tab=login');
            } else {
              window.swal(
                'Uh-oh',
                'Something went wrong when placing your play: ' + ((typeof data.error != 'undefined')? data.error:''),
                'error',
              );
            }
          }
        });
    },
  },
  mutations: {
    [DicingMutations.APPEND_ROWS](state, { table, rolls }) {
      state.tables[table] = [...rolls, ...state.tables[table]].slice(0, 9);
    },
    [DicingMutations.SET_MULTIPLIER](state, multiplier) {
      state.controls.multiplier = multiplier;
    },
    [DicingMutations.SET_OVER_TARGET](state, flag) {
      state.controls.overTarget = flag;
    },
    [DicingMutations.SET_WIN_CHANCE](state, chance) {
      state.controls.winChance = chance;
    },
    [DicingMutations.DISABLE_BETTING](state) {
      state.enabled = false;
    },
    [DicingMutations.ENABLE_BETTING](state) {
      state.enabled = true;
    },
  },
};
