<template>
  <Popover v-slot="{ open }" class="relative max-h-full">
    <PopoverButton
      :class="open ? '' : 'text-opacity-90'"
      class="bg-[rgb(37_37_70_/_60%)] px-2 py-2 md:px-4 rounded-lg font-semibold flex flex-inline items-center transition ease-in-out delay-0 duration-150">
      <div class="flex flex-row space-x-2 items-center text-slate-300">
        <span class="text-sm pl-1 flex flex-row"> Categories </span>
        <img alt="icon" src="../../assets/svg/chevron.svg" class="mx-2" />
      </div>
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0">
      <PopoverPanel
        class="absolute left-0 z-50 min-w-[250px] max-w-[250px] md:px-4 mt-3 transform md:-translate-x-1/2 md:left-1/2 sm:px-0">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
          <perfect-scrollbar class="rounded-md overflow-y-scroll">
            <div class="overflow-y-auto relative bg-slate-100 pt-4">
              <button
                v-for="item in categories"
                :key="item"
                v-on:click="toggleCategory(item)"
                class="w-full px-2 md:px-4 py-1 cursor-pointer transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                <div
                  class
                  ="flex flex-row space-x-2 w-full justify-between pointer-events-none">
                  <div
                    class="flex items-center justify-start gap-1.5 pointer-events-none text-white">
                    <input
                      :checked="isSelected(item)"
                      v-on:click="toggleCategory(item)"
                      type="checkbox"
                      class="w-4 h-4 pointer-events-none text-[#1EE563] bg-gray-100/10 rounded border-gray-300 focus:ring-green-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label
                      :for="item"
                      class="pointer-events-none ml-1 text-[0.9rem] cursor-pointer font-medium text-gray-900">
                      {{ item }}
                    </label>
                  </div>
                  <!-- <div class="text-end text-black/70">
                    <p
                      class="bg-green-500 font-bold rounded-2xl px-2 py-0.5 text-[0.5rem]">
                      {{ item.count }}
                    </p>
                  </div> -->
                </div>
              </button>
              <div class="border-t-2 p-1">
                <popover-button
                  v-on:click="clearAll"
                  class="w-full py-2 text-gray-900 hover:bg-black/10 text-center rounded-md text-xs">
                  Clear All
                </popover-button>
              </div>
            </div>
          </perfect-scrollbar>
          <div
            class="hidden flex flex-row p-8 justify-center items-center min-h-40 bg-slate-100 rounded-md">
            <svg
              class="animate-spin -ml-1 mr-3 h-10 w-10 text-rh-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24">
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import {
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
} from '@headlessui/vue';

export default {
  name: 'CategoryFilters',
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    PopoverOverlay,
  },
  setup() {
    return {};
  },
  props: {
    categories: {
      default: [],
    },
    selectedCategories: {
      default: [],
    },
    clearAll: {
      default: () => {},
    },
    toggleCategory: {
      default: () => {},
    },
  },
  computed: {},
  methods: {
    isSelected(item) {
      return this.selectedCategories.includes(item);
    },
  },
};
</script>
