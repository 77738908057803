<template>
      <punt-modal v-if="ShowUpcomingRacepopup"
    size="md:max-w-xl max-w-[92%] "
    :onClose="closeModal" :showClose="true">
    <template #title>
      <div class="flex flex-col py-5 pl-5">
        <span class="font-bold text-lg">Hey !!</span>
      </div>
    </template>
    <template #body>
        <div class="flex flex-row pb-5 pl-5 pr-2 text-white" >

                <div class="flex-auto pr-5 ">
                    <div class="timer mb-5 font-bold text-sm ">
                        <div class="bg-secondary-light rounded-md px-3 py-2 inline-block w-[110px] ">
                            <span class="  verticalign-middle mr-1"><svg class="max-w-[20px] inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#FFD43B" d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg></span>
                            <span>{{ remainingTime.days }}d {{ remainingTime.hours }}h</span>
                        </div>
                    </div>
                    <h3 class="font-bold mb-2 text-lg">$100,000 Weekly Raffle</h3>
                    <p class="mb-10">The more you play, the more chances you'll have to win!</p>

                    
                    <button @click="learnMore"   class="btn-bet bg-accent text-white shadow-btn rounded rounded-2xl hover:opacity-75 px-6 py-3 w-auto "> 
                        Learn More
                    </button>

                </div>
                 <div class="flex-auto w-[200px]">
                    <img src="/images/rafel-test.png" alt="$100,000 Weekly Raffle" />
                 </div>
                
        </div>
    </template>

    </punt-modal>
</template>
<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import { setCookie,getCookie } from '@/lib/utils';
export default {
  name: 'UpcomingRace',
  components: { PuntModal },
  data() {
     
    return {
      date: null,
      ShowUpcomingRacepopup : false,
      remainingTime: {
        hours: '00',
        days: '00',
        
      },

    };
  },
  mounted(){
    this.startTimer();
    
    setTimeout(() => {
        this.ShowUpcomingRacepopup = !(getCookie('hide_race_popup') == 1)
    }, 2000);
  },
  methods:
  {
    closeModal()
    {
        setCookie('hide_race_popup',1,  import.meta.env.VITE_RACE_POPUP_EXPIRY);
        this.ShowUpcomingRacepopup = false;
    },
    learnMore()
    {
        setCookie('hide_race_popup',1, import.meta.env.VITE_RACE_POPUP_EXPIRY);
        this.ShowUpcomingRacepopup = false;
        this.$router.push({ path: import.meta.env.VITE_NEXT_RACE_LINK});
    },
    startTimer() {
        // Define two dates
      
        const nextClaimTime = new Date(import.meta.env.VITE_NEXT_RACE_DATE_TIME);
         
      if (this.interval != null) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        const currentTime = new Date();
        let duration = nextClaimTime.getTime() - currentTime.getTime();
        if (duration <= 0 && this.interval) {
          clearInterval(this.interval);
          this.remainingTime = {
            hours: '00',
            days: '00',
          };
          this.interval = null;
        }

     
        let hours = parseInt(((duration / (1000 * 60 * 60)) % 24).toString());
        let days = parseInt(((duration / (1000 * 60 * 60 * 24)) % 24).toString());
        hours = Math.max(hours, 0);
        days = Math.max(days, 0);
        hours = hours < 10 ? '0' + hours : hours;
        days = days < 10 ? '0' + days : days;
       
        this.remainingTime = { hours, days };
      }, 1000);
    },
  }
}
</script>