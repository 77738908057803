<template>
    <div class="club-banefit-item">
        <div class="left-part">
            <img :src="'/images/ranks/'+item.img" alt="Item Image" v-if="item.img" >
        </div>
        <div class="right-part">
            <h3 class="item-title" v-if="item.title" >{{ item.title }}</h3>
            <p class="item-desc" v-if="item.desc" >{{ item.desc }}</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'VipClubBanefitItem',
  props : ['item'],
}
</script>

<style lang="scss" scoped>
    .club-banefit-item{
        @apply bg-corbeau;
        border-radius: 18px;
        padding: 23px 58px 23px 26px;
        display: flex;
        align-items: center;
        column-gap: 37px;
        .left-part{
            width: 100%;
            max-width: 103px;
            img{
                margin: auto;
            }
        }
        .item-title{
            font-size: 18px;
            line-height: 20px;
            font-weight: 600;
            margin-bottom: 11px;
        }
        .item-desc{
            font-size: 14px;
            line-height: 24px;
            font-weight: 600;
            @apply text-pango-black;
        }
    }
    @media screen and (max-width:992px){
        .club-banefit-item{
            flex-direction: column;
            padding: 23px 26px 35px;
            align-items:flex-start;
            .left-part{
              margin-bottom: 25px;
            }
          }
    }
</style>