<template>

    <div class="jackpot-counter bg-[rgba(0,0,0,0)] lg:bg-[rgba(0,0,0,0.50)] lg:p-1 absolute z text-xs lg:text-sm  z-30 font-bold " >
      <img class="w-6 h-auto  inline" :src="getIcon(currency)" /> 
        <span class="transition bg-[rgba(0,0,0,0.50)] lg:bg-[rgba(0,0,0,0)] p-1 pl-[10px] lg:p-0 jackpotnumber opacity-0 " :class="currentElement==0?'opacity-100 ':''" >  <span class="label">Minor</span> <span class=" lg:hidden">{{currency}}</span> {{   (parseFloat(startvalue[0])).toFixed(2).toLocaleString() }}    </span>
        <span class="transition bg-[rgba(0,0,0,0.50)] lg:bg-[rgba(0,0,0,0)] p-1  pl-[10px] lg:p-0 jackpotnumber opacity-0 " :class="currentElement==1?'opacity-100 ':''"> <span class="label">Major</span> <span class=" lg:hidden">{{currency}}</span> {{ (parseFloat(startvalue[1])).toFixed(2).toLocaleString() }} </span>
        <span class="transition bg-[rgba(0,0,0,0.50)] lg:bg-[rgba(0,0,0,0)] p-1  pl-[10px] lg:p-0 jackpotnumber opacity-0 " :class="currentElement==2?'opacity-100 ':''"> <span class="label">Mega</span> <span class=" lg:hidden">{{currency}}</span> {{ (parseFloat(startvalue[2])).toFixed(2).toLocaleString() }} </span>
        <span class="transition bg-[rgba(0,0,0,0.50)] lg:bg-[rgba(0,0,0,0)] p-1  pl-[10px] lg:p-0 jackpotnumber opacity-0 " :class="currentElement==3?'opacity-100 ':''" > <span class="label">Grand</span> <span class="lg:hidden">{{currency}}</span> {{ (parseFloat(startvalue[3])).toFixed(2).toLocaleString() }} </span>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';
import { getBalanceIcon } from '@/lib/icons';
export default {
  name: 'JackpotCounter',
  props: {
    currency: {
      default:'SC'
    },
    
    alltiers: 
    {
      default:0
    }
  },
  components: {
    
  },
  mounted()
  {
    this.init();
    var obj=this;
    setInterval(()=>{
        obj.currentElement = Math.floor(Math.random() * 4);
    
    },1500);

  },
  data() {
    return {
      minor: 0,
      major: 0,
      mega: 0,
      grand: 0,
      interval : null,
      currentElement: Math.floor(Math.random() * 4),
      startvalue : [
        0,0,0,0
      ],
    };
  },
  methods: {
      
    getIcon(currency) {
      return getBalanceIcon(currency);
    },

    init()
    {
      var obj = this;
      setTimeout(() => {
         obj.interval = setInterval(function(){
        
        
          obj.alltiers.forEach((v,i) => {  
            
            if( parseFloat(obj.startvalue[i]) < v.amount )
            {
          
              if(obj.startvalue[i] < (v.amount*0.90))
              {
                obj.startvalue[i] +=((v.amount*0.90)/300)
              }
              else if(obj.startvalue[i] < (v.amount*0.97))
              {
                obj.startvalue[i] +=((v.amount*0.07)/300)
              }
              else if(obj.startvalue[i] < (v.amount*0.99))
              {
                obj.startvalue[i] +=((v.amount*0.02)/300)
              }
              else{
                obj.startvalue[i] +=((v.amount*0.01)/300)
              }
                
              if(obj.startvalue[i] > v.amount)
                  obj.startvalue[i] = 0;
          
            }

          });
            
          

          }, 100);
      }, 1000);

     
      
    }
  },
};
</script>
<style>
.jackpot-counter 
{

  bottom:5px;
  left:10px;
  width:calc(100% - 20px);
  border-radius: 20px;
}
 
.jackpot-counter  *
{
  vertical-align: middle;
}
.jackpot-counter .jackpotnumber
{
  position:absolute;
  left:36px;
  top:50%;
  transform:translateY(-50%);
}
@media (max-width:1024px)
{
  .jackpot-counter img 
  {
    display:none;
  }
  .jackpot-counter .jackpotnumber
  {
    left:0;
    border-radius: 20px;
    width:100%;
  }
  
 
  .jackpot-counter .jackpotnumber span.label{
    display:block;
  }
  .jackpot-counter
  {
    bottom:30px;
  }
}
</style>
 
 