<template>
    <div :class="'ranking-card '+item.style">
        <div class="ranking-badge">
            <img :src="'/images/ranks/'+item.style+'.webp'" alt="Bandge Image" v-if="item.style" class="ranking-badge-image">
            <h3 class="ranking-badge-title"  v-if="item.title">{{item.title}}</h3>
        </div>
        <div class="ranking-content">
            <h4 class="ranking-price" v-if="item.GamePlayAmount">{{item.GamePlayAmount}}</h4>
            <p class="ranking-price-text" v-if="item.GamePlayAmountTitle">{{item.GamePlayAmountTitle}}</p>
            <ul class="lists">
                <li class="list" v-for="list in item.lists">{{ list }}</li>
            </ul>

        </div>
    </div>
</template>

<script>
export default {
  name: 'VipRankingCard', 
  props : ["item"]
}
</script>

<style lang="scss" scoped>
    .ranking-card{
        padding: 16px 24px 16px 18px;
        @apply bg-light-blue;
        border-radius: 32px;
        min-height: 421px;
        position: relative;
        overflow: hidden;
        &::before{
            content:'';
            width: 150px;
            height: 150px;
            position: absolute;
            top: -100px;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0.5;
            filter: blur(95px);
            pointer-events: none;
        }
        .ranking-badge{
            display: flex;
            align-items: center;
            column-gap: 11px;
            margin-bottom: 23px;
            .ranking-badge-title{
                font-size: 22px;
                line-height: 25px;
                letter-spacing: 0.0145455em;
            }
            .ranking-badge-image{
                -webkit-box-reflect:unset;
                max-width: 41px
            }
        }
        .ranking-content{
            padding-left: 6px;
            .ranking-price{
                font-size: 28px;
                line-height: 32px;
                font-weight: 700;
            }
            .ranking-price-text{
                font-size: 14px;
                line-height: 21px;
                font-weight: 600;
                @apply text-pango-black;
                margin-bottom: 16px;
            }
            .lists{
                padding-left: 25px;
            }
            .list{
                font-size: 14px;
                line-height: 24px;
                font-weight: 600;
                position: relative;
                &::before{
                    content: '';
                    width: 17px;
                    height: 17px;
                    background-size: contain;
                    background-position: center;
                    display: inline-block;
                    position: absolute;
                    top: 3px;
                    right: calc(100% + 8px);
                }
            }
            
        }
    }
    .ranking-card.copper{
        &::before{
            @apply bg-light-orange;
        }
        .ranking-content .list::before{
            background-image: url('/images/ranks/bronze-list.webp');
        }
    }
    .ranking-card.bronze{
        &::before{
            @apply bg-light-orange;
        }
        .ranking-content .list::before{
            background-image: url('/images/ranks/bronze-list.webp');
        }
    }
    .ranking-card.silver{
        &::before{
            @apply bg-paternoster
        }
        .ranking-content .list::before{
            background-image: url('/images/ranks/silver-list.webp');
        }
    }
    .ranking-card.gold{
        &::before{
            @apply bg-yellow;
        }
        .ranking-content .list::before{
            background-image: url('/images/ranks/gold-list.webp');
        }
    }
    .ranking-card.platinum{
        &::before{
            @apply bg-sky;
        }
        .ranking-content .list::before{
            background-image: url('/images/ranks/platinum-list.webp');
        }
    }
    .ranking-card.diamond{
        &::before{
            @apply bg-orange-daylily;
        }
        .ranking-content .list::before{
            background-image: url('/images/ranks/diamond-list.webp');
        }
    }
    @media screen and (max-width:992px){
        .ranking-card .ranking-badge-image{
            width: fit-content;
        }
    }
</style>