import LaravelEcho from 'laravel-echo';
import Pusher from 'pusher-js';
import $store from '../store';
import swal from 'sweetalert2';
import { getCurrentInstance } from 'vue';
import { defaultConvertor as rsgp } from '../lib/rsgp';
import { balanceStore } from '@/store/pinia/balanceStore';
import { ordersStore } from '@/store/pinia/ordersStore';
import orderSound from '@/assets/audio/order_status.mp3';
import { duelStore } from '@/store/pinia/duelStore';
import Router from '@/router';
import { useToast } from 'vue-toast-notification';
 

const orderCompletedSound = new Audio(orderSound);
orderCompletedSound.load();

const Vue = getCurrentInstance();
 

 
const $toast = useToast();
window.toast = $toast;
if (import.meta.env.VITE_ECHO_DRIVER === 'socket.io') {
  window.io = import('socket.io-client');
}
let balStore = null;
let orderS = null;
let duels = null;
window.Pusher = Pusher;

function buildEcho() {
  const echo = new LaravelEcho({
    ...getEchoDefaults(),
    ...getDriverOptions(),
  });

  setTimeout(() => bindListeners(echo), 1000);
  bindConnectionHandlers(echo);
  setTimeout(() => {
    balStore = balanceStore();
    orderS = ordersStore();
    duels = duelStore();
  }, 5000);
  return echo;
}

function bindConnectionHandlers(echo) {
  function dispatchChatConnected() {
    setTimeout(() => $store.dispatch('chatConnect', null), 0);
  }

  function dispatchChatDisconnected() {
    setTimeout(() => $store.dispatch('chatDisconnect', null), 0);
  }

  if (import.meta.env.VITE_ECHO_DRIVER === 'socket.io') {
    echo.connector.socket.on('connect', dispatchChatConnected);
    echo.connector.socket.on('disconnect', dispatchChatDisconnected);
  } else if (import.meta.env.VITE_ECHO_DRIVER === 'pusher') {
    echo.connector.pusher.connection.bind('connected', dispatchChatConnected);
    echo.connector.pusher.connection.bind('disconnected', dispatchChatDisconnected);
  }
}

function bindPrivateEcho(echo) {
  echo
    .private('App.User.' + $store.getters.me.id)
    .listen('UserProfileUpdated', (profile) => {
      $store.commit('update-user', profile);
    })
    /*.listen("PokerBalanceUpdated", ({ balance }) => {
      $store.dispatch("updatePokerBalance", balance);
    })*/
    .listen('BalanceWasUpdated', (x) => {
      const { balance, currency, triggered, delay } = x;

      if (delay > 0) {
        setTimeout(function () {
          if (balStore) {
            balStore.updateBalance(currency, balance, triggered);
          }
          $store.dispatch('updateBalance', balance);
        }, delay);
      } else {
        if (balStore) {
          balStore.updateBalance(currency, balance, triggered);
        }
        $store.dispatch('updateBalance', balance);
      }
    })
    .listen('UserBanned', () => {
      $store.dispatch('logout');
    })
    .listen('UserKicked', () => {
      $store.dispatch('logout');
    })
    .listen('OrderCreated', (order) => {
      $store.dispatch('showIntercom', `Hi I have an order: #${order.id}`);
      $store.commit('Orders/orders/RESET');
      $store.commit('Orders/orders/OPEN_ORDER', order);
      // $store.dispatch('Orders/cancelOrder', order.id)
    })
    .listen('OrderCompleted', (order) => {
      $store.dispatch('hideIntercom');
      $store.commit('Orders/orders/RESET');
      $store.commit('Orders/orders/COMPLETE_ORDER', order);

      swal(
        `Order Completed!`,
        `A total of ${order.amount.toLocaleString()} was traded.`,
        'success',
      ).then(swal.noop, swal.noop);
    })
    .listen('OrderCancelled', (order) => {
      $store.dispatch('hideIntercom');
      $store.commit('Orders/orders/CANCEL_ORDER', order);

      swal('Order Cancelled', `Your ${order.type} order was cancelled.`, 'error');
    })
    .listen('OrderUpdated', (order) => {
      $store.commit('Orders/orders/UPDATE_ORDER', order);
    })
    .listen('UserNotificationEvent', ({ message, success }) => {
      if (success) {
        window.toast.success(message);
      } else {
        window.error(message);
      }
    })
    .listen('EmailVerified', () => {
      $store.dispatch('fetchUser');
      window
        .swal({
          title: 'Thanks!',
          text: 'Your email was successfully verified!',
          type: 'success',
          timer: 3e3,
        })
        .then(
          () => {},
          () => {},
        );
    })
    .listen('ChatRoomStarted', ({ room }) => {
      const { joiner, owner } = room;
      const roomId = room.id;
      const myId = $store.getters.me.id;
      $store.dispatch('fetchScrollback', roomId);

      let other = [joiner, owner].find((x) => x.id !== myId);
      $store.dispatch('createRoom', {
        id: roomId,
        name: 'PM: ' + other.username,
      });
      if (room.owner_id === myId) {
        $store.dispatch('changeRoom', roomId);
      } else if (
        'Notification' in window &&
        window.Notification.permission === 'granted'
      ) {
        let n = new window.Notification('Private Message Started', {
          icon: 'https://gambleh.com/icons/256x256.png',
          body: other.username + ' has opened a private message room with you.',
        });
        n.onclick = function () {
          $store.dispatch('changeRoom', roomId);
          n.close();
        };
      }

      echo
        .join(`room.${roomId}`)
        .joining((user) => {
          $store.dispatch('joinChat', { roomId, user });
        })
        .leaving((user) => {
          $store.dispatch('leaveChat', { roomId, user });
        })
        .here((users) => {
          $store.dispatch('roomJoined', { roomId, users });
        })
        .listen('ChatMessagePublished', ({ message }) => {})
        .listen('MessageHidden', ({ roomId, messageId }) => {
          $store.deleteMessage({ roomId, messageId });
        });
    })
    .listen('WarInviteViaChatEvent', (data) => {
      const game = data.game;
      swal({
        title: 'War Invite',
        text: `User ${game.host.username} has invited you to a ${rsgp.format(
          game.wager,
          1,
          2,
        )} ${game.game_mode.toUpperCase()} war.`,
        confirmButtonText: 'Accept',
        timer: 20e3,
        type: 'info',
        showCancelButton: true,
      }).then(
        () => {
          window.location.href = `${
            window.location.origin
          }/war?invite=${encodeURIComponent(window.btoa(game.id))}`;
        },
        () => {},
      );
    })
    .listen('CryptoOrderCompleted', ({ order }) => {
      //show toast notification
      const message = `Your purchase of ${order.amount} ${order.currency} has been confirmed!`;
      //play sound
      try {
        orderCompletedSound.play();
        if (orderS) {
          orderS.onOrdersUpdated();
        }
        window.toast.success(message);
      } catch (e) {}
    })
    .listen('CryptoOrderCreated', ({ order }) => {
      //show toast notification
      const message = `Your purchase of ${order.amount} ${order.currency} has been received and is awaiting confirmation.`;
      //play sound
      try {
        orderCompletedSound.play();
        if (orderS) {
          orderS.onOrdersUpdated();
        }
        window.toast.success(message);
      } catch (e) {}
    })
    .listen('NotifyUser', ({ message, success, userData }) => {
      if (success) {
        if (userData?.verification_id) {
          $store.dispatch('updateUserVeriffVerification', userData.verification_id);
        }
        window.toast.success(message);
      } else {
        window.toast.error(message);
      }
    })
    .listen('BioAuthResult', ({ message, success, data }) => {
     
      Router.push('/');
      if (success) {
        window.swal({
          icon: 'success',
          title: 'Success',
          text: message,
          type: 'success',
        });
        if(data.redeem_successfully !== undefined && data.redeem_successfully)
        {
          // tracking redemption event on GTM
          if(window.gtm !== undefined &&  import.meta.env.VITE_NODE_ENV == 'production')
          { 
            window.gtm.trackEvent({
              event: 'redemption',
              value:  data.amount,
              email: $store.getters.me.email,
            });
          }
        }
      } else {
        let confirmButtonText =
          data.status === 'resubmission_requested' ? 'Click here to Resubmit' : 'OK';

        window
          .swal({
            icon: 'error',
            title: 'Failed',
            text: message,
            confirmButtonText: confirmButtonText,
            type: 'error',
          })
          .then((result) => {
            if (result.isConfirmed) {
              if (data.url) {
                window.open(data.url, '_blank');
              }
            }
          });
      }
    });
}

function bindListeners(echo) {
  echo.channel('announcements').listen('WebsiteUpdated', (e) => {
    window
      .swal({
        text: 'RuneBet was just updated!',
        type: 'info',
        showCancelButton: true,
        confirmButtonText: `
          <span class="icon">
            <i class="fa fa-refresh"></i>
          </span>
          Refresh Page
        `,
      })
      .then(
        () => window.location.reload(true),
        () => {},
      );
  });

  echo
    .channel('duels')
    .listen('DuelCreated', ({ duel }) => duels.add(duel))
    .listen('DuelCompleted', ({ duel }) => duels.onResult(duel))
    .listen('DuelCanceled', ({ duel }) => duels.remove(duel))
    .listen('JoinableDuelCountChanged', ({ count, game }) => {
      if ($store.state.user.game_mode === game) {
        $store.commit('setJoinableDuelCount', count);
      }
    });

  window.Echo.channel('races').listen('RacesUpdated', ({ races }) => {
    $store.commit('setRacesCount', races.length);
  });

  if (!$store.getters.me.id) {
    if ($store.getters.isLoggedIn) {
      const privateInterval = setInterval(() => {
        if ($store.getters.me.id) {
          bindPrivateEcho(echo);
          clearInterval(privateInterval);
        }
      }, 200);
    }
  } else {
    bindPrivateEcho(echo);
  }

  echo.channel('stats').listen('TotalWageredUpdated', ({ wagered }) => {
    $store.commit('update-total-wagered', wagered);
  });

  echo.channel('house-bets').listen('DiceRolled', ({ roll, user }) => {
    $store.commit('dicing/APPEND_ROWS', {
      table: 'all',
      rolls: [{ ...roll, user }],
    });
  });

  echo.channel('default').listen('ExchangeRatesChanged', ({ rates }) => {});
}

export const pubsub = Vue;

export function rebuildEcho() {
  if (window.Echo) {
    leaveChannels();
    echoDisconnect();
  }
  window.Echo = buildEcho();
  //pubsub.$emit('rebuilt')
}

window.rebuildEcho = rebuildEcho;

export function getEcho() {
  return window.Echo;
}

window.Echo = buildEcho()
export default getEcho();

export function leaveChannels() {
  let chans = [];
  const echo = getEcho();
  if (echo) {
    chans = Object.keys(echo.connector.channels);
    chans.forEach((chan) => echo.leave(chan));
  }
}

export function echoDisconnect() {
  const echo = getEcho();
  if (echo) {
    if (import.meta.env.VITE_ECHO_DRIVER === 'socket.io') {
      echo.connector.socket.disconnect();
    } else if (import.meta.env.VITE_ECHO_DRIVER === 'pusher') {
      echo.disconnect();
    }
  }
}

export function echoConnect() {
  if (import.meta.env.VITE_ECHO_DRIVER === 'socket.io') {
    getEcho().connector.socket.connect();
  }
}

function getDriverOptions() {
  return import.meta.env.VITE_ECHO_DRIVER === 'socket.io'
    ? getSocketIoOptions()
    : getPusherOptions();
}

function getSocketIoOptions() {
  return {
    host: import.meta.env.VITE_ECHO_HOST,
    encrypted: false,
  };
}

function getPusherOptions() {
  return {
    encrypted: true,
  };
}

function getEchoDefaults() {
  return {
    broadcaster: import.meta.env.VITE_ECHO_DRIVER,
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    auth: {
      headers: {
        Authorization: 'Bearer ' + window.localStorage['dd-auth'],
      },
    },
    wsHost: import.meta.env.VITE_ECHO_HOST,
    wsPort: import.meta.env.VITE_ECHO_WS_PORT,
    wssPort: import.meta.env.VITE_ECHO_WSS_PORT,
    forceTLS: false,
    encrypted: false,
    pongTimeout: 6e3,
    authEndpoint: import.meta.env.VITE_API_BASE_URL + 'broadcasting/auth',
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
  };
}
