<template>
  <div
    class="w-full loading-icon-body bg-tertiary px-4 py-12 md:px-8 md:py-12 justify-center text-center">
    <img
      src="../../assets/logo.png"
      alt="Chanced"
      class="w-250 m-auto mb-2 mt-10 rotate"
      :style="{ height: props.height }" />
    <p class="big-text text-white">Loading ...</p>
  </div>
</template>

<script setup>
const props = defineProps({
  height: {
    type: String,
    default: '20px',
  },
});
</script>

<style scoped>
/* if we need to add rotating image */
/* .rotate {
  animation: rotation 5s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
} */
</style>
