<template>
  <punt-modal
    size="max-w-[660px] p-6 my-3 max-[575px]:px-3"
    class="ssn-verification-model">
    <template #body>
      <div class="text-center mb-4">
        <img
          src="https://static.veriff.com/r/end-user-web/4.43.0/static/images/themes/defaultnext/logo.svg"
          alt=""
          class="mx-auto mb-5" />
        <div class="my-5 md:p-5 bg-[#fafafc] rounded-[10px] mb-0 pb-[5px]">
          <div class="max-w-[320px] mx-auto">
            <h4 class="font-bold text-lg">Please enter your SSN number</h4>
            <p class="text-[#505366] text-sm">
              Your SSN is used to verify your identity only.
            </p>
          </div>

          <form @submit.stop.prevent="() => validateSSN()" class="ssn-address-form">
            <div class="mb-4 mt-4">
              <div class="max-w-[320px] mx-[auto] my-[0]">
                <input
                  v-model.trim="ssn_number"
                  type="text"
                  v-mask="['###-##-####']"
                  maxlength="11"
                  id="ssn_number"
                  name="ssn_number"
                  placeholder="Enter 9 digit SSN number"
                  :class="{ 'has-error': v$.ssn_number.$error }"
                  class="mt-1 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300" />
                <span v-if="v$.ssn_number.$error" class="text-red-500 text-xs">{{
                  v$.ssn_number.$errors[0].$message
                }}</span>
                <p class="text-[#505366] text-sm mt-5 mb-2 font-bold">
                  Please enter your address
                </p>
              </div>

              <div class="max-w-[480px] mx-[auto] my-[0] max-[767px]:max-w-[320px]">
                <div class="">
                  <input
                    v-model.trim="address_line1"
                    type="text"
                    id="address_line1"
                    name="address_line1"
                    placeholder="Address Line 1"
                    :class="{ 'has-error': v$.address_line1.$error }"
                    class="mt-1 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300" />
                </div>

                <div class="grid grid-cols-1 md:grid-cols-10 md:gap-3 mt-2">
                  <input
                    v-model.trim="city"
                    type="text"
                    id="city"
                    name="city"
                    placeholder="City"
                    :class="{ 'has-error': v$.city.$error }"
                    class="md:mt-1 col-span-3 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300" />

                  <select
                    v-model="state"
                    :class="{ 'has-error': v$.state.$error }"
                    class="mt-2 md:mt-1 col-span-4 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300"
                    id="state"
                    name="state">
                    <option value="">State</option>
                    <option
                      v-for="(item, i) in statelist"
                      v-bind:key="i"
                      v-bind:value="i">
                      {{ item }}
                    </option>
                  </select>

                  <input
                    v-model.trim="zip"
                    type="text"
                    maxlength="5"
                    id="zip"
                    name="zip"
                    placeholder="5 Digit Zip"
                    :class="{ 'has-error': v$.zip.$error }"
                    class="mt-2 md:mt-1 col-span-3 w-full rounded-md border-gray-300 bg-white px-4 py-2 text-gray-900 focus:border-blue-300 focus:ring focus:ring-blue-300" />
                </div>

                <div class="max-w-[320px] mx-[auto] mt-[22px]">
                  <button
                    type="submit"
                    class="rounded-md bg-blue-500 px-6 py-2 text-white hover:bg-blue-600 disabled:opacity-50 w-full"
                    :disabled="this.veriffying">
                    <span v-if="this.veriffying" class="animate-spin">Loading...</span>
                    <span v-else>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="text-center mb-3 max-w-[450px] mx-auto">
        <p class="text-[#505366] mb-3">
          We will never use your SSN for any purpose other than account verification.
          <a
            class="text-[#03282c] inline-flex"
            target="_blank"
            title="know more about ssn verification"
            href="https://www.veriff.com/product/ssn-verification"
            ><strong class="underline">veriff.com</strong> &nbsp;
            <svg
              class="mt-1.5"
              xmlns="http://www.w3.org/2000/svg"
              height="14"
              width="14"
              viewBox="0 0 512 512">
              <path
                d="M432 320H400a16 16 0 0 0 -16 16V448H64V128H208a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16H48A48 48 0 0 0 0 112V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48V336A16 16 0 0 0 432 320zM488 0h-128c-21.4 0-32.1 25.9-17 41l35.7 35.7L135 320.4a24 24 0 0 0 0 34L157.7 377a24 24 0 0 0 34 0L435.3 133.3 471 169c15 15 41 4.5 41-17V24A24 24 0 0 0 488 0z" />
            </svg>
          </a>
        </p>
        <!-- <p class="text-[#505366]">Lorem ipsum dolor sit amet <a href="#" class="text-[#000000]">consectetur.</a> </p> -->
      </div>
    </template>
  </punt-modal>
</template>

<script>
import { computed } from '@vue/reactivity';
import { mapActions, mapGetters } from 'vuex';
import { aptPay } from '@/store/pinia/aptPay';
import PuntModal from '../Shared/PuntModal.vue';

import { mask } from 'vue-the-mask';
import { getUsStatesWithCode } from '@/lib/state';
import { maxLength, required, helpers, minLength, numeric } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

const validateMaskedInput = (value) => {
  return value.replace(/-/g, '').length === 9;
};

export default {
  name: 'SsnVerification',
  components: {
    PuntModal,
  },
  directives: { mask },
  props: {
    onSuccess: {
      default: null,
    },
  },
  setup() {
    const aptP = aptPay();
    return {
      aptP,
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      veriffying: false,
      statelist: [],
      ssn_number: '',
      address_line1: '',
      city: '',
      state: '',
      zip: '',
    };
  },
  validations() {
    return {
      ssn_number: {
        required: helpers.withMessage('SSN number is required', required),
        validateMaskedInput: helpers.withMessage(
          'SSN number must be 9 digit',
          validateMaskedInput,
        ),
      },
      address_line1: { required, maxLength: maxLength(100) },
      city: { required, maxLength: maxLength(100) },
      state: { required },
      zip: {
        required,
        maxLength: maxLength(5),
        minLength: minLength(5),
        numeric: numeric,
      },
    };
  },
  mounted() {
    this.getStateByCountry();
  },
  created() {
    window.toast = this.$toast;
  },
  computed: {},
  methods: {
    async validateSSN() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.veriffying = true;
        const data = await this.aptP.ssnverify({
          ssn_number: this.ssn_number,
          address_line1: this.address_line1,
          city: this.city,
          state: this.state,
          zip: this.zip,
        });
        if ( data.status == 200 && data.data.error == false && data.data.success == true) {
          if (this.onSuccess) this.onSuccess(data);
        } else if(typeof data.data.error != 'undefined' ){
          this.$toast.error(data.data.error);
        }
        this.veriffying = false;
      }
    },
    getStateByCountry() {
      this.statelist = getUsStatesWithCode();
    },
  },
};
</script>
