<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-col md:flex-row p-4 md:p-8 gap-4 w-full max-w-[1200px]">
      <div class="md:w-[200px] flex flex-col">
        <div
          class="space-y-0 py-2 bg-secondary h-auto rounded-lg flex md:block md:space-y-2">
          <span
            class="font-medium w-full mx-2"
            v-for="(item, itemIndex) in tabs"
            :key="itemIndex">
            <router-link
              :to="'/settings/' + item.link"
              :active-class="'text-slate-400 border-b-4 max-[575px]:border-b-2 md:border-b-0 md:border-l-4 border-[#F02665]'"
              class="inline-block p-4 max-[575px]:p-2 w-full font-semibold text-white text-left md:text-left max-[575px]:text-sm">
              {{ item.name }}
            </router-link>
          </span>
        </div>
      </div>
      <div class="bg-secondary p-3 flex-1 rounded-lg">
        <General v-if="activeTab === 'general'" />
        <Security v-if="activeTab === 'security'" />
        <!--          <Preferences v-if="activeTab === 'preferences'" />-->
        <Offers v-if="activeTab === 'offers'" />
        <!--          <Verify v-if="activeTab === 'verify'" />-->
        <!-- <CloseAccount v-if="activeTab === 'close-account'" /> -->
        <ResponsibleGaming v-if="activeTab === 'responsible-gaming'" />
        <RecognizeSigns v-if="activeTab === 'recognize-signs'" />
        <SelfAssessment v-if="activeTab === 'self-assessment'" />
      </div>
    </div>
  </div>
</template>

<script>
import General from '@/components/Settings/General.vue';
import Security from '@/components/Settings/Security.vue';
import Preferences from '@/components/Settings/Preferences.vue';
import Verify from '@/components/Settings/Verify.vue';

import Offers from '@/components/Settings/Offers.vue';
//import CloseAccount from "@/components/Settings/CloseAccount.vue";
import ResponsibleGaming from '../ResponsibleGaming/ResponsibleGaming.vue';
import RecognizeSigns from '../RecognizeSigns/RecognizeSigns.vue';
import SelfAssessment from '../SelfAssessment/SelfAssessment.vue';

export default {
  name: 'Settings',
  components: {
    SelfAssessment,
    RecognizeSigns,
    General,
    Security,
    Preferences,
    Verify,
    Offers,
    ResponsibleGaming,
  },
  data() {
    return {
      activeTab: 'general',
      tabs: [
        { name: 'General', link: 'general' },
        // { name: "Security", link: "security" },
        /*{ name: "Preferences", link: "preferences" },*/
        /* { name: "Verify", link: "verify" },*/
        { name: 'Offers', link: 'offers' },
        // { name: "Close Account", link: "close-account" },
        { name: 'Responsible Social Gameplay', link: 'responsible-gaming' },
        { name: 'Recognize the Signs', link: 'recognize-signs' },
        { name: 'Self Assessment', link: 'self-assessment' },
      ],
    };
  },

  watch: {
    '$route.params.type': {
      handler: function (newState) {
        this.activeTab = newState;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setCapital(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>

<style>
.dropdown nav {
  display: none;
}

.dropdown:focus-within nav {
  display: block;
}
</style>
