<template>
  <div
    id="cash"
    class="flex flex-col playforcash-section section justify-center bg-no-repeat bg-cover min-[991px]:min-h-[628px] relative">
    <div class="container">
      <div class="z-40 relative max-w-[784px] w-full mx-auto">
        <div
          class="w-full content-wrapper medium big max-w-[475px] ml-auto max-[992px]:m-auto">
          <h2 class="title">
            No purchase necessary. Claim your <span>free</span> coins today!
          </h2>
          <button
            v-on:click="$router.push({ path: $route.path, query: { tab: 'register' } })"
            class="btn-start">
            Play now
          </button>
        </div>
      </div>
    </div>
    <div class="bg-1">
      <img src="/images/home/img1.png" class="coinimage1 coinimages" alt="Coin Image" />
      <img
        src="/images/home/img1.png"
        class="coinimage2 coinrotate coinimages"
        alt="Coin Image" />
      <img
        src="/images/home/img1.png"
        class="coinimage3 reversecoinrotate coinimages"
        alt="Coin Image" />
      <img src="/images/home/img1.png" class="coinimage4 coinimages" alt="Coin Image" />
      <img src="/images/home/img1.png" class="coinimage5 coinimages" alt="Coin Image" />
      <img
        src="/images/home/img1.png"
        class="coinimage6 coinrotate coinimages"
        alt="Coin Image" />
      <img
        src="/images/home/img1.png"
        class="coinimage7 reversecoinrotate coinimages"
        alt="Coin Image" />
      <img
        src="/images/home/img1.png"
        class="coinimage8 reversecoinrotate coinimages"
        alt="Coin Image" />
      <img src="/images/home/img4.png" class="coinimage9 coinimages" alt="Coin Image" />
      <img src="/images/home/img4.png" class="coinimage10 coinimages" alt="Coin Image" />
      <img src="/images/home/img3.png" class="coinimage11 coinimages" alt="Coin Image" />
      <img src="/images/home/img3.png" class="coinimage12 coinimages" alt="Coin Image" />
      <img src="/images/home/img4.png" class="coinimage13 coinimages" alt="Coin Image" />
      <img
        src="/images/home/img1.png"
        class="coinimage14 coinrotate coinimages"
        alt="Coin Image" />
      <img
        src="/images/home/img1.png"
        class="coinimage15 coinrotate coinimages"
        alt="Coin Image" />
      <img
        src="/images/home/img7.png"
        class="coinimage16 reversecoinrotate coinimages"
        alt="Coin Image" />
    </div>
    <div class="bg-2">
      <img src="/images/home/price-s-2.webp" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayForCash',
};
</script>
