<template>
    <div class="club-banefit">
        <SectionTitle title="Chanced VIP Club Benefits" />
        <div class="club-banefit-items">
            <ClubBanefitItem v-for="item in ClubBanefitData" :item="item" />
        </div>
    </div>
</template>

<script>
import SectionTitle from './SectionTitle.vue';
import ClubBanefitItem from './ClubBanefitItem.vue'
import { ClubBanefitData } from './ClubBanefit'
export default {
  name: 'VipClubBanefit',
  components: {
    SectionTitle,
    ClubBanefitItem
  },
  data(){
    return{
        ClubBanefitData
    }
  }
}
</script>

<style lang="scss" scoped>
  .club-banefit{
    margin-bottom: 40px;
    .club-banefit-items{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 19px;
    }
  }
  @media screen and (max-width:992px){
    .club-banefit{
      margin-bottom: 30px;
        .section-title{
          text-align: center;
          margin-bottom: 27px;
        }
        .club-banefit-items{
          grid-template-columns:1fr;
          grid-gap:10px
        }
    }
  }
</style>