<template>
  <div
    id="realPeople"
    class="flex flex-col real-people-section section min-[993px]:flex-row min-h-[720px] overflow-hidden">
    <div class="container flex flex-col min-[993px]:flex-row">
      <div
        class="relative flex flex-col min-[993px]:w-7/12 items-start justify-center left-col">
        <div class="z-40 w-full content-wrapper medium big max-w-[475px] mobile">
          <h2 class="title">Real people, real <span>wins</span></h2>
          <h4 class="small-title-bg">Fun should always be free</h4>
        </div>
        <div class="grid mobile-g items-center w-full h-full real_people_slider">
          <div class="swiper realpeople-slider-swiper">
            <div class="swiper-wrapper realpeople-slider">
              <div class="swiper-slide">
                <div class="img-wrapper">
                  <img src="/images/home/wildcashwin.gif" alt="slider image" />
                </div>
              </div>
              <div class="swiper-slide">
                <div class="img-wrapper">
                  <img src="/images/home/fedgif.gif" alt="slider image" />
                </div>
              </div>
              <div class="swiper-slide">
                <div class="img-wrapper">
                  <img src="/images/home/slotqueengif.gif" alt="slider image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="relative flex flex-col min-[993px]:w-5/12 items-end max-w-[40%] justify-center right-col">
        <div class="z-40 w-full content-wrapper medium big max-w-[475px]">
          <h2 class="title desktop">Real people,<br />real <span>wins</span></h2>
          <h4 class="small-title-bg desktop">Fun should always be free</h4>
          <div class="navigation-button">
            <div class="arrow left realPeople-slider-prev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none">
                <path
                  d="M1 7L7 1M1 7L7 13M1 7H17"
                  stroke="#E9FF5F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
            <div class="arrow right realPeople-slider-next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none">
                <path
                  d="M17 7L11 1M17 7L11 13M17 7H1"
                  stroke="#E9FF5F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg">
      <img src="/images/home/real-people-bg.webp" alt="" />
    </div>
  </div>
</template>

<script>
import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/navigation';
import Swiper, { EffectCreative, Navigation, Autoplay } from 'swiper';
export default {
  name: 'RealPeople',
  components: {},
  data: () => ({
    frontendUrl: import.meta.env.VITE_FRONTEND_URL,
    swiper: null,
  }),
  mounted() {
    setTimeout(() => {
      if (window.innerWidth > 998) {
        this.swiper = new Swiper('.realpeople-slider-swiper', {
          modules: [EffectCreative, Navigation, Autoplay],
          effect: 'creative',
          loop: true,
          grabCursor: false,
          preventClicks: false,
          loopedSlides: 2,
          preventClicksPropagation: false,
          navigation: {
            enabled: true,
            nextEl: '.realPeople-slider-next',
            prevEl: '.realPeople-slider-prev',
          },
          allowTouchMove: false,
          slidesPerView: 1,
          creativeEffect: {
            prev: {
              translate: ['-90%', '50%', -200],
            },
            next: {
              translate: ['90%', '-50%', -200],
            },
          },
          autoplay: {
            delay: 2700,
          },
          speed: 1500,
        });
      } else {
        this.swiper = new Swiper('.realpeople-slider-swiper', {
          modules: [Navigation, Autoplay],
          grabCursor: false,
          spaceBetween: 20,
          preventClicks: false,
          preventClicksPropagation: false,
          loopedSlides: 2,
          loop: true,
          navigation: {
            enabled: true,
            nextEl: '.realPeople-slider-next',
            prevEl: '.realPeople-slider-prev',
          },
          slidesPerView: 1.2,
          autoplay: {
            delay: 2700,
          },
          speed: 1500,
        });
      }

      let swiperContainer = document.querySelector(`div.realpeople-slider-swiper`);

      swiperContainer.addEventListener('mouseenter', function () {
        this.swiper.autoplay.stop();
      });

      swiperContainer.addEventListener('mouseleave', function () {
        this.swiper.autoplay.start();
      });
    }, 500);
  },
};
</script>
