import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';

export const raceStore = defineStore('race', {
  state: () => {
    return {
      selected: {
        name: null,
        icon: null,
      },
      featured: null,
    };
  },
  getters: {},
  actions: {
    async fetchAll(params) {
      return await axios.get('races', { params });
    },
    async fetchLeaderboard(raceId) {
      return await axios.get(`races/leaderboard/${raceId}`)
    },
    async fetchUserLeaderboardProgress(userId){
      return await axios.get(`/races/leaderboard/user/${userId}`)
    }
  },
});
