import Home from '@/components/Home/Home.vue';
import LoginOrRegister from '@/components/Auth/LoginOrRegister.vue';
import Bank from '@/components/Bank/Bank.vue';
import WeeklyRaffleModal from '@/components/Promotions/WeeklyRaffleModal.vue';
import About from '@/views/About.vue';
import NotFound from '@/views/NotFound.vue';
import Settings from '@/components/Settings/Settings.vue';
import store from './store';

import Races from '@/components/Races.vue';

import Mines from '@/components/Mines/Mines.vue';
import MinesRules from '@/components/Mines/MinesRules.vue';
import MinesFairness from '@/components/Mines/MinesFairness.vue';
import MinesGameInfo from '@/components/Mines/MinesVerification.vue';

import Keno from '@/components/Keno/Keno.vue';
import KenoRules from '@/components/Keno/KenoRules.vue';
import KenoFairness from '@/components/Keno/KenoFairness.vue';
import KenoVerification from '@/components/Keno/KenoVerification.vue';

/*import War from "@/components/War/War.vue";
import WarRules from "@/components/War/WarRules.vue";
import WarVerification from "@/components/War/WarVerification.vue";*/

import Duels from '@/components/Duels/Duels.vue';
import DuelsRules from '@/components/Duels/DuelsRules.vue';
import DuelsVerification from '@/components/Duels/DuelsVerification.vue';

import Dice from '@/components/Dice/Dicing.vue';
import DiceFairness from '@/components/Dice/ProvablyFairCard.vue';
import DiceBetInfo from '@/components/Dice/BetInfo.vue';
import DiceRules from '@/components/Dice/DiceRules.vue';
import Jackpotari from '@/components/Jackpotari.vue';

import Slots from '@/components/Slots/Slots.vue';
import SlotsRules from '@/components/Slots/SlotsRules.vue';
import SlotsFairness from '@/components/Slots/SlotsFairness.vue';
import SlotsGameInfo from '@/components/Slots/SlotsGameInfo.vue';

import Softswiss from '@/components/Softswiss/Softswiss.vue';
import SoftswissTiles from '@/components/Softswiss/SoftswissTiles.vue';
import SoftswissBetInfo from '@/components/Softswiss/SoftswissBetInfo.vue';

import Pragmatic from '@/components/Pragmatic/Pragmatic.vue';
import PragmaticTiles from '@/components/Pragmatic/PragmaticTiles.vue';


import Evolution from '@/components/Evolution/Evolution.vue';


import Challenges from '@/components/Challenges/Challenges.vue';


import oaks from '@/components/oaks/oaks.vue';

import Relax from '@/components/Relax/Relax.vue';
import RelaxTiles from '@/components/Relax/RelaxTiles.vue';
import RelaxBetInfo from '@/components/Relax/RelaxBetInfo.vue';

import Roulette from '@/components/Roulette/Roulette.vue';
import RouletteRules from '@/components/Roulette/RouletteRules.vue';
import RouletteFairness from '@/components/Roulette/ProvablyFairCard.vue';
import RouletteGameInfo from '@/components/Roulette/RouletteGameInfo.vue';

import Plinko from '@/components/Plinko/Plinko.vue';
import PlinkoRules from '@/components/Plinko/PlinkoRules.vue';
import PlinkoFairness from '@/components/Plinko/PlinkoFairness.vue';
import PlinkoVerification from '@/components/Plinko/PlinkoVerification.vue';

import Limbo from '@/components/Limbo/Limbo.vue';
import LimboRules from '@/components/Limbo/LimboRules.vue';
import LimboFairness from '@/components/Limbo/LimboFairness.vue';
import LimboVerification from '@/components/Limbo/LimboVerification.vue';
import LimboGameInfo from '@/components/Limbo/LimboGameInfo.vue';

import Baccarat from '@/components/Baccarat/Baccarat.vue';
import BaccaratRules from '@/components/Baccarat/BaccaratRules.vue';
import BaccaratFairness from '@/components/Baccarat/BaccaratFairness.vue';
import BaccaratVerification from '@/components/Baccarat/BaccaratVerification.vue';

import Blackjack from '@/components/Blackjack/Blackjack.vue';
import BlackjackRules from '@/components/Blackjack/RulesModal.vue';
import BlackjackFairness from '@/components/Blackjack/FairnessModal.vue';
import BlackjackGameInfo from '@/components/Blackjack/BlackjackGameInfo.vue';

/*import Crash from "@/components/Crash/Crash.vue";
import CrashGameInfo from "@/components/Crash/CrashGameInfo.vue";
import CrashBetInfo from "@/components/Crash/CrashBetInfo.vue";
import CrashRules from "@/components/Crash/CrashRules.vue";
import CrashGames from "@/components/Crash/CrashGames.vue";*/
import MyTransactions from './components/Transactions/MyTransactions.vue';
import MyGamePlay from './components/GameHistory/MyGamePlay.vue';
import Details from './components/Transactions/Details.vue';

import Licenses from './components/Licenses/Licenses.vue';

import Policies from '@/components/Policies/Policies.vue';

import JackpotContributors from '@/components/FreeBies/JackpotContributors.vue';
import UserStats from '@/components/Stats/UserStats.vue';

import Overview from '@/components/Affiliates/Overview.vue';
import Funds from '@/components/Affiliates/Funds.vue';
import ReferedUsers from '@/components/Affiliates/ReferedUsers.vue';
import MyAffiliates from '@/components/Affiliates/MyAffiliates.vue';
import PasswordReset from '@/components/Auth/PasswordReset.vue';
import ForgotPassword from '@/components/Auth/ForgotPassword.vue';
import ProvablyFair from './components/ProvablyFair/ProvablyFair.vue';
import OverviewP from '@/components/ProvablyFair/Overview.vue';
import Implementation from '@/components/ProvablyFair/Implementation.vue';
import Conversions from '@/components/ProvablyFair/Conversions.vue';
import GameEvents from '@/components/ProvablyFair/GameEvents.vue';
import PrivacyPolicy from '@/components/SitePolicies/PrivacyPolicy.vue';
import SweepRules from '@/components/SitePolicies/SweepRules.vue';
import Giveaway from '@/components/Bonus/Giveaway.vue';
import Giveaways from '@/components/Giveaways/Giveaways.vue';
import GiveawayDetails from '@/components/Giveaways/GiveawayDetails.vue';
import GiveawayDrop from '@/components/Bonus/GiveawayDrop.vue';
import Fishy from '@/components/Bonus/Fishy.vue';
import Feddy from '@/components/Bonus/Feddy.vue';
import VIP from './components/Vip/VipNew.vue'
import TestComponent from '@/components/Bank/TestComponent.vue';

import ChallengesModal from '@/components/Challenges/ChallengesModal.vue';

import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router';
import SearchMobile from './components/Search/SearchMobile.vue';
import MobileNotificationCenter from './components/Notifications/MobileNotificationCenter.vue';

import BetsHistory from './components/GameHistory/BetsHistory.vue';
/*import Sports from "./components/Sports/Sports.vue";
import SportsBetInfo from "@/components/Sports/SportsBetInfo.vue";
import SportsBetPlacedInfo from "@/components/Sports/SportsBetPlacedInfo.vue";*/

/*import TvBet from "./components/TvBet/TvBet.vue";
import TvBetInfo from "@/components/TvBet/TvBetInfo.vue";
import TvBetPlacedInfo from "@/components/TvBet/TvBetPlacedInfo.vue";*/

//custom affiliate links
import Bob from './components/Affiliates/Bob.vue';
// import Fishy from "./components/Affiliates/Fishy.vue";
import Fedmyster from './components/Affiliates/Fedmyster.vue';
import Custom from './components/Affiliates/Custom.vue';
import Lossback from './components/Lossback.vue';

import ResponsibleGaming from "./components/ResponsibleGaming/ResponsibleGaming.vue";
import HallOfFame from './components/HallOfFame.vue';
import NewReferralBonus from './components/Affiliates/NewReferralBonus.vue';
import PromoRace from "@/components/Promotions/PromoRace.vue";


const getCustomRoutes = (aid, variations) => {
  return variations.map((variation) => {
    return {
      path: '/' + variation,
      component: Custom,
      name: variation,
      meta: { title: variation },
      props: { aid },
    };
  });
};

/** @type {import('vue-router').RouterOptions['routes']} */
const routes = [
  ...getCustomRoutes(5249, ['Axiun', 'axiun', 'AXIUN']),
  ...getCustomRoutes(30321, ['jpb', 'JPB']),
  { path: '/', component: Home, name: 'Home', meta: { title: 'Home' } },

  { path: '/bobqinxd', component: Bob, name: 'Bob', meta: { title: 'Bob' } },

  // { path: "/fishy", component: Fishy, name: "fishy", meta: { title: "fishy" } },
  {
    path: '/fedmyster',
    component: Fedmyster,
    name: 'fedmyster',
    meta: { title: 'Fedmyster' },
  },
  { path: '/fed', component: Fedmyster, name: 'fed', meta: { title: 'fed' } },
  { path: '/Fed', component: Fedmyster, name: 'Fed', meta: { title: 'fed' } },
  { path: '/FED', component: Fedmyster, name: 'FED', meta: { title: 'fed' } },
  { path: '/giveaway', component: Feddy, name: 'fed', meta: { title: 'fed' } },

  // { path: "/Fishy", component: Fishy, name: "Fishy", meta: { title: "Fishy" } },
  {
    path: '/Fedmyster',
    component: Fedmyster,
    name: 'Fedmyster',
    meta: { title: 'Fedmyster' },
  },
  { path: '/FED', component: Fedmyster, meta: { title: 'Fed' } },

  {
    path: '/auth',
    component: LoginOrRegister,
    name: 'LoginOrRegister',
    meta: {},
  },
  {
    path: '/c/:code',
    component: LoginOrRegister,
    name: 'LoginOrRegister',
    meta: { tab: 'register' },
  },
  {
    path: '/weekly-bonus/:code',
    component: Lossback,
    name: 'Lossback',
    meta: {
      auth: true,
    },
  },

  /*{
    path: "/live",
    component: TvBet,
    name: "TvBet",
    meta: { title: "TvBet" },
    children: [
      {
        path: "history/show/:betId",
        component: TvBetInfo,
        props: true,
      },
      {
        path: "history/show/placed/:betId",
        component: TvBetPlacedInfo,
        props: true,
      },
    ],
  },*/
  {
    path: '/password/reset/:token',
    component: PasswordReset,
    name: 'PasswordReset',
    meta: {},
    props: (route) => ({ resetToken: +route.params.token }),
  },

  { path: '/licenses', component: Licenses, name: 'Licenses', meta: {} },

  {
    path: '/password/forgot',
    component: ForgotPassword,
    name: 'ForgotPassword',
    meta: {},
  },

  { path: '/auth/:type', component: LoginOrRegister, name: 'Authy', meta: {} },
  { path: '/bank', component: Bank, name: 'Bank', meta: {} },
  { path: '/promotions', component: WeeklyRaffleModal, name: 'WeeklyRaffleModal', meta: {} },
  {
    path: '/bank/test/ach',
    component: TestComponent,
    name: 'TestComponentACH',
    props: (route) => ({ type: 'ACH' }),
    meta: {},
  },
  {
    path: '/bank/test/card',
    component: TestComponent,
    name: 'TestComponentCARD',
    props: (route) => ({ type: 'CARD' }),
    meta: {},
  },
  // {path: '/settings/verify', component: Verify, name: 'Verify', meta: {}},

  { path: '/settings/:type', component: Settings, name: 'Settings', meta: { auth: true } },
  { path: '/privacy-policy', component: PrivacyPolicy, name: 'PrivacyPolicy', meta: {} },
  { path: '/sweep-rules', component: SweepRules, name: 'SweepRules', meta: {} },
  { path: '/bonus', component: Giveaway, name: 'Bonus', meta: {} },
  { path: '/drop', component: GiveawayDrop, name: 'Drop', meta: {} },
  { path: '/fishy', component: Fishy, name: 'Fishy', meta: {} },
  { path: '/jackpotari', component: Jackpotari, name: 'Jackpotari', meta: {} },

  /*{
    path: '/casino/mines',
    component: Mines,
    name: 'Mines',
    meta: {},
    children: [
      {
        name: 'MinesRules',
        path: 'rules',
        component: MinesRules,
        meta: { auth: true },
      },
      {
        name: 'MinesFairness',
        path: 'fairness',
        component: MinesFairness,
        meta: { auth: true },
      },

      {
        name: 'MinesGameInfo',
        path: '/casino/mines/history/:gameId',
        component: MinesGameInfo,
        props: (route) => ({ gameId: +route.params.gameId, onClose: 'back' }),
        meta: {
          auth: true,
        },
      },
    ],
  },*/
  /*{
    path: '/casino/keno',
    component: Keno,
    name: 'Keno',
    meta: {},
    children: [
      {
        name: 'KenoRules',
        path: 'rules',
        component: KenoRules,
        meta: { auth: true },
      },
      {
        name: 'KenoFairness',
        path: 'fairness',
        component: KenoFairness,
        meta: { auth: true },
      },
      {
        name: 'KenoGameInfo',
        path: '/casino/keno/history/:gameId',
        component: KenoVerification,
        props: (route) => ({ gameId: +route.params.gameId, onClose: 'back' }),
        meta: {
          auth: true,
        },
      },
    ],
  },*/
  /*{
        path: '/casino/war', component: War, name: 'War', meta: {},
        children: [
            {name: 'WarRules', path: 'rules', component: WarRules, meta: {auth: true}},
            {
                name: 'WarGameInfo',
                path: '/casino/war/history/:gameId',
                component: WarVerification,
                props: route => ({gameId: +route.params.gameId, onClose: 'back'}),
                meta: {
                    auth: true
                }
            },
        ]
    },*/
  /*{
        path: '/casino/duels', component: Duels, name: 'Duels', meta: {},
        children: [
            {name: 'DuelsRules', path: 'rules', component: DuelsRules, meta: {auth: true}},
            {
                name: 'DuelsGameInfo',
                path: '/casino/duels/history/:gameId',
                component: DuelsVerification,
                props: route => ({gameId: +route.params.gameId, onClose: 'back'}),
                meta: {
                    auth: true
                }
            },
        ]
    },*/
  /*{
    path: "/casino/crash",
    component: Crash,
    name: "Crash",
    meta: {},
    children: [
      {
        name: "CrashRules",
        path: "rules",
        component: CrashRules,
        meta: { auth: true },
      },
      {
        name: "CrashGames",
        path: "games",
        component: CrashGames,
        meta: { auth: true },
      },
      {
        path: "/casino/crash/history/:gameId",
        component: CrashBetInfo,
        props: (route) => ({ gameId: +route.params.gameId, onClose: "back" }),
        meta: { auth: true },
      },
      {
        name: "CrashGameInfo",
        path: "/history/crash-games/:gameId",
        component: CrashGameInfo,
        props: (route) => ({ gameId: +route.params.gameId, onClose: "back" }),
        meta: { auth: true },
      },
    ],
  },*/

  /*{
    path: '/casino/dice',
    component: Dice,
    name: 'Dice',
    meta: {},
    children: [
      {
        name: 'DiceRules',
        path: 'rules',
        component: DiceRules,
        meta: { auth: true },
      },
      {
        name: 'DiceFairness',
        path: 'fairness',
        component: DiceFairness,
        meta: { auth: true },
      },
      {
        name: 'HouseBetInfo',
        path: '/casino/dice/history/:gameId',
        component: DiceBetInfo,
        meta: { auth: true },
      },
    ],
  },
  */
  /*{
        path: '/casino/slots', component: Slots, name: 'Slots', meta: {},
        children: [
            {name: 'SlotsRules', path: 'rules', component: SlotsRules, meta: {auth: true}},
            {name: 'SlotsFairness', path: 'fairness', component: SlotsFairness, meta: {auth: true}},
            {
                name: 'SlotsGameInfo',
                path: '/casino/slots/history/:gameId',
                component: SlotsGameInfo,
                props: route => ({gameId: +route.params.gameId, onClose: 'back'}),
                meta: {
                    auth: true
                }
            },
        ]
    },

  {
    path: '/casino/roulette',
    component: Roulette,
    name: 'Roulette',
    meta: {},
    children: [
      {
        name: 'RouletteRules',
        path: 'rules',
        component: RouletteRules,
        meta: { auth: true },
      },
      {
        name: 'RouletteFairness',
        path: 'fairness',
        component: RouletteFairness,
        meta: { auth: true },
      },
      {
        name: 'RouletteGameInfo',
        path: '/casino/roulette/history/:gameId',
        component: RouletteGameInfo,
        meta: { auth: true },
      },
    ],
  },*/
  /*{
    path: '/casino/plinko',
    component: Plinko,
    name: 'Plinko',
    meta: {},
    children: [
      {
        name: 'PlinkoRules',
        path: 'rules',
        component: PlinkoRules,
        meta: { auth: true },
      },
      {
        name: 'PlinkoFairness',
        path: 'fairness',
        component: PlinkoFairness,
        meta: { auth: true },
      },
      {
        name: 'PlinkoGameInfo',
        path: '/casino/plinko/history/:gameId',
        component: PlinkoVerification,
        props: (route) => ({ gameId: +route.params.gameId, onClose: 'back' }),
        meta: {
          auth: true,
        },
      },
    ],
  },*/
  /*
  {
    path: '/casino/limbo',
    component: Limbo,
    name: 'Limbo',
    meta: {},
    children: [
      {
        name: 'LimboRules',
        path: 'rules',
        component: LimboRules,
        meta: { auth: true },
      },
      {
        name: 'LimboFairness',
        path: 'fairness',
        component: LimboFairness,
        meta: { auth: true },
      },
      {
        name: 'LimboGameInfo',
        path: '/casino/limbo/history/:gameId',
        props: (route) => ({ gameId: +route.params.gameId, onClose: 'back' }),
        component: LimboVerification,
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/casino/baccarat',
    component: Baccarat,
    name: 'Baccarat',
    meta: {},
    children: [
      {
        name: 'BaccaratRules',
        path: 'rules',
        component: BaccaratRules,
        meta: { auth: true },
      },
      {
        name: 'BaccaratFairness',
        path: 'fairness',
        component: BaccaratFairness,
        meta: { auth: true },
      },
      {
        name: 'BaccaratVerification',
        path: '/casino/baccarat/history/:gameId',
        component: BaccaratVerification,
        props: (route) => ({ gameId: +route.params.gameId, onClose: 'back' }),
        meta: {
          auth: true,
        },
      },
    ],
  },*/
  /*{
    path: '/casino/blackjack',
    component: Blackjack,
    name: 'Blackjack',
    meta: {},
    children: [
      {
        name: 'BlackjackRules',
        path: 'rules',
        component: BlackjackRules,
        meta: { auth: true },
      },
      {
        name: 'BlackjackFairness',
        path: 'fairness',
        component: BlackjackFairness,
        meta: { auth: true },
      },
      {
        name: 'BlackjackGameInfo',
        path: '/casino/blackjack/history/:gameId',
        component: BlackjackGameInfo,
        props: (route) => ({ gameId: +route.params.gameId, onClose: 'back' }),
        meta: {
          auth: true,
        },
      },
    ],
  },*/

  //mobile navigation
  {
    path: '/side-menu',
    component: () => import('@/components/Sidebar/LeftSideBar.vue'),
    name: 'LeftSideBar',
    meta: {},
  },

  {
    path: '/chat',
    component: () => import('@/components/Chat/ChatBar.vue'),
    name: 'ChatBar',
    meta: {},
  },

  {
    path: '/softswiss/casino/games/:name',
    component: Softswiss,
    name: 'Softswiss',
    meta: {},
    children: [
      {
        name: 'SoftswissBetInfo',
        path: '/softswiss/casino/history/:gameId',
        component: SoftswissBetInfo,
        props: (route) => ({ gameId: +route.params.gameId, onClose: 'back' }),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/softswiss/casino/group/:name',
    component: SoftswissTiles,
    name: 'SoftswissTiles',
    meta: {},
  },

  {
    path: '/casino/games/:name',
    component: Relax,
    name: 'Relax',
    meta: {},
    children: [
      {
        name: 'RelaxBetInfo',
        path: '/casino/history/:gameId',
        component: RelaxBetInfo,
        props: (route) => ({ gameId: +route.params.gameId, onClose: 'back' }),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/casino/group/:name',
    component: RelaxTiles,
    name: 'RelaxTiles',
    meta: {},
  },
  {
    path: '/promo-race/:id',
    component: PromoRace,
    name: 'WeeklyRaffle',
    meta: {},
  },
  {
    path: '/challenges/create',
    component: ChallengesModal,
    name: 'ChallengesModal',
    meta: {},
  },
  {
    path: '/challenges/:type',
    component: Challenges,
    name: 'Challenges',
    meta: {},
  },
  {
    path: '/giveaways/active',
    component: Giveaways,
    name: 'GiveawaysActive',
    meta: {},
    props: (route) => ({ type: 'active' }),
  },
  {
    path: '/giveaways/finished',
    component: Giveaways,
    name: 'GiveawaysFinished',
    meta: {},
    props: (route) => ({ type: 'finished' }),
  },
  {
    path: '/giveaways/:id',
    component: GiveawayDetails,
    name: 'GiveawayDetails',
    meta: {},
  },

  // {
  //   path: '/races',
  //   component: Races,
  //   name: 'Races',
  //   meta: {},
  // },

  {
    path: '/pragmatic/casino/group/:name',
    component: PragmaticTiles,
    name: 'PragmaticTiles',
    meta: {},
  },
  
  {
    path: '/pragmatic/casino/games/:name',
    component: Pragmatic,
    name: 'Pragmatic',
    meta: {},
    children: [],
  },
  {
    path: '/evolution/casino/games/:name',
    component: Evolution,
    name: 'Evolution',
    meta: {},
    children: [],
  },

  {
    path: '/3oaks/casino/games/:name',
    component: oaks,
    name: '3oaks',
    meta: {},
    children: [],
  },

  { path: '/policies/:name', component: Policies, name: 'Policies', meta: {} },

  { path: '/search', component: SearchMobile, name: 'Search', meta: {} },

  { path: '/notifications', component: MobileNotificationCenter, name: 'NotificationCenter', meta: {} },

  { path: '/bets', component: BetsHistory, name: 'BetHistory', meta: {} },

  {
    path: '/freebies/jackpotcontributors',
    component: JackpotContributors,
    name: 'JackpotContributors',
    meta: {},
  },

  {
    path: '/about',
    meta: { title: 'About' },
    component: About,
    // example of route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import('@/views/About.vue')
  },

  {
    path: '/about',
    meta: { title: 'About' },
    component: About,
    // example of route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import('./views/About.vue')
  },
  {
    path: '/user/statistics',
    component: UserStats,
    name: 'UserStats',
    meta: {},
  },
  {
    path: '/affiliates/:name',
    component: MyAffiliates,
    name: 'MyAffiliates',
    meta: {},
    children: [
      { name: 'Overview', path: 'overview', component: Overview, meta: {} },
      { name: 'Funds', path: 'funds', component: Funds, meta: {} },
      {
        name: 'ReferedUsers',
        path: 'referred-users',
        component: ReferedUsers,
        meta: {},
      },
      {
        name: 'NewReferralBonus',
        path: 'new-referral-bonus',
        component: NewReferralBonus,
        meta: {},
      },
    ],
  },

  {
    path: '/provablyfair/:name',
    component: ProvablyFair,
    name: 'ProvablyFair',
    meta: {},
    children: [
      { name: 'OverviewP', path: 'overview', component: OverviewP, meta: {} },
      {
        name: 'Implementation',
        path: 'implementation',
        component: Implementation,
        meta: {},
      },
      {
        name: 'Conversions',
        path: 'conversions',
        component: Conversions,
        meta: {},
      },
      {
        name: 'GameEvents',
        path: 'gameevents',
        component: GameEvents,
        meta: {},
      },
    ],
  },

  // Responsible Gaming
  {
    path: '/responsible-gaming',
    meta: { title: 'Responsible Gaming' },
    component: ResponsibleGaming,
  },

  { path: '/:path(.*)', component: NotFound },
  {
    path: '/transactions/:name',
    component: MyTransactions,
    name: 'MyTransactions',
    meta: {},
    children: [{ name: 'Details', path: 'details', component: Details, meta: {} }],
  },
  {
    path: '/my-game-play',
    component: MyGamePlay,
    name: 'MyGamePlay',
    meta: {},
    children: [{ name: 'Details', path: 'details', component: Details, meta: {} }],
  },
  { path: '/:path(.*)', component: NotFound },
  {
    path: '/vip',
    component: VIP,
    name: 'VIP',
    meta: {},
  },
  {
    path: '/hall-of-fame',
    component: HallOfFame,
    name: 'HallOfFame',
    meta: {},
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      window.scrollTo(0, 0);
    }
  },
});

router.beforeEach((to, from, next) => {
  if ((to.meta.auth || to.query.tab === 'bank') && !store.getters.isLoggedIn) {
    store.commit('set-redirect-route', to.fullPath);
    return next('/auth/login');
  }
  return next();
});

export default router;
