<template>
  <div class="flex flex-col justify-center items-center min-h-[100%]">
    <h1 class="text-3xl font-bold mb-8">Identity Verification</h1>
    <form
      @submit.prevent="submitForm"
      class="w-96 max-w-full bg-black bg-opacity-25 rounded-lg shadow-lg px-8 py-8">
      <div class="mb-4">
        <label for="first-name" class="block text-white font-medium">First Name</label>
        <input
          v-model="formData.first_name"
          pattern="[A-Za-z]+"
          title="Please enter a valid first name"
          type="text"
          id="first-name"
          name="first-name"
          required
          class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300" />
      </div>
      <div class="mb-4">
        <label for="last-name" class="block text-white font-medium">Last Name</label>
        <input
          v-model="formData.last_name"
          pattern="[A-Za-z]+"
          title="Please enter a valid last name"
          type="text"
          id="last-name"
          name="last-name"
          required
          class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300" />
      </div>
      <div class="mb-4">
        <label for="email" class="block text-white font-medium">Email</label>
        <input
          v-model="formData.email"
          type="email"
          id="email"
          name="email"
          required
          class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300" />
      </div>
      <div class="mb-4">
        <label for="dateOfBirth" class="block text-white font-medium">DOB</label>
        <input
          v-model="formData.dateOfBirth"
          pattern="\d{4}-\d{2}-\d{2}"
          title="Please enter a valid date in the format YYYY-MM-DD"
          type="date"
          id="dateOfBirth"
          name="dateOfBirth"
          placeholder="YYYY-MM-DD"
          required
          class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300" />
      </div>
      <div class="mb-4">
        <label for="city" class="block text-white font-medium">City</label>
        <input
          v-model="formData.city"
          type="text"
          id="city"
          name="city"
          required
          class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300" />
      </div>
      <div class="mb-4">
        <label for="zip" class="block text-white font-medium">ZIP</label>
        <input
          v-model="formData.zip"
          type="text"
          id="zip"
          name="zip"
          required
          minlength="5"
          maxlength="10"
          pattern="^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$"
          title="Please enter valid US  Zip Code"
          class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300" />
      </div>
      <div class="mb-4">
        <label for="address1" class="block text-white font-medium">Address</label>
        <input
          v-model="formData.address1"
          type="text"
          id="zip"
          name="address1"
          required
          minlength="5"
          title="Please enter address"
          class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300" />
      </div>
      <!-- <div class="mb-4">
          <label for="country" class="block text-white font-medium">Country</label>
          <input v-model="formData.country" type="text" id="country" name="country" required
            class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300">
        </div> -->

      <div>
        <label for="country" class="block text-white font-medium">Country</label>
        <select
          v-model="selectedOptionValue"
          required
          class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300">
          <option value="" disabled selected>Select a country</option>
          <option v-for="option in options" :value="option.iso" :key="option.code">
            {{ option.country }}
          </option>
        </select>
      </div>
      <div class="mb-4">
        <label for="phone" class="block text-white font-medium">Phone</label>
        <input
          v-model="formData.phone"
          required
          type="tel"
          id="phone"
          name="phone"
          title="Please enter phone number without any country code"
          minlength="10"
          maxlength="15"
          pattern="[0-9]+"
          class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300" />
      </div>
      <div class="flex justify-center mt-6">
        <button
          type="submit"
          class="px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md"
          :disabled="isLoading">
          <span v-if="isLoading" class="animate-spin">Loading...</span>
          <span v-else>Submit</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { aptPay } from '@/store/pinia/aptPay';
import { computed } from '@vue/reactivity';
import countriesJson from './countries.json';

export default {
  name: 'IdentityVerificationForm',
  setup() {
    const aptP = aptPay();

    const noPayForm = computed(() => {
      return !aptP.isAptPayVerified;
    });

    const isLoading = computed(() => {
      return aptP.isLoading;
    });
    const showQRCode = computed(() => {
      return aptP.hasAptPayVerifyDetails;
    });
    const qrCode = computed(() => {
      return aptP.identityForm.qrcode;
    });
    const qrCodeUrl = computed(() => {
      return aptP.identityForm.url;
    });
    return { aptP, qrCode, qrCodeUrl, showQRCode, noPayForm, isLoading };
  },
  data() {
    return {
      options: [],
      selectedOption: { key: null, value: null },
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        dateOfBirth: '',
        city: '',
        zip: '',
        country: '',
        phone: '',
        address1: '',
      },
      // qrCodeUrl: '',
      // showQRCode: false,
    };
  },
  computed: {
    selectedOptionValue: {
      get() {
        return this.selectedOption.value;
      },
      set(value) {
        // Update the selectedOption data property with both key and value
        const selectedOption = this.options.find((option) => option.iso === value);
        this.selectedOption = { key: selectedOption.code, value: value };
      },
    },
    showQRCode() {
      return this.aptP.hasAptPayVerifyDetails;
    },
    npPayForm() {
      return !this.aptP.isAptPayVerified;
    },
  },
  mounted() {
    this.options = countriesJson;
    // this.aptP.getlatestAptPayVerify();
  },
  methods: {
    async submitForm() {
      this.formData.country = this.selectedOption.value;
      // this.formData.phone='+'+this.selectedOption.key+this.formData.phone;
      let data = JSON.parse(JSON.stringify(this.formData));
      data.phone = '+' + this.selectedOption.key + this.formData.phone;

      try {
        let response = await this.aptP.identityCreation(data);

        if (typeof response.data.errors != 'undefined') {
          Object.keys(response.data.errors).forEach((key) => {
            if (Array.isArray(response.data.errors[key]))
              this.$toast.error(response.data.errors[key][0]);
            else this.$toast.error(response.data.errors[key]);
          });
        }

        this.aptP.isAptPayVerified = true;

        /*if(response.status=="AML check in progress"){
            this.$toast.success("AML check in progress");
          } 
           else if( typeof response.data.errors!= 'undefined' ){
              
            
            Object.keys(response.data.errors).forEach(key => {
              this.$toast.error(response.data.errors[key][0]);
            });
          }*/
      } catch (error) {
        console.error('Error in creating identity for user', error);
        this.$toast.warning('Error');
        if (typeof error.data.errors != 'undefined') {
          Object.keys(error.data.errors).forEach((key) => {
            this.$toast.error(error.data.errors[key][0]);
          });
        }
      }
      // Simulate API call to generate QR code
    },
  },
};
</script>
