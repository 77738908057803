<template>
  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 sm:px-6">
      <div class="px-2 py-2 text-center">
        <button
          v-on:click.prevent="toggleShowCampaign"
          class="inline-flex h-10 flex-row items-center justify-center gap-2 rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto md:px-5 md:py-3 bg-prim-highlight shadow-btn shadow-accent px-2">
          Create New Campaign
        </button>
      </div>
      <div class="lg:p-2" v-if="!loading">
        <div v-if="campaigns && campaigns.length > 0">
          <div
            v-for="(item, index) in campaigns"
            class="lg:p-5 mt-5 rounded-md bg-primary p-3">
            <div class="flex justify-between p-2 text-sm flex-wrap gap-2">
              <label for="">{{ item.name }}</label>
              <div class="flex flex-wrap gap-2">
                <span> Commissions: </span>
                <div class="flex flex-wrap gap-2">
                  <span class="inline-flex gap-2 flex-wrap items-center"
                    >{{ item.total_commission[0].commission }}
                    <img
                      :src="getIcon(item.total_commission[0].currency)"
                      alt="Currency Icon"
                      class="inline w-4 h-4"
                  /></span>
                  <span class="inline-flex gap-2 flex-wrap items-center"
                    >{{ item.total_commission[1].commission }}
                    <img :src="getIcon('GC')" alt="Currency Icon" class="inline w-4 h-4"
                  /></span>
                </div>
              </div>
            </div>
            <div
              class="grid grid-cols-1 min-[360px]:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 lg:gap-5 lg:pt-5 border-t border-gray-500 gap-2 pt-3">
              <div class="flex flex-col py-2 text-sm">
                <span>Campaign Hits</span>
                <span>0</span>
              </div>
              <div class="flex flex-col py-2 text-sm">
                <span>Referrals</span>
                <span>{{ item.referrals }}</span>
              </div>
              <div class="flex flex-col py-2 text-sm">
                <span>Total Purchases</span>
                <span>{{ item.total_purchase }}</span>
              </div>
              <div class="flex flex-col py-2 text-sm">
                <span>Unique Purchases</span>
                <span>0</span>
              </div>

              <div class="flex flex-col py-2 text-sm">
                <span>Commission Rate</span>
                <span>{{ item.commission_rate }}%</span>
              </div>
              <div class="flex flex-col py-2 text-sm">
                <span>Available Commission</span>
                <div v-for="(el, key) in item.available_commission">
                  <span class="flex gap-2 flex-wrap items-center">
                    {{ el.commission }}
                    <img
                      :src="getIcon(el.currency)"
                      alt="Currency Icon"
                      class="inline w-4 h-4" />
                  </span>
                </div>
              </div>
              <div class="flex flex-col py-2 text-sm">
                <span>Total Commission</span>
                <div v-for="(el, key) in item.total_commission">
                  <span class="flex gap-2 flex-wrap items-center">
                    {{ el.commission }}
                    <img
                      :src="getIcon(el.currency)"
                      alt="Currency Icon"
                      class="inline w-4 h-4" />
                  </span>
                </div>
              </div>
            </div>
            <div class="pb-4 text-sm mt-2 lg:max-w-[450px]">
              <div class="relative flex items-center">
                <input
                  readonly
                  type="text"
                  v-model="item.link"
                  class="bg-secondary placeholder:text-text-dim-color border border-secondary text-slate-300 text-sm rounded-xl font-semibold focus:ring-accent focus:border-accent block w-full p-2.5"
                  placeholder="Your text here" />
                <div
                  class="absolute inset-y-0 flex items-center right-[0] px-[12px] bg-secondary">
                  <button
                    :disabled="!me.id"
                    v-on:click="copyText(item.link)"
                    class="bg-secondary -mr-[1px]">
                    <c-clipboard class="w-6 h-6" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center">No data</div>
      </div>

      <div
        v-else
        class="flex flex-row items-center justify-center flex-grow h-full p-8 rounded-md min-h-40">
        <svg
          class="w-10 h-10 mr-3 -ml-1 text-white animate-spin"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"></circle>
          <path
            class="opacity-75"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            fill="currentColor"></path>
        </svg>
        Please wait ...
      </div>
    </div>
  </div>
  <punt-modal v-if="showCreateCampaign" show-close="true" :onClose="toggleShowCampaign">
    <template #title>
      <div class="w-full">
        <span class="flex items-center p-2"> Create New Campaign </span>
        <hr class="w-full h-px my-2 ml-4 border-0 dark:bg-gray-700" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-col px-4 py-2">
        <label class="block mt-4 mb-2 text-sm font-medium text-text-dim-color">
          Campaign Name</label
        >
        <input
          required
          type="text"
          id="campaign-name"
          class="text-sm font-semibold text-white rounded-md bg-secondary focus:border-0 focus:ring-accent"
          v-model="campaign.name" />
        <p v-if="campaignNameError" class="ml-0.5 mt-0.5 text-sm text-red-500 opacity-80">
          {{ campaignNameError }}
        </p>
        <!-- <div class="relative flex">
            <label class="block mt-4 mb-2 text-sm font-medium text-text-dim-color">
              Code (Optional)</label
            >
            <div class="flex items-center ml-3">
              <input
                @click="campaign.isManualCode = !campaign.isManualCode"
                id="code"
                name="codecheckbox"
                type="checkbox"
                :checked="campaign.isManualCode === true"
                class="w-4 h-4 pt-2 border-gray-300 rounded text-accent focus:ring-accent" />
            </div>
          </div>
          <input
            v-if="campaign.isManualCode === true"
            type="text"
            id="campaign-code"
            class="text-sm font-semibold text-white rounded-md bg-secondary focus:border-0 focus:ring-accent"
            v-model="campaign.code" />
          <p v-if="campaignCodeError" class="ml-0.5 mt-0.5 text-sm text-red-500 opacity-80">
            {{ campaignCodeError }}
          </p> -->
        <button
          v-on:click.prevent="createCampaign"
          class="bg-onprimary disabled:cursor-not-allowed disabled:bg-onprimary/60 disabled:hover:bg-onprimary/60 hover:opacity-80 hover:scale-105 disabled:hover:scale-100 py-2 px-2 text-[#111F29] mt-2 rounded-xl">
          Save
        </button>
      </div>
    </template>
  </punt-modal>
</template>

<script>
import { affiliatesStore } from '@/store/pinia/affiliatesStore';
import { mapGetters, mapActions } from 'vuex';
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';
import PuntModal from '@/components/Shared/PuntModal.vue';
import CClipboard from '../Shared/CopyToClipboard.vue';

export default {
  name: 'Campaign',
  components: { PuntModal, CClipboard },
  setup() {
    const balS = balanceStore();
    const affStore = affiliatesStore();
    return { affStore, balS };
  },
  data() {
    return {
      loading: true,
      campaigns: [],
      link: '',
      showCreateCampaign: false,
      campaign: {
        name: '',
        // isManualCode: false,
        // code: null,
      },
      campaignNameError: null,
      // campaignCodeError: null,
    };
  },

  mounted() {
    if (this.me.id) {
      this.fetchCampaigns();
    }
  },
  methods: {
    async fetchCampaigns() {
      this.loading = true;
      try {
        this.affStore.fetchReferralCodes(this.me.id).then((res) => {
          this.campaigns = res.data;
          this.loading = false;
        });
      } catch (e) {
        this.$toast.error('Cannot fetch claims! <br>' + ((typeof e.data.error !=="undefined")?e.data.error:''));
      }
      this.loading = false;
    },

    getIcon(currency) {
      return getBalanceIcon(currency);
    },

    validateCampaignForm() {
      if (this.campaign.name === '' || this.campaign.name === null) {
        this.campaignNameError = 'Name is required';
        return false;
      }
      // if (this.campaign.isManualCode === true) {
      //   if (this.campaign.code === '' || this.campaign.code === null) {
      //     this.campaignCodeError = 'Code is required';
      //     return false;
      //   }
      //   if (this.campaign.code.length < 3) {
      //     this.campaignCodeError = 'Atleast 3 characters are required';
      //     return false;
      //   }
      // }
      return true;
    },

    createCampaign() {
      if (!this.validateCampaignForm()) return;
      this.campaignNameError = null;
      this.campaign.user_id = this.me.id;
      try {
      this.affStore
        .storeCampaign(this.campaign)
        .then((res) => {
          if (res.status === 200) {
            this.$toast.success('Campaign added successfully');
            this.fetchCampaigns();
            this.toggleShowCampaign();
            this.campaign = {
              name: '',
              // isManualCode: false,
              // code: '',
            };
          }
        })
        .catch((err) => {
          this.toggleShowCampaign();
          this.$toast.error('Error Occurred');
        });
      } catch(error) {
        console.log("error while adding campaign");
      }
    },
    toggleShowCampaign() {
      this.showCreateCampaign = !this.showCreateCampaign;
    },
    async copyText(link) {
      try {
        await navigator.clipboard.writeText(link);
        this.$toast.success('Copied');
      } catch (err) {
        console.error('Unable to copy text: ', err);
      }
    },

    ...mapActions(['fetchReferralCodes']),
  },
  computed: {
    ...mapGetters(['me']),
  },
};
</script>
