import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const oaksStore = defineStore('oaks', {
  state: () => {
    return {
      selected: {
        name: null,
        icon: null,
      },
      featured: null,
    };
  },
  getters: {},
  actions: {
    async createGameSession(isMobile, gameId, isDemo) {
      const platform = isMobile ? 'mobile' : 'desktop';
      const url = 'games/3oaks/' + (isDemo ? 'demo' : 'session');
      const custom_ip =   (document.getElementById('custom_ip') != null)?document.getElementById('custom_ip').value:'0.0.0.0';

      return await axios.post(url, { platform, gameId, custom_ip });
    },
    async createSession(currency, amount, address, tag) {
      return await axios.post('games/3oaks/session', {
        currency,
        amount,
        address,
        tag,
      });
    },
    async fetchFeatured() {
      if (!this.featured) {
        const { data } = await axios.get('3oaks/featured');
        this.featured = data;
      }
      return this.featured;
    },
     
    async fetchGame(identifier) {
      return await axios.get('games/3oaks/game/' + identifier);
    },
    async toggleFavourite(data) {
      return await axios.post('favourite', data);
    },
    async checkFavourite(data) {
      return await axios.post('favourite/check', data);
    },
  },
});
