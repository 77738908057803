<template>
  
     
      
        <a :disabled="canNotVerify" target="_blank" :href="verifylink" class="btn-bet ch-button hover:opacity-75 disabled:opacity-50 min-w-[200px] text-black/90">
            {{buttonText}}
        </a>
       
  </template>
  
  <script>
  import {authStore} from "@/store/pinia/authStore";
  
  export default {
    name: "VerifyLink",
    setup(){
      return {authS : authStore()}
    },
    data(){
      return {loading: false, session : null,verifylink:null}
    },
    async created(){
      await this.fetchVerification();
      this.getVerificationLink();
    },
    computed:{
      buttonText(){
        if(this.loading) return "Please wait..."
  
        if(!this.session || ['expired', 'abandoned'].includes(this.session.status)){
          return "Get Verified"
        }
  
        if(this.session.status === 'resubmission_requested' ){
          return "Resubmit Details"
        }
  
        if(this.session.status === 'declined' ){
          return "Verification Failed Verify Again"
        }
  
  
        if(this.session && ['created', 'started'].includes(this.session.status)){
          return "Continue With Verification"
        }
  
  
        if(this.session &&  ['review','submitted'].includes(this.session.status)){
          return "Verification In Progress..."
        }
  
  
        if(this.session.status === 'approved' ){
          return "Verification Approved. Exit"
        }
  
        return "Get Verified"
  
      },
      canNotVerify(){
        return this.loading || ( (this.session && [  'approved', 'review','submitted'].includes(this.session.status)))
      }
    },
    methods:{
      async fetchVerification(){
        try {
          this.loading = true
          if(!this.session){
            const {data} = await this.authS.fetchVerify()
            if(data.session){
              this.session = data.session
            }
          }
        } catch (e) {
  
        } finally {
          this.loading = false
        }
      },
      async getVerificationLink(){
        try {
          this.loading = true
          const {data} = await this.authS.verify()
          this.session = data
          if(this.session.status === 'approved'){
            this.$router.replace({path : this.$route.path,  query : {tab : 'bank', action : 'redeem'}})
            window.location.reload();
          }else{
            this.verifylink = this.session.url
          }
        } catch (e) {
        } finally {
          this.loading = false
        }
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>