<template>
  <button
    v-on:click.prevent="debouncedOnClick"
    :disabled="isButtonDisabled"
    class="option-button rounded-lg md:rounded-xl bg-secondary-light aspect-1"
    :class="buttonClasses">
    <div
      v-if="(isManual && (selected || completed)) || (!isManual && completed)"
      :style="isMine && selected ? 'background-color:#FF4757 !important;' : ''"
      class="button-content-mines items-center flex flex-col justify-center">
      <!--       <img v-if="selected && isMine" src="../../assets/gifs/explode.gif" alt="" class="mines-explode">-->

      <img
        v-if="isMine"
        src="../../assets/svg/mines/grenade.svg"
        :class="completed && !selected ? 'mine-result' : 'mine-selected'"
        class="h-8 md:h-12 ! m-4" />

      <img
        v-else
        src="../../assets/svg/mines/diamond.svg"
        class="h-12 md:h-16"
        :class="completed && !selected ? 'mine-result ' : 'mine-selected'" />
    </div>
    <div v-else>
      <div v-if="!isButtonDisabled" class="button-content-mines">
        <span class="button-text-mines">{{ index }}</span>
      </div>
      <div v-else class="button-content-mines bg-secondary-light/70 !cursor-not-allowed">
        <span class="button-text-mines">{{ index }}</span>
      </div>
    </div>
  </button>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'MinesGridItem',
  props: {
    index: {},
    isMine: {},
    isManual: {},
    selected: {},
    completed: {},
    loading: {},
    onclick: {},
    currentState: {},
  },
  created() {
    this.debouncedOnClick = debounce(
      () => {
        if (!this.loading) this.onclick(this.index);
      },
      50,
      {
        leading: true,
        trailing: false,
      },
    );
  },
  computed: {
    isButtonDisabled() {
      if (this.isManual) {
        return this.currentState !== 'inProgress' || (this.selected && !this.loading);
      } else {
        return this.currentState !== 'notStarted';
      }
    },
    notStarted() {
      return this.currentState === 'notStarted';
    },

    buttonClasses() {
      if (this.loading) return 'selected-loading';

      if (this.isManual) {
        return `${
          this.selected && !this.completed ? 'selected' : 'unselected opacity-70'
        } ${this.completed ? 'result' : ''}`;
      } else {
        return `${
          this.selected && !this.completed ? 'selected-auto !shadow-none' : 'unselected'
        } ${this.completed ? 'result shadow-none' : ''}`;
      }
    },
  },
  data: () => ({
    debouncedOnClick: () => {},
  }),
};
</script>

<style>
.option-button {
  position: relative;
  color: rgb(255, 255, 255);
  transition-duration: 300ms;
  transition-property: background-color, box-shadow, opacity;
  outline: none;
  padding-top: 100%;
  box-shadow: #1f3741 0px 0.3em;
}

.option-button.result {
  background-color: rgb(7, 24, 36);
  border-color: rgb(7, 24, 36);
  animation-name: option_result;
  -moz-animation-name: option_result;
  -webkit-animation-duration: 100ms;
  animation-duration: 100ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

@keyframes option_result {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    transform: scale(1);
    background-color: rgb(7, 24, 36, 0.1);
    border-color: rgb(7, 24, 36, 0.2);
  }
}

.option-button.selected.result {
  color: rgb(255, 255, 255);
}

.option-button.result.selected .button-content-mines {
  background-color: rgb(0, 177, 0) !important;
}

.option-button.result:not(.selected) .button-content-mines {
  background-color: rgb(7, 24, 36, 0.2);
}

.option-button:not(:disabled) {
  cursor: pointer;
}

.option-button.selected-loading {
  transform: scale(1.025);
  animation-name: bounce_loading;
  -moz-animation-name: bounce_loading;
  -webkit-animation-duration: 700ms;
  animation-duration: 700ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.option-button:disabled,
.option-button[disabled] {
  opacity: 1;
  background-color: rgb(7, 24, 36, 0.2);
  box-shadow: transparent 0px 0px;
  cursor: not-allowed;
  pointer-events: none;
}

.button-content-mines {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  animation-fill-mode: forwards;
  transition-duration: 300ms;
  transition-property: background-color, box-s, transform;
  animation-name: ibUtsH;
  animation-duration: 300ms;
  animation-timing-function: cubic-bezier(0.87, -0.41, 0.19, 1.44);
  border-radius: 0.7em;
}

.mine-selected {
  opacity: 1;
  transform: scale(0.6);
  filter: brightness(1.05);
  animation-name: mine_selected;
  -moz-animation-name: mine_selected;
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

@keyframes mine_selected {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  /* 30% {
    opacity: 0;
    transform: scale(1);
  } */
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.mine-result {
  opacity: 1;
  transform: scale(0.4);
  animation-name: mine_result;
  -moz-animation-name: mine_result;
  -webkit-animation-duration: 100ms;
  animation-duration: 100ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
}

@keyframes mine_result {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  80% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 0.35;
    transform: scale(0.8);
  }
}

.option-button.selected-auto:not(.result):not(.selected) {
  border-color: #972eff;
  background-color: #972eff;
  box-shadow: #7a21d3 0 0.45em;
}

.option-button.unselected:hover {
  transform: scale(1.01);
  -webkit-animation-duration: 700ms;
  animation-duration: 700ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.option-button:hover:not(.result) .button-content {
  box-shadow: #193b4d 0px 0.45em;
  transform: translateY(-0.15em);
  opacity: 0.7;
}

.button-text-mines {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

@keyframes bounce_loading {
  0%,
  50%,
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  12.5%,
  37.5% {
    -webkit-transform: scale(0.97);
    -ms-transform: scale(0.97);
    transform: scale(0.97);
  }

  25% {
    -webkit-transform: scale(0.94);
    -ms-transform: scale(0.94);
    transform: scale(0.94);
  }

  75% {
    -webkit-transform: scale(1.06);
    -ms-transform: scale(1.06);
    transform: scale(1.06);
  }

  87.5%,
  62.5% {
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
  }
}

@keyframes button-animate {
  0% {
    opacity: 0;
    transform: scale(0);
  }
}

.mines-explode {
  animation: 1s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}
</style>
