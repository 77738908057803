<template>
  <div class="bg-[#0a0a23] p-4 rounded-lg">
    <span class="text-lg bold font-bold">Change Password</span>

    <div v-if="error" class="notification is-danger">
      {{ error }}
    </div>
    <form @submit.stop.prevent="() => updatePassword()">
      <div class="mt-3 flex flex-col gap-2">
        <label class="block text-sm font-medium text-gray-300" for="password-old"
          >Old Password<span class="text-red-800">*</span></label
        >
        <input
          id="password-old"
          v-model="form.current_password"
          class="input-bet"
          required
          type="password" />
      </div>
      <div class="mt-3 flex flex-col gap-2">
        <label class="block text-sm font-medium text-gray-300" for="password-new"
          >New Password<span class="text-red-800">*</span></label
        >
        <input
          id="password-new"
          v-model="form.password"
          class="input-bet"
          required
          type="password" />
      </div>
      <div class="mt-3 flex flex-col gap-2">
        <label class="block text-sm font-medium text-gray-300" for="password-new-confirm"
          >Confirm New Password<span class="text-red-800">*</span></label
        >
        <input
          id="password-new-confirm"
          v-model="form.password_confirmation"
          class="input-bet"
          required
          type="password" />
      </div>

      <button :disabled="locked" class="btn-bet" type="submit">Update</button>
    </form>
  </div>

  <div class="bg-[#0a0a23] p-4 mt-3 rounded-lg">
    <span class="text-lg bold font-bold">Privacy & Address</span>
    <div class="flex relative items-center mt-3">
      <div class="flex items-center">
        <SwitchGroup>
          <Switch
            v-model="isPrivateMode"
            :class="isPrivateMode ? 'bg-onprimary' : 'bg-secondary-light'"
            class="relative inline-flex items-center h-6 transition-colors rounded-full w-11">
            <span
              :class="isPrivateMode ? 'translate-x-6' : 'translate-x-1'"
              class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full" />
          </Switch>
          <SwitchLabel class="ml-4 text-sm font-semibold text-gray-300"
            >Enable Private Mode</SwitchLabel
          >
        </SwitchGroup>
      </div>
    </div>
  </div>

  <div v-if="!isPromoOptInDisabled" class="bg-[#0a0a23] p-4 mt-3 rounded-lg">
    <span class="text-lg bold font-bold">Promotions Preferences</span>
    <div class="flex relative items-center mt-3">
      <div class="flex items-center">
        <SwitchGroup>
          <Switch
            v-model="localOptInPromoSms"
            @click="updateOptInPromoSms"
            :class="localOptInPromoSms ? 'bg-onprimary' : 'bg-secondary-light'"
            class="relative inline-flex items-center h-6 transition-colors rounded-full w-11">
            <span
              :class="localOptInPromoSms ? 'translate-x-6' : 'translate-x-1'"
              class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full"/>
          </Switch>
          <SwitchLabel class="ml-4 text-sm font-semibold text-gray-300">Receive Bonuses by SMS</SwitchLabel>
        </SwitchGroup>
      </div>
    </div>
    <div class="flex relative items-center mt-3">
      <div class="flex items-center">
        <SwitchGroup>
          <Switch
            v-model="localOptInPromoEmail"
            @click="updateOptInPromoEmail"
            :class="localOptInPromoEmail ? 'bg-onprimary' : 'bg-secondary-light'"
            class="relative inline-flex items-center h-6 transition-colors rounded-full w-11">
            <span
              :class="localOptInPromoEmail ? 'translate-x-6' : 'translate-x-1'"
              class="inline-block w-4 h-4 transition-transform transform bg-white rounded-full"/>
          </Switch>
          <SwitchLabel class="ml-4 text-sm font-semibold text-gray-300">Receive Bonuses by Email</SwitchLabel>
        </SwitchGroup>
      </div>
    </div>
  </div>

  <div class="bg-[#0a0a23] p-4 mt-3 rounded-lg">
    <span class="text-lg bold font-bold">Username</span>
    <div class="mt-2"></div>
    <div class="mt-3 flex flex-col gap-2">
      <input id="username" class="input-bet" disabled="true" :value="me.username" />
    </div>
    <br />
    <span class="text-lg bold font-bold">Email<span class="text-red-800">*</span></span>
    <div class="mt-2"></div>
    <div class="mt-3 flex flex-col gap-2">
      <input
        id="email"
        v-model="partiallyStarredEmail"
        @input="updateNewEmail"
        class="input-bet"
        placeholder="mail@mail.com"
        required
        type="email" />
    </div>
    <div class="flex justify-start gap-4 max-[400px]:gap-0 max-[400px]:flex-wrap">
      <button class="btn-bet" v-on:click.prevent="updateEmail">Update</button>
      <button
        v-if="!me.email_verified"
        class="btn-bet"
        v-on:click.prevent="sendVerificationEmail">
        Resend Email
      </button>
    </div>
  </div>
</template>

<script>
import axios from '../../lib/axiosConfig';
import { mapActions, mapGetters } from 'vuex';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

export default {
  name: 'General',
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
  },
  data() {
    return {
      token: '',
      darkMode: false,
      initialized: false,
      url: null,
      text: null,
      is_private: false,
      isPrivateMode: false,
      localOptInPromoSms: false, // use values local to the component for optimistic UI pattern
      localOptInPromoEmail: false,
      isPromoOptInDisabled: true, // hardcoded until ready for release
      error: null,
      locked: false,
      email: '',
      partiallyStarredEmail: '',
      firstName: '',
      lastName: '',
      form: {
        current_password: '',
        password: '',
        password_confirmation: '',
      },
    };
  },
  created() {
    this.isPrivateMode = this.me.is_private;
    setTimeout(() => (this.initialized = true), 1000);
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'me', 'isUserOptInPromoSms', 'isUserOptInPromoEmail']),
    hasLoaded() {
      return 'email_verified' in this.me;
    },
    partiallyStarredEmail: {
      get() {
        if (this.email.length >= 6 && this.email === this.me.email) {
          const stars = '*'.repeat(this.email.length - 6);
          return this.email.substring(0, 2) + stars + this.email.slice(-4);
        } else {
          return this.email; // Email is too short to partially star
        }
      },
    },
  },
  methods: {
    ...mapActions(['fetchUser', 'updateUserOptInPromo']),
    async updatePassword() {
      const form = {
        ...this.form,
        fp: window.fp,
      };

      this.locked = true;
      try
      {
        await axios
        .post('change_password', form)
        .then(() => {
          this.resetForm();
          this.$toast.success(
            'Your password has been updated and other sessions have been logged out.',
          );

          setTimeout(() => {
            window.location.reload();
            this.$router.push({ path: '/' }).then(() => {
              this.$router.go();
            });
          }, 3000);
        })
        .catch((error) => {
          if((typeof error.data.error != 'undefined'))
          this.$toast.error('Ooops! cant update password! <br>' + error.data.error);
          if (error.data && error.data.hasOwnProperty('error'))
            this.error = error.data.error;
          else this.error = 'Please try again';
          this.resetForm();
        });
      } catch(error) {
        this.resetForm();
         
        this.$toast.error('Ooops! cant update password! <br> try again');
      }
    },
    resetForm() {
      this.locked = false;
      this.error = null;
      this.form = {
        current_password: '',
        password: '',
        password_confirmation: '',
      };
    },
    privacyChanged() {
      try {
        axios
        .patch('user/profile/privacy', { enabled: this.isPrivateMode })
        .then(({ data }) => {
          this.$toast.success('Privacy Updated');
          this.fetchUser();
        })
        .catch(({ data, status }) => {
          if(typeof data.error != 'undefined')
          this.$toast.error(data.error);
        });
      } catch(error) {
        console.log('error while fatchin privacy');
      }
    },
    async updateOptInPromoSms() {
      await this.$nextTick();
      await this.updateUserOptInPromo({ opt_in_promo_sms: this.localOptInPromoSms })
        .then(() => {
          this.$toast.success('SMS preferences updated');
        })
        .catch(() => {
          this.$toast.error('Settings could not be updated');
          this.localOptInPromoSms = this.isUserOptInPromoSms;
        });
    },
    async updateOptInPromoEmail() {
      await this.$nextTick();
      await this.updateUserOptInPromo({ opt_in_promo_email: this.localOptInPromoEmail })
        .then(() => {
          this.$toast.success('Email preferences updated');
        })
        .catch(() => {
          this.$toast.error('Settings could not be updated');
          this.localOptInPromoEmail = this.isUserOptInPromoEmail;
        });
    },
    updateEmail() {
      try {
      axios
        .post('update_email', { email: this.email })
        .then(
          () => window.swal('Nice!', 'Your email has been updated.', 'success'),
          ({ data }) =>  window.swal('Uh-oh', data.error, 'error'),
        )
        .then(() => this.fetchUser());
      } catch(error) {
        console.log('error while updating email');
      }
    },
    sendVerificationEmail() {
      try {
      axios
        .post('verify_email')
        .then(
          () =>
            window.swal(
              'Check your email!',
              'A verification email has been sent to your email on file. Click the link in the email to verify your account.',
              'info',
            ),
          ({ data, status }) => {
            if (status === 429) {
              window.swal(
                'Slow down!',
                'You are trying to verify your email too often. Try again in a few minutes.',
                'error',
              );
            } else {
              window.swal('Hmm...', (typeof data.error != 'undefined'?data.error:''), 'error');
            }
          },
        )
        .then(() => this.fetchUser());
      } catch(error) {
        console.log('error while sending verification');
      }
    },
    updateNewEmail(e) {
      this.email = e.target.value;
    },
  },
  mounted() {
    if (!this.email) {
      this.fetchUser();
    }
    if (document.body.classList) {
      this.darkMode = document.body.classList.contains('dark');
    }
    this.localOptInPromoSms = this.isUserOptInPromoSms;
    this.localOptInPromoEmail = this.isUserOptInPromoEmail;
  },
  watch: {
    me() {
      this.email = this.me.email || '';
      this.firstName = this.me.firstname || '';
      this.lastName = this.me.lastname || '';
      this.is_private = this.me.is_private || false;
    },
    isPrivateMode() {
      if (this.initialized) {
        this.privacyChanged();
      }
    },
    isUserOptInPromoSms(newValue) {
      this.localOptInPromoSms = newValue;
    },
    isUserOptInPromoEmail(newValue) {
      this.localOptInPromoEmail = newValue;
    },
  },
};
</script>
