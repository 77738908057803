import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';
import { timeParse } from 'd3-time-format';
import { random } from 'lodash';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const transactionStore = defineStore('transctions', {
  state: () => {
    return {
      selected: {
        name: null,
        icon: null,
      },
    };
  },
  getters: {},
  actions: {
    async deposit() {
      return await axios.get('/transactions/deposit');
    },
    async widthrawal() {
      return await axios.get('/transactions/widthrawal');
    },
    async fetchWeeklyRaffleProgress(payload){
      return await axios.get(`users/${payload}/weekly-raffle-progress`)
    },
    async fetchWeeklyRaffleTickets(payload){
      return await axios.get(`users/${payload}/weekly-raffle-tickets`)
    },
    async fetchWeeklyRaffleStatus(){
      return await axios.get(`weekly-raffle-status`)
    },
  },
});
