<template>
  <div class="py-2 md:py-4">
    <verify-account
      data-verifid="{me.verification_id}"
      context="claim your GC Top up"
      v-if="me.verification_id === null"
      :reverification="me.prev_verification_id" />
    <div v-else>
      <form @submit.stop.prevent="() => claim()">
        

        <div class="flex flex-col text-secondary-text/60 justify-center items-center" v-if="formatedGoldamount < 10">
          <span class="text-center">Out of coins? Have a free top up on us!</span>
        </div>
        <div class="flex flex-col text-secondary-text/60 justify-center items-center" v-else>
          <span class="text-center">If you have less than 10 GC in your wallet, you can claim a free top-up on us!</span>
        </div>
        <div class="flex flex-col text-secondary-text/60 justify-center items-center" v-if="canClaim">
          <span class="text-center">Next Top Up Available in:</span>
        </div>

        <div
          class="flex flex-row bg-tertsiary justify-center items-center font-bold text-3xl px-4 py-2 text-slate-300 rounded-2xl"   v-if="formatedGoldamount < 10">
          <div
            class="flex flex-col items-center bg-secondary rounded-3xl m-4 p-4 min-w-[90px] max-[400px]:m-2 max-[400px]:min-w-[80px]">
            {{ remainingTime.hours }}
            <span class="text-xs text-secondary-text mt-2">HOURS</span>
          </div>
          <div
            class="flex flex-col items-center bg-secondary rounded-3xl m-4 p-4 min-w-[90px] max-[400px]:m-2 max-[400px]:min-w-[80px]">
            {{ remainingTime.minutes }}
            <span class="text-xs text-secondary-text mt-2">MINUTES</span>
          </div>
          <div
            class="flex flex-col items-center bg-secondary rounded-3xl m-4 p-4 min-w-[90px] max-[400px]:m-2 max-[400px]:min-w-[80px]">
            {{ remainingTime.seconds }}
            <span class="text-xs text-secondary-text mt-2">SECONDS</span>
          </div>
        </div>
        <div class="flex flex-row gap-8 max-[480px]:gap-4"  v-if="formatedGoldamount < 10">
          <div class="flex-1">
            <div class="flex flex-row justify-between mt-2 text-xs items-center">
              <label
                class="block my-2 mx-0.5 text-sm font-medium text-text-dim-color"
                for="email-address">
                Gold Coins
              </label>
            </div>
            <div class="relative">
              <div
                class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <img class="h-6 w-6" :src="getBalanceIcon('GC')" />
              </div>
              <input
                id="email-address"
                value="1000"
                class="input-with-icon font-bold bg-secondary"
                placeholder="Enter Amount"
                required
                readonly
                disabled
                step="any"
                type="number" />
            </div>
        </div>          
        </div>
        <div class="flex flex-row justify-center mt-4">
          <button
            :disabled="!canClaim || isOnBreak"
            class="btn-bet hover:opacity-75 disabled:opacity-30 text-white rounded-2xl px-4 py-3 my-4 w-1/2 -align-center max-[480px]:w-auto">
            {{ loading ? 'Please wait...' : 'Claim Top Up' }}
          </button>
        </div>

        <div
          v-if="!me.email_verified"
          class="text-sm text-slate-300 border-slate-300 border-2 border-dashed mt-6 p-4 rounded-lg">
          <span> Please verify you email in order to proceed with your redemption.</span>
          <div class="text-text-dim-color hover:text-text-dim-color/70 font-bold">
            <span class="cursor-pointer" v-on:click="$router.push('/settings/general')"
              >Verify now</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { dailyBonusStore } from '@/store/pinia/dailyBonusStore';
import { getBalanceIcon } from '@/lib/icons';
import { mapGetters } from 'vuex';
import VerifyAccount from '@/components/Bank/VerifyAccount.vue';
import { balanceStore } from '@/store/pinia/balanceStore';
import { getErrorCodeMessage } from '@/utils';
export default {
  name: 'TopUp',
  components: { VerifyAccount },
  props: {
    isOnBreak: {
      default: true,
    }
  },
  setup() {
    const dailyBonusS = dailyBonusStore();
    const balS = balanceStore();
    return { dailyBonusS, getBalanceIcon,balS };
  },
  data() {
    return {
      lastClaim: null,
      loading: true,
      remainingTime: {
        hours: '00',
        minutes: '00',
        seconds: '00',
      },
      interval: null,
    };
  },
  async created() {
    await this.balS.init();
    await this.fetch();
    this.startTimer();
  },
  computed: {
    ...mapGetters(['me']),
    canClaim() {
       
      return !this.interval && !this.loading && this.me.email_verified && (this.formatedGoldamount < 10);
    },
    formatedGoldamount()
    {
      
      return this.balS.formatCryptoRaw(this.balS.goldBalance, true);
    }
  },

  methods: {
    startTimer() {
      let nextClaimTime = new Date();
      nextClaimTime.setHours(nextClaimTime.getHours() + this.remainingTime.hours);
      nextClaimTime.setMinutes(nextClaimTime.getMinutes() + this.remainingTime.minutes);
      nextClaimTime.setSeconds(nextClaimTime.getSeconds() + this.remainingTime.seconds);
      if (this.interval != null) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        const currentTime = new Date();
        let duration = nextClaimTime.getTime() - currentTime.getTime();
        if (duration <= 0 && this.interval) {
          clearInterval(this.interval);
          this.remainingTime = {
            hours: '00',
            minutes: '00',
            seconds: '00',
          };
          this.interval = null;
        }

        let milliseconds = parseInt(((duration % 1000) / 100).toString());
        let seconds = parseInt(((duration / 1000) % 60).toString());
        let minutes = parseInt(((duration / (1000 * 60)) % 60).toString());
        let hours = parseInt(((duration / (1000 * 60 * 60)) % 24).toString());
        hours = Math.max(hours, 0);
        minutes = Math.max(minutes, 0);
        seconds = Math.max(seconds, 0);

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        this.remainingTime = { hours, minutes, seconds, milliseconds };
      }, 1000);
    },
    async fetch() {
      try {
        const { data } = await this.dailyBonusS.lastClaimedGcTopup();
        this.lastClaim = data;
        this.remainingTime = data.difference;
      } catch (e) {
        const { message } = getErrorCodeMessage(e, 'Unable to fetch claimed top up!');
        this.$swal(
          'Uh-oh',
          'Something went wrong: ' + message,
          'error',
        );
      } finally {
        this.loading = false;
      }
    },
    async claim() {
      this.$swal({
        title: 'Confirm claiming',
        html: `You are claiming  <strong style="color: grey"> 1,000 Gold Coins </strong>
              <br><br>Next Top Up Available in  <strong class="mt-4" style="color: grey">4 Minutes.</strong>`,
        showCancelButton: true,
        animation: 'slide-from-top',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          try {
            await this.dailyBonusS.claimGCtopup();
            await this.fetch();
            this.startTimer();
            await this.balS.init();
            this.$swal('', `Topup Claimed successfully`, 'success');
          } catch (e) {
            const { message } = getErrorCodeMessage(e, 'Unable to claim top up!');
            this.$swal(
              'Uh-oh',
              'Something went wrong: ' + message,
              'error',
            );
          } finally {
            this.loading = false;
          }
        }
      })
      .catch((error) => {});
    },
  },
};
</script>
