<template>
  <punt-modal show-close="true" @close="$emit('close')" size="max-w-2xl p-4 my-3" bgColor="bg-gray-800">
    <template #title>
      <div class="flex w-11/12 justify-between">
        <span>{{race.name}}</span>
        <span>Ends in {{ Math.floor((new Date()-new Date(race.end_time))/1000/60/60*-1) }} hours</span>
      </div>
    </template>
    <template #body>
      <div class="flex w-11/12 justify-between text-white">
        <span>My Position: {{ this.leaderboard.findIndex((score)=>score.user_id===this.me.id)+1 }}</span>
        <span></span>
      </div>
    <div class="flex w-10/12 max-w-[1200px] flex-col m-auto text-sm">
      <div>
        <p v-if="!leaderboard || leaderboard.length === 0">
          <label
            class="flex flex-row items-center justify-center text-center w-full text-sm">
            <div v-if="isLoading" class="pt-2">
              <svg
                class="h-10"
                id="L4"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enable-background="new 0 0 0 0"
                xml:space="preserve">
                <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.1" />
                </circle>
                <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.2" />
                </circle>
                <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.3" />
                </circle>
              </svg>
            </div>
            <div v-else class="pt-2">No winners yet! Maybe you'll be the first.</div>
          </label>
        </p>
        <div v-else>
          <div class="flex items-center justify-center h-5" v-if="isLoading">
            <svg
              class="h-10"
              id="L4"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve">
              <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1" />
              </circle>
              <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2" />
              </circle>
              <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3" />
              </circle>
            </svg>
          </div>
          <div v-else>
            <div class="flex mt-10 text-center justify-center text-medium md:hidden">
              <button
                class="mr-5 disabled:opacity-50"
                :disabled="currentPage == 1"
                @click="fetchHistory(false)">
                Previous
              </button>
              <button
                class="disabled:opacity-50"
                :disabled="currentPage == lastPage"
                @click="fetchHistory(true)">
                Next
              </button>
            </div>
            <table class="table w-full">
              <thead class="text-center">
                <tr class="text-slate-300">
                  <th class="md:table-cell flex w-[100px]">
                    <label>Rank </label>
                  </th>

                  <th class=" md:table-cell w-[180px]">
                    <label> User </label>
                  </th>                 
                  <th class=" md:table-cell w-[180px]">
                    <label> Total Played </label>
                  </th>
                  <th class=" md:table-cell w-[180px]">
                    <label> Prize </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(bet, idx) in leaderboard.slice(0+perPage*currentPage,perPage+perPage*currentPage)"
                  v-bind:key="bet.id"
                  :class="idx % 2 == 0 ? 'is-even' : 'bg-secondary-light'">
                  <td><span class="flex flex-row items-center justify-center text-center">{{ idx+1+currentPage*perPage }}</span></td>
                  <td><span class="flex flex-row items-center justify-center text-center">{{ bet.username }}</span></td>
                  <td><span class="flex flex-row items-center justify-center text-center">{{ bet.score }}</span></td>
                  <td><span class="flex flex-row items-center justify-center text-center">{{ this.prizes[idx+currentPage*perPage] }} <img v-if="this.race.game_mode==='SC'" src="/src/assets/coins/cash.png" class="max-w-7 inline pl-2"/><img v-else class="max-w-7 inline pl-2" src="/src/assets/coins/gold.png"></span></td>
                </tr>
              </tbody>
            </table>

            <div class="flex mt-10 text-center justify-center text-medium">
              <button
                class="mr-5 disabled:opacity-50 text-slate-300"
                :disabled="currentPage == 0"
                @click="changePage(-1)">
                Previous
              </button>
              <button
                class="disabled:opacity-50 text-slate-300"
                :disabled="currentPage >= leaderboard.length/perPage-1"
                @click="changePage(1)">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import { raceStore } from '../store/pinia/raceStore';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'RaceModal',
  components: {
    PuntModal, 
  },

  props: {
    race: {
      default: {},
    },
  },



  setup() {
    const store = raceStore();
    return { store, prizes:[],perPage:10 };
  },

  async created() {
      this.init();
    },

  data() {
    return {
      leaderboard: [],
      isLoading: false,
      currentPage: 0,
        nextPage: 1,
        lastPage: 999,
    }
 },

  mounted() {
   
  },

  unmounted(){
  },

  computed: {

  },
  methods: {
    async init() {
        try {
            const response = await this.store.fetchLeaderboard(this.race.id)
            this.leaderboard=response.data;
            const winners = JSON.parse(this.race.payouts).winners;
            const prize = parseFloat(this.race.prize);
            const prizes = []
            winners.forEach((winner)=>prizes.push(prize*(winner/100)))
            this.prizes=prizes;
        } catch (e) {
        } finally {
          this.loading = false;
        }
      },

      changePage(value){
        return this.currentPage+=value
      },

  },
  watch: {
  },
  computed: {
    ...mapGetters(['me']),
  },
};
</script>


<style scoped>
  .panel {
    border: none;
  }
  
  .table {
    border: none;
  }
  
  td {
    border: none;
    color: #ffffff;
  }
  
  tr {
    height: 48px;
    border: none;
  }
  
  th {
    padding: 15px 0 0 0;
    border: none;
  }
  
  table {
    transform-style: preserve-3d;
  }
  
  tbody {
    overflow: hidden;
  }
  
  thead {
    z-index: 0;
  }
  </style>
