<template>
  <div class="w-full h-screen">
    <NotificationCenter />
  </div>
</template>

<script>
import NotificationCenter from '@/components/Notifications/NotificationCenter.vue';
export default {
  components: {
      NotificationCenter,
    },
  };
</script>