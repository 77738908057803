<template>
  <div class="p-4">
    <div class="flex flex-col gap-4 text-lg text-slate-300/60 font-sesmibold">
      <div class="flex justify-center items-center gap-5 mt-5">
        <img src="../../assets/svg/icon-face.svg" height="120" width="120" />
      </div>
      <div class="m-5">
        <ul class="list-disc ml-4">
          <li>
            To proceed further, please verify your identity by taking a quick selfie.
          </li>
          <li>
            Redemption request will be processed as soon selfie face verification is
            verified and approved.
          </li>
        </ul>
      </div>
    </div>
    <div class="w-full">
      <div class="w-full flex flex-col items-center justify-center mt-4">
        <a
          :disabled="link == ''"
          target="_blank"
          :href="link"
          class="btn-bet ch-button hover:opacity-75 disabled:opacity-50 min-w-[200px] text-black/90">
          Verify Your Identity
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BioAuthVerify.vue',
  props: {
    bioAuthVerifylink: {
      default: null,
    },
  },
  data() {
    return { loading: false, session: null, link: this.bioAuthVerifylink };
  },
};
</script>
