<template>
  <router-view></router-view>
  <div class="p-0 md:p-8 h-auto md:min-h-[auto] flex flex-row justify-center">
    <div class="w-full max-w-[1200px]">
      <div
        class="flex flex-col-reverse xl:flex-row justify-center h-auto md:min-h-[auto]">
        <!-- Side Menu -->
        <game-actions
          :game-data="{ name: 'Duels', id: null }"
          :pre-extras="gameActions"
          class="bg-secondary-light rounded-br-xl rounded-bl-xl block md:hidden" />
        <div
          class="w-full px-2 md:px-3 md:w-full min-w-[300px] xl:w-[300px] py-2 md:py-3 bg-side-color md:rounded-bl-xl md:rounded-br-none md:rounded-tl-xl">
          <duels-bets
            :joinDuel="joinDuel"
            :spectateDuel="spectateDuel"
            :startGame="playGame"
            :cancel-duel="cancelDuel" />
        </div>
        <div
          class="flex flex-col items-center md:w-full md:h-auto md:min-h-[auto] bg-cover bg-default-bg md:rounded-tr-xl">
          <input id="set" class="hiddden text-red-500" hidden type="text" value="2d6" />
          <div class="h-full w-full flex flex-col justify-center bg-primary/60 relative">
            <div class="h-full w-full relative scale-50 md:scale-75">
              <div class="h-full w-full">
                <div
                  id="hostCanvas"
                  class="h-full w-full child:h-full child:w-full z-50"></div>
              </div>
            </div>
            <div class="flex w-full flex-col items-center scale-75 md:scale-100">
              <span :class="hostLabel['classes']" class="select-none font bold text-md">
                {{ hostLabel['text'] }}
              </span>
              <img class="h-16" src="../../assets/vs.png" />
              <span
                class="hidden w-3/4 border-t border-line-color bg-slate-500/60"></span>
              <span :class="guestLabel['classes']" class="select-none font bold text-md">
                {{ guestLabel['text'] }}
              </span>
            </div>
            <div class="h-full w-full relative scale-50 md:scale-75">
              <div class="h-full w-full z-50">
                <div
                  id="guestCanvas"
                  class="h-full w-full child:h-full child:w-full"></div>
              </div>
            </div>
          </div>
          <game-actions
            :game-data="{ name: 'Duels', id: null }"
            :pre-extras="gameActions"
            class="hidden md:block bg-secondary-light rounded-br-xl" />
        </div>
      </div>
    </div>
  </div>
  <game-history-table class="hidden md:flex" />
</template>

<script>
import { defaultConvertor } from '@/lib/rsgp';
import { mapActions, mapGetters } from 'vuex';
import DuelsGrid from './DuelsGrid.vue';
import DuelsBets from './DuelsBets.vue';
import DuelsFairness from './DuelsFairness.vue';
import { betSound, hitSound, loseSound, rollSound } from './sounds';
import GameHistoryTable from '../GameHistory/BetsHistory.vue';
import GameActions from '../Shared/GameActions.vue'; /*24, 28, 6, 34, 4, 2, 12, 19, 3, 17 */
import { duelStore } from '@/store/pinia/duelStore';
import { initializeDice } from './Roll/dice/main';
import { log } from 'debug/src/browser';

export default {
  components: {
    DuelsGrid,
    DuelsBets,
    DuelsFairness,
    GameHistoryTable,
    GameActions,
  },
  setup() {
    const duelS = duelStore();
    return { duelS };
  },
  props: {},
  async created() {
    await this.fetchDuels();
  },
  mounted() {
    this.init();
  },
  data: () => ({
    showFairness: false,
    currentState: 'notStarted',
    game: null,
    host: null,
    seed: null,
    limits: [],
    lobby: [],
    result: null,
    profit: 0,
    loading: false,
    wager: 0,
    onGameCompleted: null,
    onGameStarted: null,
    debouncedJoin: {},
    rollHost: null,
    rollGuest: null,
    clearHost: null,
    clearGuest: null,
    gameActions: [['/casino/war/rules', 'Rules']],
    clientSeed: '',
  }),
  watch: {
    'duelS.current.result': {
      handler: function (result) {
        if (result) {
          this.rollDuelsResult();
        }
      },
      immediate: true,
    },
    me: {
      handler: function (me) {
        if (me) {
          this.duelS.user = me;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['me', 'userBalance']),
    ...mapGetters('Sound', ['isSoundMuted']),
    hostLabel() {
      let text = 'HOST';
      let classes = 'text-slate-300/60';
      if (this.duelS.current) {
        text = this.duelS.current.host.username;
        if (this.result) {
          text += ` (${this.result.host})`;
          if (this.result.winnerId === null) {
            classes = 'text-[#F79410]';
          } else {
            classes =
              this.result.winnerId === this.duelS.current.host_id
                ? 'text-[#1EE563]'
                : 'text-[#ED1F24]';
          }
        }
      }
      return { text, classes };
    },
    guestLabel() {
      let text = 'JOINER';
      let classes = 'text-slate-300/60';
      if (this.duelS.current && this.duelS.current.guest) {
        text = this.duelS.current.guest.username;
        //if won, lost or tied
        if (this.result) {
          text += ` (${this.result.guest})`;
          if (this.result.winnerId === null) {
            classes = 'text-[#F79410]';
          } else {
            classes =
              this.result.winnerId === this.duelS.current.guest_id
                ? 'text-[#1EE563]'
                : 'text-[#ED1F24]';
          }
        }
      }
      return { text, classes };
    },
  },
  methods: {
    ...mapActions('Sound', ['toggleSound']),
    init() {
      const setRollHost = (roll, clear) => {
        this.rollHost = roll;
        this.clearHost = clear;
      };
      const setRollGuest = (roll, clear) => {
        this.rollGuest = roll;
        this.clearGuest = clear;
      };
      initializeDice(document.body, 'hostCanvas', setRollHost);
      initializeDice(document.body, 'guestCanvas', setRollGuest);
    },
    clearScene() {
      if (this.clearGuest) {
        try {
          this.clearGuest();
        } catch (e) {}
      }
      if (this.clearHost) {
        try {
          this.clearHost();
        } catch (e) {}
      }
    },
    async rollDuelsResult() {
      const currentDuel = this.duelS.current;
      if (currentDuel && currentDuel.result) {
        const result = currentDuel.result;
        const host = [result.host.first, result.host.second];
        const guest = [result.guest.first, result.guest.second];
        this.rollGuest(guest[0], guest[1]);
        this.rollHost(host[0], host[1]);
        await this.verify2bo(500);
        this.playSound(rollSound);
        await this.verify2bo(2000);
        const outcome = {
          host: result.host.total,
          guest: result.guest.total,
          winnerId: null,
        };
        this.duelS.currentState = 'notStarted';
        if (outcome.host !== outcome.guest) {
          outcome.winnerId =
            outcome.host > outcome.guest ? currentDuel.host_id : currentDuel.guest_id;
          const won = outcome.winnerId === this.me.id;
          this.playHitLoseSound(won);
        }
        this.result = outcome;
      }

      //show result
    },
    setActiveMode(mode) {
      this.duelS.activeMode = mode;
      // clear game canvas if user has not joined the current game being viewed
      if (
        !this.duelS.joined &&
        this.duelS.currentState === 'inProgress' &&
        this.duelS.activeMode === 'NewBet'
      ) {
        this.duelS.currentState = 'notStarted';
      }
    },
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.play();
      }
    },

    playHitLoseSound(win = true) {
      this.playSound(win ? hitSound : loseSound);
    },

    async fetchDuels() {
      try {
        await this.duelS.fetch();
        if (this.me !== null) {
          this.duelS.current = this.duelS.duels.find(
            (duel) => duel.host_id === this.me.id,
          );
          if (this.duelS.current) {
            this.spectateDuel(this.duelS.current);
          }
        }
      } catch (e) {}
    },

    spectateDuel(duel) {
      this.initGame(duel);
    },

    async joinDuel(duelId, seed) {
      try {
        this.duelS.loading = true;
        this.playSound(betSound);
        const bodyData = { duelId, seed };
        await this.duelS.join(bodyData);
      } catch (e) {
        const { data, status } = e;
        this.onBetException(data, status, false);
      } finally {
        setTimeout(() => (this.duelS.loading = false), 2000);
      }
    },
    async cancelDuel() {
      try {
        const duel = this.duelS.current;
        this.duelS.loading = true;
        if (duel && duel.host_id === this.me.id) {
          await this.duelS.cancel(duel.id);
        }
      } catch (e) {
        const { data, status } = e;
        this.onBetException(data, status, false);
      } finally {
        setTimeout(() => (this.duelS.loading = false), 2000);
      }
    },
    initGame(game) {
      if (this.onGameStarted !== null) {
        this.onGameStarted();
      }
      this.clearScene();
      this.result = null;
      // limits
      if (game) {
        this.setActiveMode('NewBet');
        this.duelS.current = game;
        this.host = game.host;
        this.wager = game.wager;
        this.duelS.currentState = 'inProgress';
      }
    },

    verify2bo(period) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, period);
      });
    },

    async playGame(betAmount, clientSeed) {
      this.playSound(betSound);

      this.wager = betAmount;
      this.clientSeed = clientSeed;

      const bodyData = {
        wager: this.wager,
        seed: this.clientSeed,
      };

      try {
        this.duelS.loading = true;
        const { data } = await this.duelS.create(bodyData);
        this.duelS.currentState = 'inProgress';
        this.initGame(data);
      } catch (e) {
        const { data, status } = e;
        this.onBetException(data, status);
      } finally {
        this.duelS.loading = false;
      }
    },

    onBetException(data, status, creating = true) {
      let message = '';
      if (status > 412) {
        message = '';
      } else if (status >= 400 && status <= 412) {
        message += ' : ' + ((typeof data.error != 'undefined')?data.error:'');
      } else {
        message += ' : ' + ((typeof data.error != 'undefined')?data.error:'');
      }
      if (message.length > 0) {
        const finalMessage = `Something went wrong when ${
          creating ? 'creating' : 'joining'
        } war${message}`;
        window.swal('Uh-oh', finalMessage, 'error');
      }

      if ((typeof data.error != 'undefined') && data.error === 'Unauthenticated.') {
        this.$router.replace('?tab=login');
      } else {
        window.swal(
          'Uh-oh',
          'Something went wrong when placing your play: ' + ((typeof data.error != 'undefined')?data.error:''),
          'error',
        );
      }
    },
    changeState(newState) {
      this.duelS.currentState = newState;
    },
  },
};
</script>

<style scoped>
.warBox {
  height: 100%;
}
</style>
