<template>
  <div class="flex flex-col items-center w-full">
    <Loader v-if="isLoading" class="h-screen"></Loader>
    <div v-else class="flex w-full max-w-[1200px] flex-col bg-secondary my-3 outer-container rounded-[25px]">
      <div class="m-8">
        <img class="rounded-2xl"
             src="@/assets/banners/weekly_raffle.png"
             alt="">
        <div class="mt-10 lg:flex sm:block flex sm:flex-row flex-col justify-evenly">
          <div class="lg:w-1/3">
            <h1 class="md:text-4xl text-2xl font-bold font_new_plak md:my-1 my-4">
              <span class="text-[orange] mr-1">{{ formattedNumberValue(this.race?.prize) }}</span>
              <img v-if="race?.game_mode === 'SC'" src="@/assets/coins/cash.png" class="sm:w-8 w-6 mx-1 mb-1 inline" alt="">
              <img v-if="race?.game_mode === 'GC'" src="@/assets/coins/gold.png" class="sm:w-8 w-6 mx-1 mb-1 inline" alt="">
              <span class="ml-1">RACE</span>
            </h1>
          </div>
          <div class="lg:w-2/3 flex sm:flex-row flex-col justify-evenly">
            <div class="sm:w-[40%]">
              <div class="rounded-2xl bg-secondary-light sm:mx-2 my-1 px-5 py-3">
                <p class="mb-2 font-bold"> ⏰ RACE Ends In: </p>
                <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div class="bg-onprimary h-2.5 rounded-full" :style="{ width: counterWidth + '%' }"></div>
                </div>
                <p class="text-sm font-bold py-2 text-[orange]">{{ counterTime }}</p>
              </div>
            </div>

            <div class="sm:w-[60%] flex sm:flex-row flex-row justify-evenly sm:gap-0 gap-2">
              <div class="w-1/2">
                <div class="rounded-2xl bg-secondary-light sm:mx-2 my-1 px-5 py-3">
                  <p class="font-bold"> 🏆 Winners</p>
                  <p class="text-4xl font-bold font_new_plak text-[orange] py-2"> {{ getPrizes.length }}</p>
                </div>
              </div>
              <div class="w-1/2">
                <div class="rounded-2xl bg-secondary-light sm:mx-2 my-1 px-5 py-3">
                  <p class="font-bold"> 🎖️ Your Position</p>
                  <p class="text-4xl font-bold font_new_plak text-[orange] py-2"> {{ getMyPosition() }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-10 raffle-info">
          <div>
            <p class="md:text-3xl text-2xl font-bold font_new_plak my-4">{{ race?.name }}</p>
            <ul class="[list-style:disc] text-[14px] sm:text-[16px] font-light ml-6">
              <li>We’re giving away {{ formattedNumberValue(this.race?.prize) }}
                <img v-if="race?.game_mode === 'SC'" src="@/assets/coins/cash.png" class="inline md:h-5 h-4 my-1" alt="">
                <img v-if="race?.game_mode === 'GC'" src="@/assets/coins/gold.png" class="inline md:h-5 h-4 my-1" alt="">
                to the fastest racers today! </li>
              <li>Races will be going on non-stop throughout the entire month of December. Merry Christmas and a Happy Holidays to all!</li>
              <li>For every spin on Provider of Month, you’ll climb our Leaderboard with prizes instantly paid out to the top.</li>
            </ul>
          </div>
        </div>

        <div class="mt-10">
          <p class="md:text-3xl text-2xl font-bold font_new_plak my-4">Ways to Enter</p>

          <div class="flex lg:flex-row flex-col lg:gap-3 gap-4 justify-evenly">
            <div class="lg:w-1/2">
              <div class="rounded-2xl bg-secondary-light font-bold ways-to-enter py-[28px] pl-[28px] pr-[110px] min-h-[130px]">
                <div class="w-4/5">
                  <p class="text-md font-light">Play Now</p>
                  <p class="font-bold text-[16px]">Spin slots in the <router-link class="inline underline text-[#FFA500]" :to="'/casino/group/providerofthemonthalt'">Provider of the Month*</router-link> section.</p>
                </div>

                <img class="clown-image" src="@/assets/banners/img.png">
              </div>
            </div>
            <div class="lg:w-1/2">
              <div class="rounded-2xl bg-secondary-light font-bold ways-to-enter py-[28px] pl-[28px] pr-[110px] min-h-[130px]">
                <div class="w-4/5">
                  <p class="text-md font-light">Play Now</p>
                  <p class="font-bold text-[16px]">The faster you race, the higher you climb, the bigger the prize!</p>
                </div>
                <img class="clown-image" src="@/assets/banners/img_1.png">
              </div>
            </div>
          </div>
        </div>

        <div class="mt-10">

          <p class="md:text-3xl text-2xl font-bold font_new_plak my-4">Race Prizes</p>
          <p class="lead text-sm font-light my-2">Here's the breakdown. Good Luck!</p>

          <div class="flex flex-wrap justify-evenly">
            <div
                class="lg:w-1/5 sm:w-1/2 w-full"
                v-for="(prize, index) in getPrizes"
            >
              <div class="rounded-2xl bg-secondary-light my-2 sm:mx-2">
                <p class="px-2 py-4 text-2xl text-center font-bold">{{ formattedNumberValue((prize*race?.prize)/100) }} {{ race?.game_mode }}</p>
                <p class="py-1 rounded-b-2xl text-center text-xs font-bold text-black" :class="getRankWithUsername(index+1)[1]">{{ getRankWithUsername(index+1)[0] }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-10">
          <p class="md:text-3xl sm:text-2xl text-xl font-bold font_new_plak my-4">Other Terms</p>
          <ul class="[list-style:disc] text-[14px] sm:text-[16px] font-light ml-6">
            <li>For this Race, all games in the Provider of the Month section are eligible. The games are as follows: Le Pharaoh, Rise of Ymir, Ze Zeus, SixSixSix, Donny Dough, Dragons Domain, Densho, Slayers INC, Cloud Princess, Fist of Destruction, 2 Wild 2 Die, Joker Bombs, Bouncy Bombs, Cash Crew, Divine Drop, Benny the Beer, Rotten, Dork Unit.</li>
            <li>No purchase necessary, play and enter our races for free!</li>
            <li>Should Chanced detect fraud, foul play, any manipulation of the promotion rules or abuse of the promotion, the relevant player and their related accounts will be suspended from current and any future promotions.</li>
            <li>Chanced reserves the right to amend, suspend, or cancel the promotion at any time.</li>
            <li>Please review our Terms of Service for further details.</li>
          </ul>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import banner from '@/assets/banners/weekly_raffle.png';
import axios from "axios";
import Loader from "@/components/Shared/Loader.vue";
import {mapGetters} from "vuex";

export default {
  name: 'PromoRace',
  components: {Loader},
  setup() {
    return {

    };
  },
  mounted() {

  },
  async created() {
    await this.getRace();
  },
  data() {
    return {
      id: null,
      counterTime : '...',
      counterWidth: 0,
      race : null,
      leaderboard : [],
      isLoading: false,
    countdownInterval: null,
    };
  },
  methods: {
    getCountDownTimer() {
      let endDateTime = this.formatDateTime(this.race?.end_time);
      let startDateTime = this.formatDateTime(this.race?.start_time);

      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count-down date
      let distance = endDateTime - now;

      if(startDateTime > now) {
        this.counterTime = 'Yet to Start';
      }
      else {
        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.counterTime = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';

        const totalDuration = endDateTime - startDateTime;
        const elapsedDuration = now - startDateTime;

        const percentage = (elapsedDuration / totalDuration)*100;
        this.counterWidth = Math.min(Math.max(percentage, 0), 100).toFixed(2);

        if (distance <= 0) {
          clearInterval(this.countdownInterval);
          this.counterTime = 'EXPIRED';
        }
      }
    },
    async getRace() {
      this.isLoading = true;
      await axios.get('/promo_race/'+ this.$route.params.id)
        .then(response => {
          this.race = response.data.race;
          this.leaderboard = response.data.leaderboard;
        // Clear any existing interval before setting a new one
        if (this.countdownInterval) {
          clearInterval(this.countdownInterval);
        }
        this.countdownInterval = setInterval(() => this.getCountDownTimer(), 1000);
        })
        .catch(error => {
          console.log('error while getting race data', error)
        }).finally(x => {
          this.isLoading = false;
        });
    },
    formatDateTime(dateString) {
      return new Date(dateString).getTime()
    },
    formattedNumberValue(amount) {
      return new Intl.NumberFormat('en-US').format(amount);
    },
    getRankWithUsername(rank) {
      let rankData = '';
      let rankClass = '';
      switch (rank) {
        case 1:
          rankData = "1st Place" + (this.leaderboard[0] ? ': ' + this.maskString(this.leaderboard[0]['username']) : '');
          rankClass = 'bg-amber-300';
          break;
        case 2:
          rankData = "2nd Place" + (this.leaderboard[1] ? ': ' + this.maskString(this.leaderboard[1]['username']) : '');
          rankClass = 'bg-[#9888cd]';
          break;
        case 3:
          rankData = "3rd Place" + (this.leaderboard[2] ? ': ' + this.maskString(this.leaderboard[2]['username']) : '');
          rankClass = 'bg-[#ffa15e]';
          break;
        default:
          rankData = rank + "th Place" + (this.leaderboard[rank-1] ? ': ' + this.maskString(this.leaderboard[rank-1]['username']) : '');
          rankClass = 'bg-[#d9d1f4]';
          break;
      }
      return [rankData, rankClass];
    },
    maskString(str) {
      if (str.length <= 3) return str;
      const start = str.slice(0, 2);
      const end = str.slice(-1);
      const maskedMiddle = '*'.repeat(str.length - 3);
      return start + maskedMiddle + end;
    },
    getMyPosition() {
      const matchedPosition = this.leaderboard.findIndex((position) => position.user_id === this.me.id);
      return matchedPosition !== -1 ? matchedPosition + 1 : '-';
    }
  },
  computed: {
    ...mapGetters(['me']),
    getPrizes() {
      return this.race ? JSON.parse(this.race?.payouts)?.winners : [];
    },
  },
  beforeDestroy() {  // Use 'unmounted' if using Vue 3
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  },
  watch: {},
};
</script>

<style scoped>
.font_new_plak {
  font-family: var(--neue-plak);
}

.ways-to-enter {
  position: relative;
}
.font-light {
  color: #9789CD;
  font-weight: 500;
}

.clown-image {
  top: 10px;
  right: -8px;
  width: 7.25rem;
  height: 7.25rem;
  position: absolute;
}

@media (min-width: 768px) {
  .clown-image {
    top: -10px;
    width: 9.25rem;
    height: 9.25rem;
  }
}

</style>