<template>
  <div class="flex w-full flex-col items-center">
    <div
      :class="{ 'max-w-[1200px] p-4 md:p-8': !generalS.isTheatreMode }"
      class="flex w-full flex-col items-center">
      <div class="flex w-full max-w-[calc(138vh)] flex-col 2xl:max-w-[calc(160vh)]">
        <div class="flex h-full w-full flex-col items-center">
          <!-- relative bg-[#0F212E] w-full  rounded-t-xl flex flex-row justify-center items-center h-full     -->
          <div
            id="relax_game_wrapper"
            class="relative  md:h-0 w-full pt-[56.25%] child:absolute child:left-0 child:top-0 child:h-full child:w-full child:rounded-t-lg">
            <div
              v-if="gameDispatcher == null"
              class="absolute inset-0 z-10 flex h-full w-full flex-row items-center justify-center rounded-t-xl bg-[#0e1224]/90">
              <div
                class="z-50 flex flex-col items-center justify-center gap-4"
                v-if="!loading">
                <!-- <fiat-dropdown :show-balance-label="true" /> -->

                <!-- {{ !isLoggedIn ? "Login" : "" }} -->
                <div class="flex flex-row gap-4" v-if="!isLoggedIn">
                  <button
                    class="flex flex-row items-center rounded-md bg-[#00e701] px-4 py-3 font-semibold text-black"
                    v-on:click="redirectAuth()">
                    Login
                  </button>
                  <!-- <button
                    v-if="this.relaxGame && !this.relaxGame.has_live"
                    class="rounded-md bg-[#2f4553] px-4 py-2 font-semibold"
                    v-on:click="launchGame(true)"
                  >
                    Fun Play
                  </button> -->
                </div>
              </div>
              <div v-else class="flex flex-row text-gray-200">
                <svg
                  class="-ml-1 mr-3 h-10 w-10 animate-spin text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"></circle>
                  <path
                    class="opacity-75"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    fill="currentColor"></path>
                </svg>
              </div>
            </div>
          </div>
          <game-actions
            :document-id="'relax_game_wrapper'"
            :game-data="gameData"
            class="rounded-b-lg bg-secondary-light" />
        </div>
        <game-details
          v-if="relaxGame"
          :game="relaxGame.title"
          :edge="relaxGame.house_edge"
          :provider="relaxGame.provider.name"
          class="mt-6 md:mt-12" />
      </div>
    </div>
  </div>
  <template class="flex flex-col items-center">
    <game-history-table class="hidden w-full max-w-[1200px] md:flex" />
  </template>
</template>

<script>
import { mapGetters } from 'vuex';
import { isMobileOnly } from 'mobile-device-detect';
import { getErrorCodeMessage } from '@/utils';

import GameHistoryTable from '../GameHistory/BetsHistory.vue';
import GameActions from '../Shared/GameActions.vue';
import { relaxStore } from '@/store/pinia/relaxStore';
import FiatDropdown from '@/components/Bank/FiatDropdown.vue';
import { balanceStore } from '@/store/pinia/balanceStore';
import { generalStore } from '@/store/pinia/generalStore';
import GameDetails from '@/components/Shared/GameDetails.vue';

export default {
  name: 'Relax',
  components: {
    GameDetails,
    FiatDropdown,
    GameHistoryTable,
    GameActions,
  },
  setup() {
    const relaxS = relaxStore();
    const balS = balanceStore();
    const generalS = generalStore();
    return { relaxS, balS, generalS };
  },
  data() {
    return {
      loading: false,
      gameDispatcher: null,
      demoLoaded: false,
      relaxGame: null,
      game: '',
      provider: '',
    };
  },
  async created() {
    //await this.launchGame(true, true)

       
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
    ...mapGetters('Sound', ['isSoundMuted']),
    gameData() {
      if (this.relaxGame) {
        return { id: this.relaxGame.id, name: null, software: 'relax' };
      }
      return { id: null, name: null };
    },
  },
  watch: {
    '$route.params.name': {
      async handler() {
        await this.init();
        //auto launch demo mode on game started
        //await this.launchGame(true, true)
      },
      // force eager callback execution
      immediate: true,
    },
    'balS.defaultFiat': {
      async handler() {
        await this.launchGame(!this.gameDispatcher);
      },
      // force eager callback execution
      immediate: true,
    },
    'balS.mainCurrency': {
      async handler() {
        await this.launchGame(!this.gameDispatcher);
      },
      // force eager callback execution
      immediate: true,
    },
  },
  methods: {
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.pause();
        sound.currentTime = 0;
        sound.play();
      }
    },
    async init() {
      try {
        this.relaxGame = null;
        this.loading = true;
        this.parseGameName(this.$route.params.name);
        const { data } = await this.relaxS.fetchGame(this.game);
        this.relaxGame = data;
        if (this.isLoggedIn) {
          this.launchGame();
        }
      } catch (e) {
        const { message } = getErrorCodeMessage(e, 'Unable to fetch the game!');
        this.$toast.error('Something went wrong: ' + message);
      } finally {
        this.loading = false;
      }
    },
    parseGameName(game) {
      this.provider = game.split('-')[0];
      this.game = game
        .split('-')
        .slice(1)
        .map((element) => {
          return element[0] + element.slice(1);
        })
        .join('');
    },

    async redirectAuth() {
      if (!this.isLoggedIn) {
        await this.$router.push('/auth/login');

      }
    },

    async launchGame(isInitial = false) {
      if (!this.isLoggedIn) {
        await this.$router.push('/auth/login');
        return;
      }

      if (!this.relaxGame?.identifier || this.relaxGame?.has_live) {
        return;
      }

      try {
        // if (this.demoLoaded && isDemo) {
        //   this.gameDispatcher = "gameDispatcher";
        //   return;
        // }

        if (!isInitial) {
          this.loading = true;
        }
        // if (this.balS.mainBalance == 0 && !isDemo) {
        //   this.$toast.error("Insufficient balance");
        //   return;
        // }

        const { data } = await this.relaxS.createGameSession(
          isMobileOnly,
          this.relaxGame.identifier,
          this.balS.defaultFiat,
          this.balS.mainCurrency,
          this.balS.mainBalance,
        );

        if (data?.error) {
          this.$toast.error(data?.message);
          return;
        }

        const gameLaunchOptions = {
          target_element: 'relax_game_wrapper',
          ...data,
        };
        const onSuccess = (gameDispatcher) => {
          if (!isInitial) {
            this.gameDispatcher = 'gameDispatcher';
          } else {
            this.demoLoaded = true;
          }
          this.loading = false;
        };
        const onError = (error) => {
          this.loading = false;
        };
        window.sg.launch(gameLaunchOptions, onSuccess, onError);
      } catch (e) {
        const { message, code } = getErrorCodeMessage(e, 'Unable to launch the game!');
        if (code === 700) {
          this.relaxGame.has_live = true;
        }
        this.$toast.error('Something went wrong: ' + message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.justify-content-start {
  justify-content: flex-start;
}

.centralizer {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 100%;
  padding-bottom: 1em;
}

.control.has-text-centered {
  text-align: center;
}

.is-uppercase {
  text-transform: uppercase;
}

.has-pointer-cursor {
  cursor: pointer;
}

.has-south-east-stickiness {
  align-self: flex-end;
}

.panel table {
  margin-bottom: 0px;
}
@media (max-width:767px)
{
  #relax_game_wrapper
  {
    height: calc(100dvh - 65px - 79px - 33px);
    padding: 0;
  }
}
@media (max-width:480px)
{
  #relax_game_wrapper
  {
    height: calc(100dvh - 54px - 60px - 33px);
    padding: 0;
  }
}
</style>

<style scoped>
iframe {
}
</style>
