<template>
  <div class="flex flex-col items-center w-full">
    <div class="flex w-full max-w-[1200px] flex-col">
      <div
        class="relative flex items-center justify-between w-full gap-2 px-4 py-4 bg-secondary/30 md:px-6 max-[767px]:pb-0">
        <h1 class="py-4 text-2xl font-bold text-slate-300">
          {{ this.group?.name }}
        </h1>
        <img
          class="h-[50px] lg:h-[120px] brightness-90 sm:h-[80px]"
          :src="this.currentSlug in this.defaultGroups ? this.group.icon : getCategoryIcon(this.group)"
          @error="loadFallbackImage"
          :data-fallback="getDefaultCategoryIcon()"
          alt="" />
      </div>
      <div
        class="relative flex items-center justify-between w-full gap-2 px-4 py-4 text-slate-300 md:px-6 max-[767px]:pt-2">
        <div class="flex flex-row items-center gap-1.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="hidden w-5 h-5 rotate-90 md:block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
          </svg>
          <span class="hidden text-sm md:block"> Filter By </span>
          <provider-filters
            :providers="providers"
            :toggle-provider="toggleProvider"
            :clear-all="clearProviders"
            :selected-providers="selectedProviders" />
        </div>
        <div v-if="!this.hideSortBy" class="flex flex-row items-center gap-1.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="hidden w-5 h-5 rotate-90 md:block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
          </svg>
          <span class="hidden text-sm md:block"> Sort By </span>
          <sort-filters :sort="sortOrder" :change-sort-order="changeSortOrder" />
        </div>
      </div>
      <div class="px-4 py-2 md:px-6 md:py-4">
        <div class="relative w-full mt-4 md:mt-0">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              class="w-5 h-5 text-gray-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                fill-rule="evenodd"></path>
            </svg>
          </div>
          <input
            v-model="searchText"
            class="input-search"
            placeholder="Search your game"
            type="text" />
        </div>
        <div class="flex flex-row justify-between mt-4"></div>
        <h-navigator v-show="!this.hidePills" :refresh-selected-providers="refreshSelectedProviders"/>
        <div class="flex items-center justify-center h-5" v-if="loading">
          <svg class="h-10" id="L4" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
            <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
              <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
            </circle>
            <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
              <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
            </circle>
            <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
              <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
            </circle>
          </svg>
        </div>
        <AllJackpotCounter v-if="this.group.slug=='jackpot-play' || this.group.slug=='jackpot'" :currency="me.jackpot_amount.currency" :type=2 :alltiers="me.jackpot_amount.alltiers" />
        <div
          class="grid w-full grid-cols-3  gap-2 mt-4 grid-rows-auto sm:grid-cols-4 sm:gap-3 lg:gap-4 md:grid-cols-5 ">
          <router-link
            class="flex flex-col hover:rotate-1 transition hover:scale-105 relative"
            v-for="(game, i) in games"
            :key="i"
            :to="getGameLink(game, game.provider)">
            <JackpotCounter v-if="game.has_jackpot && me.jackpot_amount.alltiers" :alltiers="me.jackpot_amount.alltiers" :currency="me.game_mode" />
            <img
              :src="getTileImage(game)"
              @error="loadFallbackImage"
              :data-fallback="getDefaultTileImage(game)"
              alt="Game Tile"
              v-tooltip="game.title"
              class="flex flex-col items-center w-auto transition duration-150 ease-in-out rounded-md shadow-md delay-0  md:w-56" />
          </router-link>
        </div>
        <div
          class="flex flex-col items-center justify-center py-12 text-slate-300/70"
          v-if="games.length === 0 && !loading">
          <svg width="96" height="96" fill="none" class="mx-auto mb-6 text-slate-300/70">
            <path
              d="M36 28.024A18.05 18.05 0 0025.022 39M59.999 28.024A18.05 18.05 0 0170.975 39"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <ellipse cx="37.5" cy="43.5" rx="4.5" ry="7.5" fill="currentColor"></ellipse>
            <ellipse cx="58.5" cy="43.5" rx="4.5" ry="7.5" fill="currentColor"></ellipse>
            <path
              d="M24.673 75.42a9.003 9.003 0 008.879 5.563m-8.88-5.562A8.973 8.973 0 0124 72c0-7.97 9-18 9-18s9 10.03 9 18a9 9 0 01-8.448 8.983m-8.88-5.562C16.919 68.817 12 58.983 12 48c0-19.882 16.118-36 36-36s36 16.118 36 36-16.118 36-36 36a35.877 35.877 0 01-14.448-3.017"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              d="M41.997 71.75A14.94 14.94 0 0148 70.5c2.399 0 4.658.56 6.661 1.556a3 3 0 003.999-4.066 12 12 0 00-10.662-6.49 11.955 11.955 0 00-7.974 3.032c1.11 2.37 1.917 4.876 1.972 7.217z"
              fill="currentColor"></path>
          </svg>
          <span>Sorry! There are no results matching the selected criteria.</span>
        </div>
        <div
          v-if="games.length > 0"
          class="flex flex-col items-center justify-center mt-8">
          <span>Displaying {{ games.length }} of {{ total }} games</span>
          <span class="flex items-center justify-center h-5" v-if="loading">
            <svg
              class="h-10"
              id="L4"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve">
              <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1" />
              </circle>
              <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2" />
              </circle>
              <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3" />
              </circle>
            </svg>
          </span>
          <button
            v-if="!loading && current_page < last_page"
            v-on:click="init(true)"
            :disabled="loading"
            class="bg-[#1EE563] text-secondary rounded-md md:rounded-2xl hover:opacity-75 md:px-4 md:py-3 md:mt-3 px-3 py-2 mt-2 max-[767px]:text-sm">
            Load More
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HNavigator from '../Home/HNavigator.vue';
import { relaxStore } from '@/store/pinia/relaxStore';
import { icons } from '../Shared/icons';
import { debounce } from 'lodash';
import ProviderFilters from '@/components/Relax/ProviderFilters.vue';
import SortFilters from '@/components/Relax/SortFilters.vue';
import JackpotCounter from '@/components/Pragmatic/JackpotCounter.vue';
import AllJackpotCounter from '@/components/Pragmatic/AllJackpotCounter.vue';
import { mapGetters } from 'vuex';
import {
  getCategoryIcon,
  getDefaultCategoryIcon,
  getDefaultTileImage,
  getGameLink,
  getTileImage,
  loadFallbackImage,
} from '@/utils';

export default {
  name: 'RelaxTiles',
  components: {
    ProviderFilters,
    SortFilters,
    HNavigator,
    JackpotCounter,
    AllJackpotCounter
  },
  setup() {
    const defaultGroups = {
      all: {
        name: 'All Games',
        icon: '',
        slug: 'all',
        categories: [],
        sub_category: [],
        options: {},
      },
      recent: {
        name: 'Recent',
        slug: 'recent',
        icon: icons.Recent,
        categories: [],
        sub_category: [],
        options: { recent: 'recent' },
      },
      favorites: {
        name: 'Favorites',
        slug: 'favorites',
        icon: icons.Favourites,
        categories: [],
        sub_category: [],
        options: { favorites: 'favorites' },
      },
    };
    return { defaultGroups, relaxS: relaxStore() };
  },
  computed: {
    ...mapGetters(['categoryOptions', 'categoryOptionsFetched','me']),
    group() {
      let selectedCategoryOptions = this.categoryOptions.find(item => item.slug === this.currentSlug);
      selectedCategoryOptions = (selectedCategoryOptions === undefined) ? (this.currentSlug in this.defaultGroups ? this.defaultGroups[this.currentSlug] : this.defaultGroups.all) : selectedCategoryOptions;
      this.hideSortBy = selectedCategoryOptions?.data?.hide_sort_by ?? false;
      this.hidePills = selectedCategoryOptions?.data?.hide_pills ?? false;
      return selectedCategoryOptions;
    },
    noResult() {
      return this.searchText.length > 0 && !this.loading && this.games.length === 0;
    },
  },
  created() {
    this.debouncedSearch = debounce(this.init, 300, {
      leading: false,
      trailing: true,
    });
  },
  data() {
    return {
      currentSlug: '',
      searchText: '',
      loading: false,
      games: [],
      providers: [],
      selectedProviders: [],
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
      debouncedSearch: null,
      sortOrder: 'order',
      hidePills: false,
      hideSortBy: false,
    };
  },
  methods: {
    getGameLink,
    getDefaultTileImage,
    getTileImage,
    getCategoryIcon,
    loadFallbackImage,
    getDefaultCategoryIcon,
    async init(loadMore = false) {
      try {
        this.loading = true;
        const group = this.group;
        const providers = this.selectedProviders.join(',');
        let search = this.searchText.length >= 3 ? this.searchText : '';
        let page = loadMore ? this.current_page + 1 : 1;
        const fetchMethod = this.group?.api_slug ? this.relaxS.fetchByApiSlug : this.relaxS.fetchAll;
        const params = {
          providers,
          sort: group.id ? this.sortOrder : null,
          categoryId: group.id,
          search,
          page,
          ...group?.options,
        };

        const { data } = this.group?.api_slug ? await fetchMethod(params, this.group.api_slug) : await fetchMethod(params);
        const games = loadMore ? this.games : [];
        this.games = [...games, ...data.results.data];
        this.providers = data.providers;
        this.current_page = data.results.current_page;
        this.last_page = data.results.last_page;
        this.per_page = data.results.per_page;
        this.total = data.results.total;
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    toggleProvider(provider) {
      const selected = this.selectedProviders.find((x) => x === provider.id);
      if (selected) {
        this.selectedProviders = this.selectedProviders.filter((x) => x !== provider.id);
      } else {
        this.selectedProviders.push(provider.id);
      }
      if (this.providers.length > 0) {
        this.init();
      }
    },
    clearProviders() {
      this.selectedProviders = [];
      if (this.providers.length > 0) {
        this.init();
      }
    },
    refreshSelectedProviders() {
      this.selectedProviders = [];
    },
    changeSortOrder(order) {
      if (this.sortOrder !== order) {
        this.sortOrder = order;
        this.init();
      }
    },
  },
  watch: {
    '$route.params.name': {
      handler: async function (newState) {
        this.currentSlug = newState;
        if(this.categoryOptionsFetched) {
          await this.init();
        }
      },
      deep: true,
      immediate: true,
    },
    categoryOptionsFetched: {
      handler: async function (newState) {
        if(newState)
          await this.init();
      },
    },
    searchText() {
      this.debouncedSearch();
    },
  },
};
</script>
 