<template>
  <div id="testimonials" class="flex flex-col testimonials-section min-[993px]:flex-row">
    <div class="container flex flex-col min-[993px]:flex-row">
      <div class="relative flex flex-col items-start justify-center flex-1 left-col">
        <div class="z-40 w-full content-wrapper medium big max-w-[440px]">
          <h2 class="title">Check out our <span>testimonials</span></h2>
          <div class="discription">
            <p>Magnify our satisfied customers' triumphs and big wins</p>
          </div>
          <div class="navigation-button desktop">
            <div class="arrow left testimonials-slider-prev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none">
                <path
                  d="M1 7L7 1M1 7L7 13M1 7H17"
                  stroke="#E9FF5F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
            <div class="arrow right testimonials-slider-next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none">
                <path
                  d="M17 7L11 1M17 7L11 13M17 7H1"
                  stroke="#E9FF5F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="relative flex flex-col items-end justify-center flex-1 right-col">
        <div class="swiper-outer grid">
          <div class="testimonials-slider">
            <swiper
              class="testimonials-slider"
              :direction="'vertical'"
              :slidesPerView="3"
              :loop="true"
              :speed="800"
              :spaceBetween="10"
              :navigation="{
                enabled: true,
                nextEl: '.testimonials-slider-next',
                prevEl: '.testimonials-slider-prev',
              }"
              :pagination="{
                clickable: true,
              }"
              :modules="modules">
              <swiper-slide>
                <div class="testimonials-box">
                  <div class="user-info very-small">
                    <!-- <div class="user-img"><img src="/images/home/Avatar.webp" alt=""></div> -->
                    <div class="username-and-reviewtime">
                      <h4 class="title">Mark</h4>
                      <!-- <p class="reviewtime">10 days ago</p> -->
                    </div>
                  </div>
                  <div class="discription">
                    <p>
                      “Chanced.com is the #1 free-to-play social casino. Their 24/7
                      customer service is incredible. I have recommended Chanced to all my
                      friends.”
                    </p>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="testimonials-box">
                  <div class="user-info very-small">
                    <!-- <div class="user-img"><img src="/images/home/Avatar.webp" alt=""></div> -->
                    <div class="username-and-reviewtime">
                      <h4 class="title">Lisa</h4>
                      <!-- <p class="reviewtime">10 days ago</p> -->
                    </div>
                  </div>
                  <div class="discription">
                    <p>
                      “Chanced's easy-to-navigate social casino and commitment to fair
                      play have made it an absolute hit for me. It's the perfect place to
                      enjoy social games safely.”
                    </p>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="testimonials-box">
                  <div class="user-info very-small">
                    <!-- <div class="user-img"><img src="/images/home/Avatar.webp" alt=""></div> -->
                    <div class="username-and-reviewtime">
                      <h4 class="title">Jenna</h4>
                      <!-- <p class="reviewtime">10 days ago</p> -->
                    </div>
                  </div>
                  <div class="discription">
                    <p>
                      “Phenomenal platform. Chanced is so far ahead of all social casinos it's not even close. 5 stars.”
                    </p>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="testimonials-box">
                  <div class="user-info very-small">
                    <!-- <div class="user-img"><img src="/images/home/Avatar.webp" alt=""></div> -->
                    <div class="username-and-reviewtime">
                      <h4 class="title">Christina</h4>
                      <!-- <p class="reviewtime">10 days ago</p> -->
                    </div>
                  </div>
                  <div class="discription">
                    <p>
                      “Over 500 fun games to play. The top-up feature is awesome and allows the fun to never stop! Chanced.com is officially my favorite social casino!”
                    </p>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="testimonials-box">
                  <div class="user-info very-small">
                    <!-- <div class="user-img"><img src="/images/home/Avatar.webp" alt=""></div> -->
                    <div class="username-and-reviewtime">
                      <h4 class="title">Maria</h4>
                      <!-- <p class="reviewtime">10 days ago</p> -->
                    </div>
                  </div>
                  <div class="discription">
                    <p>
                      “Chanced is really pushing their competitors to up their game. The
                      social live blackjack is so immersive it feels like I’m in Las
                      Vegas.”
                    </p>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="testimonials-box">
                  <div class="user-info very-small">
                    <!-- <div class="user-img"><img src="/images/home/Avatar.webp" alt=""></div> -->
                    <div class="username-and-reviewtime">
                      <h4 class="title">Chen</h4>
                      <!-- <p class="reviewtime">10 days ago</p> -->
                    </div>
                  </div>
                  <div class="discription">
                    <p>
                      “Incredible games. Everything is so smooth and user friendly. I love
                      the fact that Chanced has premium providers such as Pragmatic Play
                      and Hacksaw Games along with their own interactive original games! ”
                    </p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="navigation-button mobile">
          <div class="arrow left testimonials-slider-prev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none">
              <path
                d="M1 7L7 1M1 7L7 13M1 7H17"
                stroke="#E9FF5F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
          <div class="arrow right testimonials-slider-next">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none">
              <path
                d="M17 7L11 1M17 7L11 13M17 7H1"
                stroke="#E9FF5F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="bg">
      <img src="/images/home/testimonials-bg.webp" alt="" />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-creative';
import { Pagination, Navigation, Autoplay } from 'swiper';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Navigation, Autoplay],
    };
  },
  name: 'Testimonials',
};
</script>
