<template>
  <div class="roulette-table">
    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-1-18"
      v-on:click="tableCellClicked(RouletteBets.RANGE_1_TO_18)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_1_TO_18] > 0"
        :value="tableBets[RouletteBets.RANGE_1_TO_18]"></Chip>
      <span class="label">1 to 18</span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-even"
      v-on:click="tableCellClicked(RouletteBets.RANGE_EVEN)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_EVEN] > 0"
        :value="tableBets[RouletteBets.RANGE_EVEN]"></Chip>

      <span class="label">even</span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-red"
      v-on:click="tableCellClicked(RouletteBets.RANGE_RED)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_RED] > 0"
        :value="tableBets[RouletteBets.RANGE_RED]"></Chip>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-black"
      v-on:click="tableCellClicked(RouletteBets.RANGE_BLACK)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_BLACK] > 0"
        :value="tableBets[RouletteBets.RANGE_BLACK]"></Chip>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-odd"
      v-on:click="tableCellClicked(RouletteBets.RANGE_ODD)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_ODD] > 0"
        :value="tableBets[RouletteBets.RANGE_ODD]"></Chip>

      <span class="label">odd</span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-19-36"
      v-on:click="tableCellClicked(RouletteBets.RANGE_19_TO_36)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_19_TO_36] > 0"
        :value="tableBets[RouletteBets.RANGE_19_TO_36]"></Chip>

      <span class="span label">19 to 36</span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-first-12"
      v-on:click="tableCellClicked(RouletteBets.RANGE_FIRST_12)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_FIRST_12] > 0"
        :value="tableBets[RouletteBets.RANGE_FIRST_12]"></Chip>

      <span class="span label"> 1 to 12 </span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-second-12"
      v-on:click="tableCellClicked(RouletteBets.RANGE_SECOND_12)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_SECOND_12] > 0"
        :value="tableBets[RouletteBets.RANGE_SECOND_12]"></Chip>

      <span class="label"> 13 to 24 </span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-bottom slot-range-third-12"
      v-on:click="tableCellClicked(RouletteBets.RANGE_THIRD_12)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_THIRD_12] > 0"
        :value="tableBets[RouletteBets.RANGE_THIRD_12]"></Chip>

      <span class="label"> 25 to 36 </span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-row-1"
      v-on:click="tableCellClicked(RouletteBets.RANGE_ROW_ONE)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_ROW_ONE] > 0"
        :value="tableBets[RouletteBets.RANGE_ROW_ONE]"></Chip>

      <span class="label">2 to 1</span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-row-2"
      v-on:click="tableCellClicked(RouletteBets.RANGE_ROW_TWO)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_ROW_TWO] > 0"
        :value="tableBets[RouletteBets.RANGE_ROW_TWO]"></Chip>

      <span class="label">2 to 1</span>
    </button>

    <button
      :class="{ enabled: !locked }"
      class="slot slot-range-row-3"
      v-on:click="tableCellClicked(RouletteBets.RANGE_ROW_THREE)">
      <Chip
        v-if="tableBets[RouletteBets.RANGE_ROW_THREE] > 0"
        :value="tableBets[RouletteBets.RANGE_ROW_THREE]"></Chip>

      <span class="label">2 to 1</span>
    </button>

    <div class="numbered-slots">
      <div class="slot-zero">
        <button
          class="slot slot-green"
          v-on:click="tableCellClicked(RouletteBets.SLOT_00)">
          <Chip
            v-if="tableBets[RouletteBets.SLOT_00] > 0"
            :value="tableBets[RouletteBets.SLOT_00]"></Chip>

          <span class="label">00</span>
        </button>

        <button
          class="slot slot-green"
          v-on:click="tableCellClicked(RouletteBets.SLOT_0)">
          <Chip
            v-if="tableBets[RouletteBets.SLOT_0] > 0"
            :value="tableBets[RouletteBets.SLOT_0]"></Chip>

          <span class="label">0</span>
        </button>
      </div>

      <button
        v-for="slot in SLOT_NUMBERS"
        class="slot slot-group"
        :class="{
          enabled: !locked,
          'slot-red': slotColor(slot) == ROULETTE_COLORS.RED,
          'slot-black': slotColor(slot) == ROULETTE_COLORS.BLACK,

          'slot-even': slot % 2 === 0 && slot > 0,
          'slot-odd': slot % 2 !== 0,

          'slot-row-1': slot % 3 === 0,
          'slot-row-2': slot % 3 === 2,
          'slot-row-3': slot % 3 === 1,

          'slot-1-to-18': slot >= 1 && slot <= 18,
          'slot-19-to-36': slot >= 19 && slot <= 36,

          'slot-first-12': slot >= 1 && slot <= 12,
          'slot-second-12': slot >= 13 && slot <= 24,
          'slot-third-12': slot >= 25 && slot <= 37,
        }"
        :key="slot + 1"
        v-on:click="tableCellClicked(slot)">
        <Chip v-if="tableBets[slot] > 0" :value="tableBets[slot]"></Chip>

        <span class="label">{{ slot }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { GetSlotColor, RouletteColors } from './RouletteLayouts.js';
import { mapActions, mapGetters } from 'vuex';
import Chip from './Chip.vue';
import { rouletteStore } from '@/store/pinia/rouletteStore';

const RouletteBets = {
  SLOT_0: 0,
  SLOT_1: 1,
  SLOT_2: 2,
  SLOT_3: 3,
  SLOT_4: 4,
  SLOT_5: 5,
  SLOT_6: 6,
  SLOT_7: 7,
  SLOT_8: 8,
  SLOT_9: 9,
  //
  SLOT_10: 10,
  SLOT_11: 11,
  SLOT_12: 12,
  SLOT_13: 13,
  SLOT_14: 14,
  SLOT_15: 15,
  SLOT_16: 16,
  SLOT_17: 17,
  SLOT_18: 18,
  SLOT_19: 19,
  //
  SLOT_20: 20,
  SLOT_21: 21,
  SLOT_22: 22,
  SLOT_23: 23,
  SLOT_24: 24,
  SLOT_25: 25,
  SLOT_26: 26,
  SLOT_27: 27,
  SLOT_28: 28,
  SLOT_29: 29,
  //
  SLOT_30: 30,
  SLOT_31: 31,
  SLOT_32: 32,
  SLOT_33: 33,
  SLOT_34: 34,
  SLOT_35: 35,
  SLOT_36: 36,
  //
  SLOT_00: 37,
  SLOT_000: 38,
  //
  RANGE_ODD: 39,
  RANGE_EVEN: 40,
  RANGE_BLACK: 41,
  RANGE_RED: 42,
  //
  //
  RANGE_ROW_ONE: 43,
  RANGE_ROW_TWO: 44,
  RANGE_ROW_THREE: 45,
  //
  RANGE_FIRST_12: 46,
  RANGE_SECOND_12: 47,
  RANGE_THIRD_12: 48,
  //
  RANGE_1_TO_18: 49,
  RANGE_19_TO_36: 50,
};

export default {
  name: 'RouletteTable',
  components: {
    Chip,
  },
  setup() {
    const rouletteS = rouletteStore();
    return { rouletteS };
  },
  data: () => ({
    chips: {},
  }),
  muted: false,
  props: {
    locked: {
      type: Boolean,
      default: false,
    },
    removeChips: {
      type: Boolean,
      default: false,
    },
    viewOnly: {
      default: false,
    },
    selected: {},
    bets: {
      default: () => [],
    },
  },
  methods: {
    slotColor: (i) => GetSlotColor(i),
    ...mapActions('Roulette', ['placeBets', 'removeBets']),
    tableCellClicked(slot) {
      const selected = this.rouletteS.selectedChip;
      if (!this.locked || this.viewOnly) {
        if (this.removingBets) {
          this.$emit('removingChips', { slot });
          this.removeBets(slot);
        }
        this.placeBets({ slot, chips: selected });
        this.$emit('placingChips', { slot });
      }
    },
  },
  computed: {
    ROULETTE_COLORS: () => RouletteColors,
    SLOT_NUMBERS: () => {
      return [
        ...Array.from({ length: 12 }, (x, i) => (i + 1) * 3),
        ...Array.from({ length: 12 }, (x, i) => (i + 1) * 3 - 1),
        ...Array.from({ length: 12 }, (x, i) => (i + 1) * 3 - 2),
      ];
    },
    RouletteBets: () => RouletteBets,
    ...mapGetters('Roulette', ['removingBets', 'placedBets', 'removingBets']),
    tableBets() {
      if (this.viewOnly) {
        return this.bets;
      }

      return this.placedBets;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables';

@mixin on-hover {
  border-color: gold;
}

.roulette-table {
  width: 100%;
  flex: 1 1 100%;
  position: relative;
  display: grid;

  grid-template-columns: repeat(14, 1fr);
  grid-row-gap: 2px;
  grid-column-gap: 2px;
  border-radius: 5px;

  .numbered-slots {
    grid-column: 1 / span 13;
    grid-row: 1 / span 3;

    display: grid;
    grid-template-columns: repeat(13, 1fr);

    .slot {
      min-height: 3.25rem;
      line-height: 1rem;
    }
  }

  .slot-zero {
    grid-row: 1 / span 3;

    display: flex;
    flex-flow: column nowrap;

    .slot {
      flex: 1 1 50%;
      max-height: none;

      &:first-child {
        border-radius: 0.5rem 0 0 0;
      }

      &:last-child {
        border-radius: 0 0 0 0.5rem;
      }
    }
  }

  .slot {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;

    position: relative;

    padding: 0.5rem 0.25rem;

    border: 0.1rem solid white;
    background-color: $table-green;

    color: #ffffff;
    font-size: 1rem;

    cursor: pointer;

    transition: all 150ms ease-out;

    min-height: 2.25rem;

    &.slot-range-bottom {
      min-height: 2rem;
      max-height: 3rem;
    }

    .label {
      text-align: center;
      user-select: none;
      font-size: 0.75rem;
    }

    .chip {
      z-index: 1;
    }

    .chip ~ .label {
      align-self: flex-end;
      justify-self: flex-end;

      font-size: 0.75rem;
      position: absolute;
      bottom: 0.1rem;
      right: 0.2rem;
    }

    &.slot-red {
      &:hover {
        @include on-hover;
        box-shadow:
          0.1rem 0.1rem 0 0 gold,
          0.1rem -0.1rem 0 0 gold,
          -0.1rem 0.1rem 0 0 gold,
          -0.1rem -0.1rem 0 0 gold;
        z-index: 1;
      }

      background-color: $slot-red !important;
    }

    &.slot-green {
      &:hover {
        @include on-hover;
        box-shadow:
          0.1rem 0.1rem 0 0 gold,
          0.1rem -0.1rem 0 0 gold,
          -0.1rem 0.1rem 0 0 gold,
          -0.1rem -0.1rem 0 0 gold;
        z-index: 1;
      }
    }

    &.slot-black {
      &:hover {
        @include on-hover;
        box-shadow:
          0.1rem 0.1rem 0 0 gold,
          0.1rem -0.1rem 0 0 gold,
          -0.1rem 0.1rem 0 0 gold,
          -0.1rem -0.1rem 0 0 gold;
        z-index: 1;
      }

      background-color: $slot-black;
    }

    &.slot-range-1-18 {
      grid-column: 2 / span 2;
      grid-row: 5;
      border-radius: 5px;
    }

    &.slot-range-19-36 {
      grid-column: 12 / span 2;
      grid-row: 5;
      border-radius: 5px;
    }

    &.slot-range-even {
      grid-column: 4 / span 2;
      grid-row: 5;
    }

    &.slot-range-red {
      background-color: $slot-red !important;
      grid-column: 6 / span 2;
      grid-row: 5;
    }

    &.slot-range-black {
      background-color: $slot-black;
      grid-column: 8 / span 2;
      grid-row: 5;
    }

    &.slot-range-odd {
      grid-column: 10 / span 2;
      grid-row: 5;
    }

    &.slot-range-first-12,
    &.slot-range-second-12,
    &.slot-range-third-12 {
      grid-row: 4;
    }

    &.slot-range-first-12 {
      grid-column: 2 / span 4;
    }

    &.slot-range-second-12 {
      grid-column: 6 / span 4;
    }

    &.slot-range-third-12 {
      grid-column: 10 / span 4;
    }

    &.slot-range-row-1 {
      border-radius: 5px;
    }

    &.slot-range-row-3 {
      border-radius: 5px;
    }

    &.enabled {
      &.slot-range-odd:hover ~ .numbered-slots > .slot-odd,
      &.slot-range-even:hover ~ .numbered-slots > .slot-even,
      &.slot-range-black:hover ~ .numbered-slots > .slot-black,
      &.slot-range-red:hover ~ .numbered-slots > .slot-red,
      &.slot-range-first-12:hover ~ .numbered-slots > .slot-group.slot-first-12,
      &.slot-range-second-12:hover ~ .numbered-slots > .slot-group.slot-second-12,
      &.slot-range-third-12:hover ~ .numbered-slots > .slot-group.slot-third-12,
      &.slot-range-1-18:hover ~ .numbered-slots > .slot-1-to-18,
      &.slot-range-19-36:hover ~ .numbered-slots > .slot-19-to-36,
      &.slot-range-row-1:hover ~ .numbered-slots > .slot-row-1,
      &.slot-range-row-2:hover ~ .numbered-slots > .slot-row-2,
      &.slot-range-row-3:hover ~ .numbered-slots > .slot-row-3 {
        @include on-hover;
      }

      &.slot-range-odd:hover ~ .numbered-slots > :not(.slot-odd),
      &.slot-range-even:hover ~ .numbered-slots > :not(.slot-even),
      &.slot-range-black:hover ~ .numbered-slots > :not(.slot-black),
      &.slot-range-red:hover ~ .numbered-slots > :not(.slot-red),
      &.slot-range-first-12:hover ~ .numbered-slots > :not(.slot-first-12),
      &.slot-range-second-12:hover ~ .numbered-slots > :not(.slot-second-12),
      &.slot-range-third-12:hover ~ .numbered-slots > :not(.slot-third-12),
      &.slot-range-1-18:hover ~ .numbered-slots > :not(.slot-1-to-18),
      &.slot-range-19-36:hover ~ .numbered-slots > :not(.slot-19-to-36),
      &.slot-range-row-1:hover ~ .numbered-slots > :not(.slot-row-1),
      &.slot-range-row-2:hover ~ .numbered-slots > :not(.slot-row-2),
      &.slot-range-row-3:hover ~ .numbered-slots > :not(.slot-row-3) {
        opacity: 0.35 !important;
      }
    }
  }
}
</style>

<style lang="scss">
@import 'variables';

.slot {
  border-color: #26444f !important;
  background-color: #26444f !important;
  border-radius: 5px;

  &.slot-black,
  &.slot-range-black {
    background-color: #0d1f28 !important;
  }

  &.slot-green {
    background-color: #1ee563 !important;
  }
}
</style>
