<template>
  <div class="flex w-full flex-col items-center">
    <div class="flex w-full max-w-[1200px] flex-col">
      <div
        class="relative flex w-full items-center justify-between gap-2 bg-secondary/30 px-4 py-4 md:px-6 max-[575px]:pb-0">
        <h1 class="py-4 text-2xl font-bold text-slate-300">Challenges</h1>
        <div class="relative flex items-center justify-end w-full gap-2 text-slate-300">
          <div class="flex flex-row items-center gap-1.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="hidden w-5 h-5 rotate-90 md:block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
            </svg>
            <span class="hidden text-sm md:block"> Sort By </span>
            <chalenge-sort :sort="sortOrder" :change-sort-order="changeSortOrder" />
          </div>
        </div>
      </div>
      <div class="px-2 py-2 md:px-6 md:py-4 max-[575px]:pt-0">
        <div class="flex h-5 items-center justify-center" v-if="loading">
          <svg
            class="h-10"
            id="L4"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enable-background="new 0 0 0 0"
            xml:space="preserve">
            <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.1" />
            </circle>
            <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.2" />
            </circle>
            <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.3" />
            </circle>
          </svg>
        </div>
        <div class="flex w-full">
          <div class="flex w-full flex-row items-start">
            <perfect-scrollbar
              ref="hScrollbar"
              class="no-scrollbar flex overflow-x-scroll rounded-full bg-secondary">
              <div
                class="ovesrflow-x-scroll grid w-full auto-cols-max grid-flow-col grid-cols-none grid-rows-1 flex-nowrap gap-0.5 sm:gap-6 p-2">
                <router-link
                  class="flex h-10 w-full min-w-fit items-center justify-center space-x-2 rounded-full px-4 py-2.5 text-sm font-medium leading-5 text-white focus:outline-none"
                  :class="[
                    selected === 'all'
                      ? 'bg-accent shadow-btn'
                      : 'text-white hover:bg-secondary-light/70 hover:text-white',
                  ]"
                  :to="'/challenges/all'">
                  <span class="flex w-full items-center justify-center gap-2">
                    All Challenges
                  </span>
                </router-link>
                <router-link
                  class="flex h-10 w-full min-w-fit items-center justify-center space-x-2 rounded-full px-4 py-2.5 text-sm font-medium leading-5 text-white focus:outline-none"
                  :class="[
                    selected === 'completed'
                      ? 'bg-accent shadow-btn'
                      : 'text-white hover:bg-secondary-light/70 hover:text-white',
                  ]"
                  :to="'/challenges/completed'">
                  <span class="flex w-full items-center justify-center gap-2">
                    Completed
                  </span>
                </router-link>
                <router-link
                  class="flex h-10 w-full min-w-fit items-center justify-center space-x-2 rounded-full px-4 py-2.5 text-sm font-medium leading-5 text-white focus:outline-none"
                  :class="[
                    selected === 'mine'
                      ? 'bg-accent shadow-btn'
                      : 'text-white hover:bg-secondary-light/70 hover:text-white',
                  ]"
                  :to="'/challenges/mine'">
                  <span class="flex w-full items-center justify-center gap-2">
                    Mine
                  </span>
                </router-link>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
        <div
          class="grid-rows-auto mt-4 grid w-full gap-3 md:gap-4 grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          <router-link
            class="flex flex-col rounded-md shadow-md transition delay-0 duration-150 ease-in-out hover:rotate-1 hover:scale-105 overflow-hidden"
            v-for="(game, i) in games"
            :key="i"
            :to="getGameLink(game, this.providers[game.all_provider_id].slug)">
            <img
              :src="getTileImage(game, this.providers[game.all_provider_id].slug)"
              @error="loadFallbackImage"
              :data-fallback="getDefaultTileImage(game, this.providers[game.all_provider_id].slug)"
              alt="Game Tile"
              v-tooltip="game.title"
              class="flex w-full flex-col items-center" />
            <div class="description sm:p-4 bg-secondary p-2">
              <p class="mb-1 text-white font-bold">{{ game.title }}</p>
              <p class="mb-1 text-slate-300/70 text-sm">
                First to hit {{ game.multiplier }}× with min {{ game.min_bet }}
                <img
                  :src="getcoinimage(game.bet_currency)"
                  alt="coin"
                  class="w-4 inline" />
                play
              </p>
              <div
                class="flex flex-col gap-2 mt-[7px] pt-[7px] border-t border-line-color">
                <div class="flex flex-wrap justify-between w-full">
                  <p class="text-white font-bold max-[400px]:text-sm">Prize</p>
                  <p class="text-slate-300/70 max-[400px]:text-sm">
                    {{ game.win_amount }}
                    <img
                      :src="getcoinimage(game.win_currency)"
                      alt="coin"
                      class="w-4 inline" />
                  </p>
                </div>
                <div v-if="game.claimed_by" class="flex flex-wrap justify-between w-full">
                  <p class="text-white font-bold">Claimed By</p>
                  <p class="text-slate-300/70 mb-1">{{ game.claimed_username }}</p>
                </div>
                <div v-if="game.username" class="flex flex-wrap justify-between w-full">
                  <p class="text-white font-bold">By</p>
                  <p class="text-slate-300/70 mb-1">{{ game.username }}</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div
          class="flex flex-col items-center justify-center py-12 text-slate-300/70"
          v-if="games.length === 0 && !loading">
          <svg width="96" height="96" fill="none" class="mx-auto mb-6 text-slate-300/70">
            <path
              d="M36 28.024A18.05 18.05 0 0025.022 39M59.999 28.024A18.05 18.05 0 0170.975 39"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <ellipse cx="37.5" cy="43.5" rx="4.5" ry="7.5" fill="currentColor"></ellipse>
            <ellipse cx="58.5" cy="43.5" rx="4.5" ry="7.5" fill="currentColor"></ellipse>
            <path
              d="M24.673 75.42a9.003 9.003 0 008.879 5.563m-8.88-5.562A8.973 8.973 0 0124 72c0-7.97 9-18 9-18s9 10.03 9 18a9 9 0 01-8.448 8.983m-8.88-5.562C16.919 68.817 12 58.983 12 48c0-19.882 16.118-36 36-36s36 16.118 36 36-16.118 36-36 36a35.877 35.877 0 01-14.448-3.017"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              d="M41.997 71.75A14.94 14.94 0 0148 70.5c2.399 0 4.658.56 6.661 1.556a3 3 0 003.999-4.066 12 12 0 00-10.662-6.49 11.955 11.955 0 00-7.974 3.032c1.11 2.37 1.917 4.876 1.972 7.217z"
              fill="currentColor"></path>
          </svg>
          <span>Sorry! There are no Challenges</span>
        </div>
        <div
          v-if="games.length > 0"
          class="mt-8 flex flex-col items-center justify-center">
          <span>Displaying {{ games.length }} of {{ total }} challenges</span>
          <span class="italic text-sm mt-6">*Please note that if you use a bonus buy, the bonus buy amount will be factored into the challenge. This means you would need to hit 1000x the bonus buy amount to qualify.</span>
          <span class="flex h-5 items-center justify-center" v-if="loading">
            <svg
              class="h-10"
              id="L4"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve">
              <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1" />
              </circle>
              <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2" />
              </circle>
              <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3" />
              </circle>
            </svg>
          </span>
          <button
            v-if="!loading && current_page < last_page"
            v-on:click="init(true)"
            :disabled="loading"
            class="mt-4 flex items-center justify-center rounded-xl bg-[#1EE563] p-3 font-semibold text-[#111F29] hover:opacity-70">
            Load More
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { challengeStore } from '@/store/pinia/challengeStore';

import ChalengeSort from '@/components/Challenges/SortFilters.vue';
import sc from '../../assets/coins/cash.png';
import gc from '../../assets/coins/gold.png';
import {getDefaultTileImage, getGameLink, getTileImage, loadFallbackImage} from "@/utils";

export default {
  name: 'Challenges',
  components: {
    ChalengeSort,
  },

  setup() {
    return { challenges: challengeStore() };
  },
  computed: {
    noResult() {
      return this.searchText.length > 0 && !this.loading && this.games.length === 0;
    },
  },
  async created() {
    await this.init();
  },
  data() {
    return {
      loading: false,
      games: [],
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
      selected: 'all',
      providers: [],
      sortOrder: 'multiplier',
      sortType: 'desc',
    };
  },
  methods: {
    getGameLink,
    loadFallbackImage,
    getDefaultTileImage,
    getTileImage,
    async init(loadMore = false) {
      try {
        let page = loadMore ? this.current_page + 1 : 1;
        const { data } = await this.challenges.fetchAll({
          type: this.$route.params.type,
          sort: this.sortOrder,
          sorttype: this.sortType,
          page,
        });

        this.selected = this.$route.params.type;
        const games = loadMore ? this.games : [];
        this.games = [...games, ...data.results.data];
        this.providers = data.providers;
        this.current_page = data.results.current_page;
        this.last_page = data.results.last_page;
        this.per_page = data.results.per_page;
        this.total = data.results.total;
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    getIdentifierName(gameName) {
      return gameName.split(':')[1];
    },
    getcoinimage(coin) {
      return coin == 'SC' ? sc : gc;
    },
    reseteverything() {
      this.loading = false;
      this.games = [];
      this.current_page = 1;
      this.last_page = 0;
      this.per_page = 0;
      this.total = 0;
    },
    changeSortOrder(order) {
      if (this.sortOrder == order) {
        if (this.sortType == 'desc') this.sortType = 'asc';
        else this.sortType = 'desc';
      }

      this.sortOrder = order;

      this.init();
    },
  },
  watch: {
    '$route.params.type': {
      async handler() {
        this.reseteverything();
        await this.init();
        //auto launch demo mode on game started
        //await this.launchGame(true, true)
      },
      // force eager callback execution
      immediate: true,
    },
  },
};
</script>
