<template>
  <router-view></router-view>
  <div class="p-0 md:p-8 h-auto md:min-h-[auto] flex flex-row justify-center">
    <div class="w-full max-w-[1200px]">
      <div
        class="flex flex-col-reverse xl:flex-row justify-center h-auto md:min-h-[auto]">
        <!-- Side Menu -->
        <div
          class="w-full md:w-full md:px-3 px-2 py-2 min-w-[300px] xl:w-[300px] md:py-3 pb-4 rounded-0 md:rounded-tl-lg bg-side-color rounded-bl-xl rounded-tl-xl">
          <!-- <span>Config here</span> -->
          <slots-bets
            :currentState="currentState"
            :playerProfit="winning"
            :playHandler="play" />
          <game-actions
            :pre-extras="gameActions"
            class="bg-tertiary rounded-br-xl rounded-bl-xl !block md:!hidden mt-4" />
        </div>
        <div
          class="bg-tertiary flex flex-col items-center md:w-full min-h-[300px] xl:min-h-[400px] justify-between rounded-br-lg rounded-tr-lg">
          <div
            class="h-full w-full flex-1 bg-primary/50 md:px-6 py-4 flex flex-col items-center rounded-br-lg">
            <!-- <span>Grid Here</span> -->
            <slots-grid
              :currentState="currentState"
              :reels="reels"
              :spins="spins"
              :lines="lines"
              :stateHandler="changeState"
              :infoHandler="setInfo"
              :winning="winning"
              :bonusRounds="bonusRounds"
              :currentRound="currentRound"
              :soundHandler="playSound"
              :currency="currency" />
          </div>
          <game-actions
            :game-data="{ name: 'Slots', id: null }"
            :pre-extras="gameActions"
            class="bg-tertiary rounded-br-lg flex-none" />
        </div>
      </div>
    </div>
  </div>
  <!-- <span class="bg-slate-500 w-full">Game History Table Here</span> -->
  <game-history-table class="hidden md:flex" />
</template>

<script>
import { defaultConvertor } from '@/lib/rsgp';
import axios from '@/lib/myAxios';
import { mapActions, mapGetters } from 'vuex';
import SlotsGrid from './SlotsGrid.vue';
import SlotsBets from './SlotsBets.vue';
import SlotsWild from './Symbols/SlotsWild.vue';
import SlotsFairness from './SlotsFairness.vue';
import { betSound, spinSound } from './sounds';
import GameHistoryTable from '../GameHistory/BetsHistory.vue';
import GameActions from '../Shared/GameActions.vue';
export default {
  components: {
    SlotsGrid,
    SlotsBets,
    SlotsWild,
    SlotsFairness,
    GameHistoryTable,
    GameActions,
  },

  props: {},
  data: () => ({
    showFairness: false,
    muted: false,
    spins: [],
    currentState: 'finished',
    betAmount: 0,
    multiplier: 0,
    bonusRounds: 0,
    currentRound: 0,
    currency: 'BTC',
    gameId: 0,
    limits: {
      maxPayout: 0,
    },
    gameActions: [
      ['/casino/slots/rules', 'Rules'],
      // ['/casino/slots/fairness', 'Fairness'],
    ],
    reels: [
      [
        4, 2, 8, 9, 5, 7, 1, 8, 2, 6, 11, 8, 12, 2, 5, 0, 2, 7, 6, 10, 3, 2, 4, 10, 1, 8,
        3, 11, 9, 8,
      ],
      [
        2, 5, 9, 2, 7, 0, 2, 5, 4, 11, 2, 9, 10, 3, 5, 6, 8, 3, 11, 12, 7, 1, 8, 10, 6, 5,
        2, 4, 5, 1,
      ],
      [
        10, 11, 4, 8, 9, 10, 5, 0, 2, 8, 9, 11, 5, 3, 7, 8, 1, 7, 10, 3, 11, 8, 6, 2, 11,
        1, 10, 4, 11, 12,
      ],
      [
        10, 11, 4, 7, 6, 5, 3, 8, 1, 5, 9, 2, 4, 5, 8, 1, 7, 2, 12, 8, 10, 9, 5, 1, 11, 7,
        6, 11, 7, 0,
      ],
      [
        9, 8, 5, 6, 10, 8, 0, 10, 11, 3, 8, 4, 7, 5, 12, 11, 7, 1, 2, 7, 1, 8, 4, 2, 9,
        11, 0, 5, 6, 2, 10, 4, 5, 8, 9, 2, 1, 11, 3, 7, 11,
      ],
    ],
    lines: [
      [1, 1, 1, 1, 1],
      [0, 0, 0, 0, 0],
      [2, 2, 2, 2, 2],

      [0, 1, 2, 1, 0],
      [2, 1, 0, 1, 2],

      [0, 0, 1, 2, 2],
      [2, 2, 1, 0, 0],

      [1, 0, 1, 2, 1],
      [1, 2, 1, 0, 1],

      [0, 1, 1, 1, 2],
      [2, 1, 1, 1, 0],

      [1, 0, 0, 1, 2],
      [1, 2, 2, 1, 0],

      [1, 1, 0, 1, 2],
      [1, 1, 2, 1, 0],

      [0, 0, 1, 2, 1],
      [2, 2, 1, 0, 1],

      [1, 0, 1, 2, 2],
      [1, 2, 1, 0, 0],

      [0, 0, 0, 1, 2],
    ],
  }),
  created() {
    this.fetchLimits();
  },
  computed: {
    ...mapGetters(['me', 'userBalance']),
    ...mapGetters('Slots', ['clientSeed']),
    ...mapGetters('Sound', ['isSoundMuted']),
    winning() {
      return (parseFloat(this.betAmount) * this.multiplier).toFixed(8);
    },
  },
  methods: {
    ...mapActions('Slots', ['updateNonce', 'refreshSeedInfo']),
    ...mapActions('Sound', ['toggleSound']),
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.play();
      }
    },
    play(wager, numberOfLines, currency) {
      this.playSound(betSound);
      this.currentState = 'inprogress';

      try {
        axios
        .post(`slots`, { wager: wager, numberOfLines: numberOfLines })
        .then((res) => res.data)
        .then((state) => {
          this.updateNonce();
          this.playSound(spinSound);
          this.gameId = state.game;
          this.spins = state.spins;
          this.betAmount = wager;
          this.currency = currency;
        })
        .catch(({ data, status }) => {
          this.currentState = 'failed';
          if (status === 403) {
            window.swal('Uh-oh', 'Not allowed to play: ' + ((typeof data.error != 'undefined')?data.error:''), 'error');
          } else {
            if ((typeof data.error != 'undefined') && data.error === 'Unauthenticated.') {
              this.$router.replace('?tab=login');
            } else {
              window.swal(
                'Uh-oh',
                'Something went wrong when placing your play: ' +((typeof data.error != 'undefined')?data.error:''),
                'error',
              );
            }
          }
        });
      } catch(error) {
        console.log("issue with slots");
      }
    },
    changeState(newState) {
      if (newState === 'finished') {
        try {
          axios.post(`slots/finish`, { gameId: this.gameId }).catch(() => {});
        } catch(error) {
          console.log("slots finished failed");
        }
      }
      this.currentState = newState;
    },
    setInfo(multiplier, bonusRounds, currentRound) {
      this.multiplier = multiplier;
      this.bonusRounds = bonusRounds;
      this.currentRound = currentRound;
    },
    fetchLimits() {
      try
      {
        axios
        .get('slots/limits')
        .then(({ data }) => {
          this.limits = data;
        })
        .catch((e) => {
          const { data, status } = e;
          if ((typeof data.error != 'undefined') && data.error === 'Unauthenticated.') {
            //ignore
            //this.$router.replace('?tab=login')
          } else {
            window.swal(
              'Uh-oh',
              'Something went wrong when fetching limits: ' + ((typeof data.error != 'undefined') ?data.error:''),
              'error',
            );
          }
        });
      } catch(error) {
        console.log("issue while fetching limits");
      }
    },
  },
};
</script>
