<template>
  <div class="py-2 md:py-4">
    <verify-account
      data-verifid="{me.verification_id}"
      context="claim your Daily Bonus"
      v-if="me.verification_id === null"
      :reverification="me.prev_verification_id" />
    <div v-else>
      <form @submit.stop.prevent="() => claim()">

        <h2 class="font-semibold text-md md:text-xl text-center mb-3">{{me.username}}, build your Daily Bonus Streak!</h2>
        <div class="flex flex-col text-secondary-text/60 justify-center items-center">
          <span class="text-center">Claim your Daily Bonus to keep your streak alive and unlock even better rewards.</span>
        </div>

        <div class="py-[20px] flex justify-around items-center max-w-[500px] mx-auto max-md:flex-col max-md:mb-5">
          <div class="w-1/2 max-md:w-full max-md:mb-5">
            <img src="@/assets/coins/300.png" alt="coin">
          </div>
          <div class="w-1/2 max-md:w-full">
            <div class="flex items-center gap-x-2 justify-center mb-2">
              <span class="font-bold text-md md:text-xl text-center">{{ parseFloat(upcomingbonus.stage)+1 }}</span>
              <span><img src="/images/fireimg.png" alt="streak image" class="max-w-[40px]"></span>
            </div>
            <p class="text-center mb-4">Day Streak</p>
            <div class="bg-white/20 py-2 px-4 rounded-[20px] flex justify-center gap-x-3 w-fit mx-auto font-bold text-[18px] md:text-xl">
              
              <span class="text-[#BB984B]">GC {{  parseFloat(upcomingbonus.gc).toLocaleString()  }}</span>
              <span>/</span>
              <span class="text-[#3ABF63]">SC {{  parseFloat(upcomingbonus.sc).toLocaleString()  }}</span>
            </div>
          </div>
        </div>

        <div class="border border-2 border-[#f02665] p-[20px] rounded-lg">
          <div class="days flex justify-around max-md:flex-col max-md:gap-y-5">
            <div class="day text-center max-md:flex max-md:items-center max-md:gap-x-2.5" v-for="(day, key) in bonuses" :class="(key==upcomingbonus.stage?'active':(upcomingbonus.stage>key)?'done':'') "  >
              <h4 class="font-semibold text-[18px]" :class="[(key==upcomingbonus.stage)?'text-white':'text-white/50']">Day</h4>
              <p class="font-semibold text-[16px] mb-5 max-md:mb-0" :class="[(key==upcomingbonus.stage)?'text-white':'text-white/50']" >{{(parseFloat(key)+1)}}</p>
              <div class="status w-[50px] h-[50px] mx-auto border-[3px] border-solid  rounded-full flex justify-center items-center mb-2" :class="[(key==upcomingbonus.stage)?'border-white':(upcomingbonus.stage>key)?'border-onprimary opacity-50':'border-white/50']"  v-if="key != (Object.keys(bonuses).length - 1)">
                <svg class="w-[30px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" v-if="(upcomingbonus.stage>key)"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" fill="#f02665"/></svg>
              </div>
              <div class="status w-[50px] h-[50px] mx-auto flex justify-center items-center mb-2" v-else>
                <img src="@/assets/coins/300.png" alt="coin">
              </div>
              <div>
              <p class="font-semibold text-[16px] mb-1" :class="[key==upcomingbonus.stage?'text-white':'text-white/50']">GC {{ parseFloat(day.gc).toLocaleString()}}</p>
              <p class="font-semibold text-[16px]" :class="[key==upcomingbonus.stage?'text-white':'text-white/50']">SC {{ parseFloat(day.sc).toLocaleString()}}</p>
            </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col text-secondary-text/60 justify-center items-center mt-5">
          <span class="text-center">Next Bonus Claim Available in:</span>
        </div>

        <div
          class="flex flex-row bg-tertsiary justify-center items-center font-bold text-3xl px-4 py-2 text-slate-300 rounded-2xl">
          <div
            class="flex flex-col items-center bg-secondary rounded-3xl m-4 p-4 min-w-[90px] max-[400px]:m-2 max-[400px]:min-w-[80px]">
            {{ remainingTime.hours }}
            <span class="text-xs text-secondary-text mt-2">HOURS</span>
          </div>
          <div
            class="flex flex-col items-center bg-secondary rounded-3xl m-4 p-4 min-w-[90px] max-[400px]:m-2 max-[400px]:min-w-[80px]">
            {{ remainingTime.minutes }}
            <span class="text-xs text-secondary-text mt-2">MINUTES</span>
          </div>
          <div
            class="flex flex-col items-center bg-secondary rounded-3xl m-4 p-4 min-w-[90px] max-[400px]:m-2 max-[400px]:min-w-[80px]">
            {{ remainingTime.seconds }}
            <span class="text-xs text-secondary-text mt-2">SECONDS</span>
          </div>
        </div>
        <div class="flex flex-row gap-8 max-[480px]:gap-4">
           
 
        </div>
        <div class="flex flex-row justify-center mt-4" v-if="me.email_verified">
          <button
            :disabled="!canClaim || isOnBreak"
            class="btn-bet hover:opacity-75 disabled:opacity-30 text-white rounded-2xl px-4 py-3 my-4 w-1/2 -align-center max-[480px]:w-auto">
            {{ loading ? 'Please wait...' : 'Claim Daily Bonus' }}
          </button>
        </div>

        <div
          v-if="!me.email_verified"
          class="text-sm text-slate-300 border-slate-300 border-2 border-dashed mt-6 p-4 rounded-lg">
          <span> Please verify you email in order to proceed with your redemption.</span>
          <div class="text-text-dim-color hover:text-text-dim-color/70 font-bold">
            <span class="cursor-pointer" v-on:click="$router.push('/settings/general')"
              >Verify now</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { dailyBonusStore } from '@/store/pinia/dailyBonusStore';
import { getBalanceIcon } from '@/lib/icons';
import { mapGetters } from 'vuex';
import VerifyAccount from '@/components/Bank/VerifyAccount.vue';
import { getErrorCodeMessage } from '@/utils';
export default {
  name: 'Daily',
  components: { VerifyAccount },
  props: {
    isOnBreak: {
      default: true,
    }
  },
  setup() {
    const dailyBonusS = dailyBonusStore();
     const bonuses = computed(() => dailyBonusS.getAvailableDailbonus);
    return { dailyBonusS, getBalanceIcon,bonuses   };
  },
  data() {
    return {
      lastClaim: null,
      loading: true,
      remainingTime: {
        hours: '00',
        minutes: '00',
        seconds: '00',
      },
      interval: null,
      upcomingbonus : {
        stage :0,
        gc:5000,
        sc:0.3
      }
    };
  },
  async created() {
    await this.fetch();
    this.startTimer();
  },
  computed: {
    ...mapGetters(['me']),
    canClaim() {
      return !this.interval && !this.loading && this.me.email_verified;
    },
  },

  methods: {
    startTimer() {
      let nextClaimTime = new Date();
      nextClaimTime.setHours(nextClaimTime.getHours() + this.remainingTime.hours);
      nextClaimTime.setMinutes(nextClaimTime.getMinutes() + this.remainingTime.minutes);
      nextClaimTime.setSeconds(nextClaimTime.getSeconds() + this.remainingTime.seconds);
      if (this.interval != null) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        const currentTime = new Date();
        let duration = nextClaimTime.getTime() - currentTime.getTime();
        if (duration <= 0 && this.interval) {
          clearInterval(this.interval);
          this.remainingTime = {
            hours: '00',
            minutes: '00',
            seconds: '00',
          };
          this.interval = null;
        }

        let milliseconds = parseInt(((duration % 1000) / 100).toString());
        let seconds = parseInt(((duration / 1000) % 60).toString());
        let minutes = parseInt(((duration / (1000 * 60)) % 60).toString());
        let hours = parseInt(((duration / (1000 * 60 * 60)) % 24).toString());
        hours = Math.max(hours, 0);
        minutes = Math.max(minutes, 0);
        seconds = Math.max(seconds, 0);

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        this.remainingTime = { hours, minutes, seconds, milliseconds };
      }, 1000);
    },
    async fetch() {
      try {
        const { data } = await this.dailyBonusS.lastClaimedBonuses();
        this.lastClaim = data;
        this.upcomingbonus = data.possible_claim; 
        this.remainingTime = data.difference;
      } catch (e) {
        const { message } = getErrorCodeMessage(e, 'Unable to fetch claimed bonuses!');
        this.$swal(
          'Uh-oh',
          'Something went wrong: ' + message,
          'error',
        );
      } finally {
        this.loading = false;
      }
    },
    async claim() {
      this.$swal({
        title: 'Confirm claiming',
        html: `You are claiming  <strong style="color: grey"> `+ parseFloat(this.upcomingbonus.sc).toLocaleString() +` Sweeps Coins & `+parseFloat(this.upcomingbonus.gc).toLocaleString() +` Gold Coins </strong>
              <br><br>You can only claim again after  <strong class="mt-4" style="color: grey">24 Hrs.</strong>`,
        showCancelButton: true,
        animation: 'slide-from-top',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          try {
            await this.dailyBonusS.claim();
            await this.fetch();
            this.startTimer();
            let pokerFrame = window.document.getElementById('pokerFrame');
            if (pokerFrame) {
              pokerFrame.contentWindow.postMessage({ action: 'reloadProfile' }, '*');
            }
            this.$swal('', 'Daily Bonus Claimed successfully', 'success');
          } catch (e) {
            const { message } = getErrorCodeMessage(e, 'Unable to claim daily bonus!');
            this.$swal(
              'Uh-oh',
              'Something went wrong: ' + message,
              'error',
            );
          } finally {
            this.loading = false;
          }
        }
      })
      .catch((error) => {});
    },
  },
};
</script>
