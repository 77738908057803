<template>
  <div class="md:px-6 px-2 py-2 max-w-[100%]">
    <div class="px-2 md:py-4 flex flex-row justify-between items-center max-[575px]:px-0">
      <router-link
        to="?tab=bank"
        class="flex items-center justify-start gap-2 py-0 md:py-4">
        <img class="h-8 sm:h-10 md:h-12" src="../../assets/3d/wallet.png" alt="" />
        <p class="font-semibold min-w-max text-md md:text-xl">Quick Buy</p>
      </router-link>
      <div class="flex">
        <button
          v-on:click="scroll(false)"
          type="button"
          class="px-2 py-1 sm:px-4 sm:py-2 text-gray-300 border border-slate-600 rounded-l-xl hover:opacity-60 cursor-pointer">
          <!--          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clip-rule="evenodd" />
          </svg>-->
          <img
            src="../../assets/3d/right-arrow.png"
            class="h-5 sm:h-6 md:h-8 -rotate-180" />
        </button>
        <button
          v-on:click="scroll(true)"
          type="button"
          class="px-2 py-1 sm:px-4 sm:py-2 text-gray-300 border border-slate-600 rounded-r-xl hover:opacity-60 cursor-pointer">
          <img src="../../assets/3d/right-arrow.png" class="h-5 sm:h-6 md:h-8" />
        </button>
      </div>
    </div> 
    <div ref="bundle" class="no-scrollbar bundle home-casino-slider overflow-x-scroll py-2">
    <div
          v-for="(bundle, i) in bundles"
          class="flex flex-col rounded-xl bg-secondary bundle-tile py-4 hover:opacity-75 cursor-pointer"
          :key="i"
          :class="[
            hasBlackFridaySale(bundle) ? 'border-4 border-yellow/80' : ''
          ]"
           v-on:click="onBundleClicked(bundle.price)"
              :disabled="isOnBreak || isDisabled(bundle.price)"
          >
          <div
            class="flex flex-row relative mt-4 sm:ml-8 ml-2 py-0.5 bg-secondary-light sm:text-sm text-[11px]">
            <img
              alt="USD Coin"
              class="h-8 w-8 absolute transform -translate-y-1/2 top-1/2 -left-2 max-[480px]:w-5 max-[480px]:h-5"
              src="../../assets/coins/cash.png" />
            <span class="ml-8 max-[480px]:ml-5"
              >{{ bundle.price }} free Sweeps Coins</span
            >
          </div>
          <div class="flex flex-col items-center justify-center p-2">
            <img
              class="w-24 max-[480px]:w-16 max-[480px]:mb-2"
              :src="bundle.img"
              alt="coins" />
            <span>{{ (bundle.price * 10000).toLocaleString() }}</span>
            <span class="text-xs text-primary-text">Gold Coins</span>
            <button
             
              class="btn-bet bg-accent text-white shadow-btn rounded rounded-2xl hover:opacity-75 px-4 py-3 mt-3 w-3/4 max-[575px]:px-3 max-[575px]:mt-2 max-[575px]:w-1/1 max-[575px]:text-sm max-[575px]:rounded-lg">
                

               <div v-if="hasBlackFridaySale(bundle)" >
                <span class="line-through-large mr-2">${{ bundle.price }}</span>
                <span class="text-lg">${{ getBundlePrice(bundle) }}</span>
                
              </div>
              <div v-else>
                ${{ getBundlePrice(bundle) }}
              </div>


            </button>
          </div>
        </div>
  </div></div>
</template>

<script>
import { computed } from 'vue';
import { balanceStore } from '@/store/pinia/balanceStore';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'QuickBuy',
  props: {
    
    isOnBreak: {
      default: false,
    }
  },
  data() {
    return { 
      scrollAmount: 0, currentIndex: 0,
     };
    
  },
  setup() {
    
    const balS = balanceStore();
    const bundles = balS.getAvailableBundle
    const blackFridayBundles = computed(() => balS.getBlackFridayBundles);
    return { bundles,balS, blackFridayBundles};
  },
  computed: {
    ...mapGetters(['me']),
  },
  methods: {
    ...mapActions(['fetchUser']),
    onBundleClicked(price) {
      if (this.isOnBreak) {
        this.$toast.error('Your "Break-In-Play" is active till: '+this.me.break_till_date);
        return;
      }
       
        this.$router.push({
          path: this.$route.path,
          query:  { tab:'bank',bundle:price},
        }); 
    },
    isDisabled(amount)
      {
        // console.log((this.me.purchase_limit_bundles)?this.me.purchase_limit_bundles.includes(amount):false);
        return (this.me.purchase_limit_bundles)?this.me.purchase_limit_bundles.includes(amount):false;
      },
    scroll(isRight = true) {
      this.setFade(isRight);
      const menu = this.$refs.bundle;

      const slide_width = menu.getElementsByClassName('bundle-tile')[0].clientWidth;

      menu.scrollTo({
        left: this.scrollAmount,
        behavior: 'smooth',
      });
    },
    hasBlackFridaySale(bundle) {
      return   this.me.is_black_friday_sale_available && !!this.blackFridayBundles[bundle.price];
    },
    getBundlePrice(bundle)
    {
      const blackfridaySaleactive = this.blackFridayBundles[bundle.price];
 
      if( this.me.is_black_friday_sale_available && blackfridaySaleactive )
        return this.blackFridayBundles[bundle.price].discounted_price;
      else
        return bundle.price;
    },
    setFade(isRight = true) {
      const menu = this.$refs.bundle;

      if (menu) {
        const width = menu.scrollWidth - menu.clientWidth;
        const total_part = Math.ceil(menu.scrollWidth / menu.clientWidth);
        const all_slide = menu.getElementsByClassName('bundle-tile');
        for (const slide of all_slide) {
          slide.classList.remove('fade');
        }
        const slide_width = menu.getElementsByClassName('bundle-tile')[0].clientWidth;
        const visible_item = Math.ceil(menu.clientWidth / slide_width);

        this.currentIndex = isRight
          ? this.currentIndex + visible_item
          : this.currentIndex - visible_item;

        var next_visible_index = this.currentIndex;

        if (this.currentIndex <= visible_item) {
          next_visible_index = visible_item;
        }

        const all_faded = menu.querySelectorAll(
          '.bundle-tile:nth-child(n+' + next_visible_index + ')',
        );

        for (const fade of all_faded) {
          fade.classList.add('fade');
        }

        const next_visible_item =
          menu.getElementsByClassName('bundle-tile')[next_visible_index - 1];

        if (next_visible_item) {
          this.scrollAmount = next_visible_item.offsetLeft - menu.offsetLeft;
        }

        if (!isRight && this.currentIndex <= 0) {
          this.scrollAmount = 0;
          this.currentIndex = 0;
        }
        if (this.currentIndex >= total_part) {
          this.currentIndex = total_part;
        }
      }
    },
  },
};
</script>

<style scoped>

 .line-through-large {
  text-decoration: line-through;
  text-decoration-thickness: 3px;
}

.bundle.home-casino-slider
{
  grid-auto-columns: calc(22% - 10px);
  gap: 11px;
}

@media (max-width: 992px) {
  .bundle.home-casino-slider {
      grid-auto-columns: calc(33.33% - 10px);
  }
}

@media (max-width: 768px) {
  .bundle.home-casino-slider {
      grid-auto-columns: calc(50% - 10px);
  }
}

 
</style>
