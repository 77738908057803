<template>
  <router-view></router-view>
  <div class="flex h-auto flex-row justify-center p-0 md:min-h-[auto] md:p-8">
    <div class="w-full max-w-[1200px]">
      <div
        class="flex h-auto flex-col-reverse justify-center md:min-h-[auto] xl:flex-row">
        <!-- Side Menu -->
        <game-actions
          :game-data="{ name: 'Keno', id: null }"
          :pre-extras="gameActions"
          class="block rounded-bl-xl rounded-br-xl bg-secondary-light md:hidden" />
        <div
          class="w-full min-w-[300px] bg-side-color px-2 pb-4 md:w-full md:rounded-bl-xl md:rounded-br-none md:rounded-tl-xl md:px-3 md:py-3 xl:w-[300px]">
          <keno-bets
            :currentState="currentState"
            :riskHandler="changeRisk"
            :playHandler="play"
            :playerProfit="profit"
            :autoPick="autoPick"
            :clearTable="clearTable"
            :numberOfSelected="numberOfSelected" />
        </div>
        <div
          class="flex flex-col items-center bg-tertiary md:h-auto md:min-h-[auto] md:w-full md:rounded-tr-xl">
          <div
            class="w-full bg-coinflip bg-no-repeat bg-cover bg-center md:h-full xl:p-5">
            <!-- The Game Grid -->
            <keno-grid
              :currentState="currentState"
              :result="result"
              :stateHandler="changeState"
              :soundHandler="playSound"
              :selected="selected"
              :selectNumber="selectNumber"
              :riskPayments="limits.risks"
              :risk="risk"
              :numberOfSelected="numberOfSelected" />
          </div>
          <game-actions
            :game-data="{ name: 'Keno', id: null }"
            :pre-extras="gameActions"
            class="hidden rounded-br-xl bg-secondary-light md:block" />
        </div>
      </div>

      <game-details
        game="Keno"
        edge="1"
        provider="Chanced Originals"
        class="mt-6 md:mt-12" />
    </div>
  </div>
  <game-history-table class="hidden md:flex" />
</template>

<script>
import { defaultConvertor } from '../../lib/rsgp';
import { mapActions, mapGetters } from 'vuex';
import KenoGrid from './KenoGrid.vue';
import KenoBets from './KenoBets.vue';
import KenoFairness from './KenoFairness.vue';
import { betSound, autoPickSound } from './sounds';
import GameHistoryTable from '../GameHistory/BetsHistory.vue';
import GameActions from '../Shared/GameActions.vue';
import GameDetails from '../Shared/GameDetails.vue';
import axios from '@/lib/myAxios';
export default {
  components: {
    KenoGrid,
    KenoBets,
    KenoFairness,
    GameHistoryTable,
    GameActions,
    GameDetails,
  },
  props: {},
  created() {
    this.fetchLimits();
  },
  data: () => ({
    showFairness: false,
    currentState: 'notStarted',
    limits: [],
    result: [],
    selected: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      17: 0,
      18: 0,
      19: 0,
      20: 0,
      21: 0,
      22: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      27: 0,
      28: 0,
      29: 0,
      30: 0,
      31: 0,
      32: 0,
      33: 0,
      34: 0,
      35: 0,
      36: 0,
      37: 0,
      38: 0,
      39: 0,
      40: 0,
    },
    gameActions: [
      ['/casino/keno/rules', 'Rules'],
      // ['/casino/keno/fairness', 'Fairness'],
    ],
    risk: 'classic',
    profit: 0,
  }),
  computed: {
    ...mapGetters(['me', 'userBalance']),
    ...mapGetters('Keno', ['clientSeed']),
    ...mapGetters('Sound', ['isSoundMuted']),
    numbers() {
      const result = [];
      for (let i in this.selected) {
        if (this.selected.hasOwnProperty(i) && this.selected[i]) {
          result.push(i);
        }
      }
      return result;
    },
    numberOfSelected() {
      let res = 0;
      for (let i = 1; i <= 40; ++i) {
        res += this.selected[i];
      }

      return res;
    },
  },
  methods: {
    ...mapActions('Keno', ['updateNonce']),
    ...mapActions('Sound', ['toggleSound']),
    rsgp(x) {
      return defaultConvertor.format(x);
    },
    playSound(sound) {
      if (!this.isSoundMuted) {
        sound.play();
      }
    },
    selectNumber(n) {
      this.result = [];
      this.selected[n] ^= 1;
    },
    play(wager) {
      this.playSound(betSound);
      this.result = [];
      this.currentState = 'inProgress';
      try {
        axios
        .post(`keno`, {
          wager: wager,
          risk: this.risk,
          guess: this.numbers,
        })
        .then((res) => res.data)
        .then((state) => {
          this.updateNonce();
          this.result = state.numbers;
          this.profit = state.won;
        })
        .catch((e) => {
          const { data, status } = e;
          this.currentState = 'failed';
          if (status === 403) {
            this.$swal('Uh-oh', 'Not allowed to play: ' + ((typeof data.error != 'undefined')?data.error:''), 'error');
          } else {
            if ((typeof data.error != 'undefined') && data.error === 'Unauthenticated.') {
              this.$router.replace('?tab=login');
            } else {
              this.$swal(
                'Uh-oh',
                'Something went wrong when placing your play: ' + ((typeof data.error != 'undefined')?data.error:''),
                'error',
              );
            }
          }
        });
      } catch(error) {
        console.log('error while play');
        this.currentState = 'failed';
      }
    },
    changeState(newState) {
      this.currentState = newState;
    },
    changeRisk(newRisk) {
      this.result = [];
      this.risk = newRisk;
    },
    async autoPick() {
      if (this.currentState === 'picking' || this.currentState === 'inProgress') {
        return;
      }
      let pickNo = 1;
      let leftToSelect = 10 - this.numberOfSelected;
      if (!leftToSelect) {
        this.clearTable();
        leftToSelect = 10;
      }
      this.changeState('picking');
      this.result = [];
      const pickedNow = [];
      while (leftToSelect) {
        const num = Math.floor(Math.random() * 40 + 1);
        if (!this.selected[num] && !pickedNow.includes(num)) {
          setTimeout(
            function (self, num, leftToSelect) {
              self.selected[num] = 1;
              self.playSound(autoPickSound);
              if (leftToSelect === 1) {
                self.changeState('notStarted');
              }
            },
            pickNo * 120,
            this,
            num,
            leftToSelect,
          );
          pickNo++;
          leftToSelect--;
          pickedNow.push(num);
        }
      }
    },
    clearTable() {
      if (this.currentState === 'picking' || this.currentState === 'inProgress') {
        return;
      }
      this.result = [];
      for (let i in this.selected) {
        if (this.selected.hasOwnProperty(i)) {
          this.selected[i] = 0;
        }
      }
    },
    fetchLimits() {

      try {
        axios
        .get('keno/limits')
        .then(({ data }) => {
          this.limits = data;
        })
        .catch((e) => {
          const { data, status } = e;
          if (status === 403) {
            this.$swal('Uh-oh', 'Not allowed to play: ' + (((typeof data.error != 'undefined'))?data.error:''), 'error');
          } else {
            if (data.error === 'Unauthenticated.') {
              //ignore
              // window.swal({
              //   text: "Error fetching your limits please Login or Register", icon: "warning", dangerMode: true,
              //   showCancelButton: true,
              //   confirmButtonText: "Login/Register",
              // })
              //   .then((result) => {
              //     if (result) {
              //       if(result.isConfirmed){
              //         this.$router.replace('?tab=login')
              //       }
              //     }
              //   })
            }
          }
        });
      } catch(error) {
        console.log('error while checking limits');
      }
    },
  },
};
// test
</script>
