<template>
  <punt-modal
    size="md:max-w-xl max-w-[92%] "
    :showClose="showclose"
    :onClose="closeModal">
    <template #title></template>
    <template #body>
      <div
        class="modal bonus-modal is-active flex min-h-[300px] flex-col items-center space-y-2 text-slate-300">
        <!--<img
            v-on:click="reload"
            class="blogo  max-h-8 hover:opacity-80"
            src="../assets/logo.png"
          />
          -->
        <img v-on:click="reload" class="main-img mb-5" :src="getBonusBackground()" />
        <div class="text-block">
          <h3 class="font-bold mb-3 text-3xl text-center">Welcome to Chanced!</h3>

          <p>{{ getDescription() }}</p>
          <p class="mt-3">
            <a
              target="_blank"
              v-if="this.buttondisabled === 0"
              :href="verifylink"
              class="mr-8 inline-block gap-2 rounded-xl bg-accent font-bold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 disabled:opacity-5">
              Verify Now
            </a>
            <button
              v-if="this.buttondisabled === 0"
              v-on:click="closeModal"
              class="gap-2 inline-block rounded-xl bg-[#252546] font-bold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto px-5 py-3 disabled:opacity-5">
              Verify Later
            </button>
          </p>
        </div>

        <!--<div
            class="flex flex-row items-center justify-center gap-4 px-4 py-8 text-sm text-slate-300"
          >
            <a href="https://twitter.com/therealpunt" target="_blank">
              <img
                class="h-5 w-5 hover:scale-105 hover:opacity-80"
                src="../assets/twitter.svg"
              />
            </a>
            <a href="https://instagram.com/therealpunt" target="_blank">
              <img
                class="h-5 w-5 hover:scale-105 hover:opacity-80"
                src="../assets/instagram.svg"
              />
            </a>
          </div> -->
      </div>
    </template>
  </punt-modal>
</template>

<script>
import PuntModal from '@/components/Shared/PuntModal.vue';
import { authStore } from '@/store/pinia/authStore';
import default_bonus_background from '@/assets/welcome-bouns.png';
import pragmatic_bonus_background from '@/assets/pragmatic-welcome-bonus.webp';
import relax_bonus_background from '@/assets/welcome-bouns.png';
export default {
  components: { PuntModal },
  props: {
    onClose: {
      type: String,
      required: false,
      default: function () {
        return 'close';
      },
      validator: function (x) {
        return ['close', 'back'].includes(x);
      },
    },
    free_spin_provider: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      limits: false,
      error: null,
      fetching: false,
      loading: false,
      session: null,
      bonus_background: default_bonus_background,
      buttondisabled: 1,
      verifylink: '',
      showclose: true,
    };
  },
  setup() {
    return { authS: authStore() };
  },
  async created() {
    await this.fetchVerification();

    this.getVerificationLink();
  },
  methods: {
    reload() {
      window.location.replace('/');
    },
    async fetchVerification() {
      try {
        this.loading = true;
        if (!this.session) {
          const { data } = await this.authS.fetchVerify();
          if (data.session) {
            this.session = data.session;
          }
        }
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    async getVerificationLink() {
      try {
        this.loading = true;
        const { data } = await this.authS.verify();
        this.session = data;
        if (this.session.status === 'approved') {
          this.$router.replace({
            path: this.$route.path,
            query: { tab: 'bank', action: 'redeem' },
          });
          window.location.reload();
        } else {
          this.verifylink = this.session.url;
        }
        this.buttondisabled = 0;
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    closeModal() {
      if (this.buttondisabled === 1) return;
      this.$router.push({ path: '/' }).then(() => {
        this.$router.go();
      });
    },
    getBonusBackground() {
      if(this.free_spin_provider === 'pragmatic') {
        this.bonus_background = pragmatic_bonus_background;
      }
      else if(this.free_spin_provider === 'relax') {
        this.bonus_background = relax_bonus_background;
      }

      return this.bonus_background;
    },
    getDescription() {
      if(this.free_spin_provider === 'pragmatic') {
        return 'Verify your identity now to claim your FREE 20 Spins on Gates of Olympus!';
      }
      else if(this.free_spin_provider === 'relax') {
        return 'Verify your identity now to claim your FREE 20 Spins on Eye Of The Panda!';
      }

      return 'Verify your identity now to claim your FREE 20 Spins on Eye Of The Panda!';
    }
  },
};
</script>
