import swal from 'sweetalert2';
import { doRecaptcha } from './recaptcha';
import axios from '@/lib/myAxios';

function attemptLogin(credentials) {
  credentials.recaptcha = 'token';
  return axios.post('/token-login', credentials).then(({ data }) => {
    window.swal.close();
    return data;
  });
}

function promptToken() {
  return window.swal({
    input: 'text',
    showCancelButton: true,
    title: 'Enter your 2FA token to continue',
    inputValidator: function (value) {
      return new Promise((resolve, reject) => {
        if (/^[\d]{6}$/.test(value)) {
          resolve();
        } else {
          reject(new Error('Token should be 6 digits in length.'));
        }
      });
    },
  });
}

function perform2FA(phoneNumber) {
  return window.swal.fire({
    title:
      'We texted you a verification code to your cell phone number. Please enter the code to verify!',
    input: 'number',
    inputValue: '',
    inputPlaceholder: 'Enter code',
    showCancelButton: true,
    confirmButtonText: 'Submit',
    showLoaderOnConfirm: true,
    customClass: {
      input: 'mySwalInput',
    },

    inputValidator: (value) => {
      if (value.length !== 6) {
        return 'Code needs to 6 characters';
      }
    },
    preConfirm: (verificationCode) => {
      return axios
        .post('user/verify-code', {
          phone_number: phoneNumber,
          verification_code: verificationCode,
          first_time: false,
        })
        .then((res) => {
          return res.json();
        })
        .catch(({ data }) => {
          if (data) {
            window.swal.showValidationMessage(`Error: ${((typeof data.error != 'undefined'))?data.error:''}`);
          }
        });
    },
    allowOutsideClick: () => !window.swal.isLoading(),
  });
}

/**
 * Confirm Account Restore in Case of Account close requested and in waiting period of 30 days.
 * @author Shani Singh
 * @date 12/15/2023
 */
function confirmAccountRestore() {
  return window.swal.fire({
    title:
      'We recently processed an account deletion request. Do you want to restore your account?',
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return true;
    },
    allowOutsideClick: () => !window.swal.isLoading(),
  });
}

export const login = function (creds) {
  return attemptLogin(creds).catch((x) => {
    window.swal.close();
    const { data, headers, status } = x;
    if (status === 429) {
      let mins = 10;
      if (
        headers &&
        headers.map &&
        headers.map['x-ratelimit-reset'] &&
        headers.map['x-ratelimit-reset'][0]
      ) {
        mins = Math.ceil(
          ((headers &&
            headers.map &&
            headers.map['x-ratelimit-reset'] &&
            headers.map['x-ratelimit-reset'][0]) *
            1000 -
            Date.now()) /
            60e3,
        );
      }
      throw new Error(
        `Too many attempts. Try again in ${mins} minute${mins > 1 ? 's' : ''}`,
      );
    }
    switch (data.code) {
      case 1:
        if((typeof data.error != 'undefined'))
        throw new Error(data.error);
      case 2:
      case 3:
        return promptToken()
          .then((res) => {
            if (!res.isConfirmed) {
              return;
            }
            return attemptLogin({ ...creds, two_factor_code: res.value });
          })
          .catch(({ data }) => {
            if((typeof data.error != 'undefined'))
            throw new Error(data.error);
          });
      case 4:
        return perform2FA(data.phone_number)
          .then((res) => {
            if (!res.isConfirmed) {
              return;
            }
            return attemptLogin({ ...creds, verified: true });
          })
          .catch(({ data }) => {
            if((typeof data.error != 'undefined'))
            throw new Error(data.error);
          });
      case 5:
        return confirmAccountRestore()
          .then((res) => {
            if (!res.isConfirmed) {
              return;
            }
            return attemptLogin({ ...creds, restore: true });
          })
          .catch(({ data }) => {
            if((typeof data.error != 'undefined'))
            throw new Error(data.error);
          });
      case 6:
        if((typeof data.error != 'undefined'))
        throw new Error(data.error);
    }
  });
};
