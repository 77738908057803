<template>
    <div :class="`accordian ${isOpen?'open':''}`" @click="toggleAccordion()">
        <h3 class="question" :aria-expanded="isOpen" :aria-controls="`collapse${item.id}`" >{{item.que}}
        <svg class="arr" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 14.25L7.75 8L1.5 1.75" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        </h3>
        <div :class="`content ${isOpen?'open':''}`" v-html="item.ans" v-show="isOpen" :id="`collapse${item.id}`">
           
        </div>
    </div>
</template>

<script>
export default {
  name: 'VipAccordian',
  props : ['item'],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
}
</script>

<style>
.accordian .content a{
    color: rgba(255,255,255,0.8);
    text-decoration: underline;
}
</style>

<style lang="scss" scoped>
    .accordian{
        padding: 14px;
        @apply bg-corbeau;
        border-radius: 10px;
        margin-bottom: 9px;
        cursor:pointer;
        .question{
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
            position: relative;
            padding-right: 28px;
            .arr{
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                transition: 0.2s transform;
            }
        }
        .question[aria-expanded = true]{
            margin-bottom: 8px;
        }
        .content{
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            @apply text-pango-black;
            
        }
    }
    .accordian.open .question .arr{
        transform: translateY(-50%) rotate(90deg);
        path{
            @apply stroke-onprimary;
        }
    }
    @media screen and (max-width:992px){
        .accordian{
            @apply bg-primary;
            .question{
                font-size:14px;
            }
            .content{
                font-size:12px;
                line-height: 21px;
            }
        }
    }
</style>