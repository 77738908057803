import Originals from '../../assets/sidebar/original.png';
import Slots from '../../assets/sidebar/slot.png';
import Live from '../../assets/sidebar/livec.png';
import GameShows from '../../assets/sidebar/gameshows.png';
import NewReleases from '../../assets/sidebar/newreleases.png';
import TableGames from '../../assets/sidebar/tablegames.png';
import Blackjack from '../../assets/sidebar/blackjack.png';
import Roulette from '../../assets/sidebar/roulette.png';
import Baccarat from '../../assets/sidebar/baccarat.png';
import Poker from '../../assets/sidebar/poker.png';

import Favourites from '../../assets/sidebar/favourites.png';
import Recent from '../../assets/sidebar/recent.png';
import Challenges from '../../assets/sidebar/challenges.png';
import Pvp from '../../assets/sidebar/playevplayer.png';
import giveaway from '../../assets/sidebar/giveaway.webp';
import ReferFriend from '../../assets/sidebar/refer-a-friend.png';
import mybets from '../../assets/sidebar/mybets.png';
import Trending from '../../assets/sidebar/trendingwebp.webp';
import Lucky from '../../assets/sidebar/lucky.png';

export const icons = {
  Originals,
  Slots,
  Pvp,
  Live,
  GameShows,
  NewReleases,
  TableGames,
  Blackjack,
  Roulette,
  Baccarat,
  Poker,
  Favourites,
  Recent,
  Challenges,
  giveaway,
  ReferFriend,
  mybets,
  Trending,
  Lucky,
};
