<template>
  <div id="73941-o1rczjy7" class="sw_container"></div>
</template>

<script setup>
import { onMounted } from 'vue';

onMounted(() => {
  var website_url = 'https://sweepwidget.com',
    tokenKey = 'sw_token_key';
  const store = {
    store: window.localStorage,
    get(e) {
      const t = this.store.getItem(e);
      return null == t ? '' : t;
    },
    set(e, t) {
      this.store.setItem(e, t);
    },
  };
  function addListener() {
    // Determine the correct event listener method based on browser compatibility
    const addEvent = window.addEventListener
      ? window.addEventListener.bind(window)
      : window.attachEvent.bind(window, 'onmessage');

    addEvent('message', (event) => {
      try {
        if (event.data && typeof event.data === 'string' && event.data.includes('command')) {
          const parsedData = JSON.parse(event.data);
          const commandData =
            typeof parsedData === 'string' ? JSON.parse(parsedData) : parsedData;

          if (commandData && typeof commandData.command === 'string') {
            switch (commandData.command) {
              case 'SET':
                if (commandData.token) {
                  store.set(tokenKey, commandData.token);
                } else {
                  console.warn('SET command received without a valid token.');
                }
                break;
              case 'GET':
                const response = JSON.stringify({
                  responseType: 'token',
                  token: store.get(tokenKey) || null, // Return null if token doesn't exist
                });
                event.source.postMessage(response, '*');
                break;
              default:
                console.warn(`Unhandled command: ${commandData.command}`);
            }
          } else {
            console.warn('Invalid command structure or missing "command" key.', commandData);
          }
        }
      } catch (error) {
        console.error('Error processing message:', error, event.data);
      }
    });
  }
  function loadScript(e, t) {
    var n = document.createElement('script');
    (n.type = 'text/javascript'),
      n.readyState
        ? (n.onreadystatechange = function () {
            ('loaded' != n.readyState && 'complete' != n.readyState) ||
              ((n.onreadystatechange = null), t());
          })
        : (n.onload = function () {
            t();
          }),
      (n.src = e),
      document.getElementsByTagName('head')[0].appendChild(n);
  }
  function iframe_resizer() {
    loadScript(website_url + '/external/ui/iframe/js/iframeResizer.js', function () {
      iFrameResize({ checkOrigin: !1, heightCalculationMethod: 'lowestElement' });
    });
  }
  function init_widget(e, t, n) {
    n = document.getElementsByClassName('sw_container')[t].id;
    var a = document.getElementsByClassName('sw_container')[t].className,
      s =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('referral-hash') || '',
      o =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('data-email-prefill') || '',
      i =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('data-name-prefill') || '',
      r =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('data-auto-login-prefill') || '',
      l =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('data-if-widget-popup') || '',
      d =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('data-widget-popup-delay') || '',
      c =
        document
          .getElementsByClassName('sw_container')
          [t].getAttribute('data-language') || '',
      m = (a.split(' ')[1], window.location.href),
      u = e + '/c/' + n + '-embedded';
    s && (u += '/' + s),
      (u += '?parent_url_full=' + encodeURIComponent(m)),
      (o || i) && (u += '&email=' + o + '&name=' + i + '&auto_login=' + r),
      c && (u += '&language=' + c),
      iframe_resizer();
    var g = document.getElementById(n);
    1 == l &&
      ((g.style.position = 'absolute'),
      (g.style.zIndex = '9999999999999999'),
      (g.style.paddingTop = '50px'),
      (g.style.top = '0px'),
      (g.style.left = '0px'),
      (g.style.width = '100%'),
      (g.style.height = '100%'),
      (g.style.display = 'none'),
      (d = 1e3 * parseInt(d)));
    var p = document.createElement('iframe');
    p.setAttribute('src', u),
      p.setAttribute('id', 'sw_iframe_' + n),
      p.setAttribute('width', '100%'),
      p.setAttribute('height', 600),
      p.setAttribute('scrolling', 'no'),
      p.setAttribute('frameborder', 'no'),
      g.appendChild(p),
      1 == l &&
        setTimeout(function () {
          (g.style.display = 'block'),
            (document.body.scrollTop = document.documentElement.scrollTop = 0);
        }, d);
  }
  addListener();
  for (
    var sw_class_count = parseInt(document.querySelectorAll('.sw_container').length, 10),
      i = 0;
    i < sw_class_count;
    i++
  ) {
    var competition_url_full = document.getElementsByClassName('sw_container')[i].id;
    init_widget(website_url, i, competition_url_full);
  }
});
</script>
