<template>
  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full sm:py-2 sm:px-6">
      <div v-if="!loading">
        <table class="w-full table-auto md:table-fixed">
          <thead class="">
            <tr>
              <th class="px-6 py-3 text-sm text-left text-gray-400" scope="col">
                Available
              </th>
              <th class="px-6 py-3 text-sm text-left text-gray-400" scope="col">
                Redeemed
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in listClaims" v-bind:key="index">
              <td class="px-6 py-4 text-sm font-medium text-slate-300">
                <span class="inline mt-1 text-sm text-white">
                  {{ item.available_commission }}
                  <img
                    :src="getIcon(item.currency)"
                    alt="Currency Icon"
                    class="inline w-4 h-4" />
                </span>
              </td>
              <td class="px-6 py-4 text-sm font-medium text-slate-300">
                <span class="inline mt-1 text-sm text-white">
                  {{ item.redeemed_commission }}
                  <img
                    :src="getIcon(item.currency)"
                    alt="Currency Icon"
                    class="inline w-4 h-4" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-else
        class="flex flex-row items-center justify-center flex-grow h-full p-8 rounded-md min-h-40">
        <svg
          class="w-10 h-10 mr-3 -ml-1 text-white animate-spin"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"></circle>
          <path
            class="opacity-75"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            fill="currentColor"></path>
        </svg>
        Please wait ...
      </div>
    </div>
  </div>

  <div class="flex items-center justify-center">
    <button
      v-on:click.prevent="widthrawAmount"
      :disabled="
        totalAvailableClaim === 0 ||
        this.listClaims.length === 0 ||
        isWithdrawClaimDisable
          ? true
          : false
      "
      class="flex-inline flex h-10 flex-row items-center justify-center gap-2 rounded-xl bg-accent font-semibold text-white/90 transition delay-0 duration-150 ease-in-out hover:scale-105 hover:opacity-70 md:h-12 md:w-auto md:px-5 md:py-3 bg-prim-highlight shadow-btn shadow-accent px-2 max-[400px]:text-[14px]">
      Withdraw Available Commission
    </button>
  </div>
</template>

<script>
import { affiliatesStore } from '@/store/pinia/affiliatesStore';
import { mapGetters, mapActions } from 'vuex';
import { getBalanceIcon } from '@/lib/icons';
import { balanceStore } from '@/store/pinia/balanceStore';
import axios from '../../lib/myAxios';
export default {
  name: 'Funds',
  setup() {
    const balS = balanceStore();
    const affStore = affiliatesStore();
    return { affStore, balS };
  },
  data() {
    return {
      listClaims: [],
      loading: true,
      isWithdrawClaimDisable: false,
      totalAvailableClaim: 0,
    };
  },

  mounted() {
    if (this.me.id) {
      this.fetchAll();
    }
  },
  methods: {
    async fetchAll() {
      this.loading = true;
      try {
        this.affStore.fetchClaims(this.me.id).then((res) => {
          this.listClaims = res.data;
          this.totalAvailableClaim = this.listClaims.reduce(
            (a, b) => a + b.available_commission,
            0,
          );
        });
      } catch (e) {
        this.$toast.error('Cannot fetch claims! <br>' +  ((typeof e.data.error !=="undefined")?e.data.error:'') );
      }
      this.loading = false;
    },

    async widthrawAmount() {
      if (this.isWithdrawClaimDisable === false) {
        this.isWithdrawClaimDisable = true;
        if (this.listClaims === null || this.listClaims.length === 0) {
          this.$toast.error('Nothing to claim');
          this.isWithdrawClaimDisable = false;
          return;
        }
        try {
          axios
            .post(`/affiliate/${this.me.id}/withdraw`)
            .then((res) => {
              this.$toast.success('Operation Completed');
              this.fetchAll();
              this.isWithdrawClaimDisable = false;
            })
            .catch((err) => {
              this.$toast.error('Cannot widthraw commission! <br>' + err.data.message);
              this.isWithdrawClaimDisable = false;
            });
        } catch (e) {
          this.$toast.error('Cannot widthraw commission! <br>' + e.message);
        }
      }
    },
    getIcon(currency) {
      return getBalanceIcon(currency);
    },

    ...mapActions(['fetchClaims']),
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn', 'userBalance']),
  },
};
</script>
