<template>
    <RaceModal :race="activeRace" @close="closeModal" v-if="this.showModal" />
    <div class="flex w-full flex-col items-center">
        <div class="flex w-full max-w-[1200px] flex-col">
            <div
                class="relative justify-between flex w-full items-center justify-left align-center gap-2 bg-secondary/30 px-4 py-4 md:px-6 md:pb-0">
                <div class="flex flex-wrap items-center md:gap-5 gap-x-3 gap-y-0">
                    <h1 class="py-4 text-2xl font-bold text-slate-300">Races</h1>
                </div>
            </div>
            <div v-if="false"
                class="relative flex items-center justify-end w-full gap-2 px-4 py-4 text-slate-300 md:px-6">
                <div class="flex flex-row items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="hidden w-5 h-5 rotate-90 md:block" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                    </svg>
                </div>
            </div>
            <div class="px-4 py-2 md:px-6 md:py-4 md:pt-0">
                <div class="flex h-5 items-center justify-center" v-if="loading">
                    <svg class="h-10" id="L4" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                        enable-background="new 0 0 0 0" xml:space="preserve">
                        <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                            <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite"
                                begin="0.1" />
                        </circle>
                        <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                            <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite"
                                begin="0.2" />
                        </circle>
                        <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                            <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite"
                                begin="0.3" />
                        </circle>
                    </svg>
                </div>
                <div class="flex w-full">
                    <div class="flex w-full flex-row items-start md:py-2 sm:px-0 py-0">
                        <perfect-scrollbar ref="hScrollbar"
                            class="no-scrollbar flex overflow-x-scroll rounded-full bg-secondary">
                            <div
                                class="w-fsull ovesrflow-x-scroll grid w-full auto-cols-max grid-flow-col grid-cols-none grid-rows-1 flex-nowrap gap-0.5 sm:gap-3 p-2">
                                <button :onclick="toggleActiveTab"
                                    class="flex h-10 w-full min-w-fit items-center justify-center space-x-2 rounded-full px-4 py-2.5 text-sm font-medium leading-5 text-white focus:outline-none">Active</button>
                                <!-- <button :onclick="toggleUpcomingTab"
                                    class="flex h-10 w-full min-w-fit items-center justify-center space-x-2 rounded-full px-4 py-2.5 text-sm font-medium leading-5 text-white focus:outline-none">Upcoming</button> -->
                            </div>
                        </perfect-scrollbar>
                    </div>
                </div>
                <div
                    class="grid-rows-auto mt-4 grid w-full gap-3 md:gap-4 grid-cols-2 sm:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
                    <div class="flex flex-col rounded-md relative shadow-md transition delay-0 duration-150 ease-in-out hover:rotate-1 hover:scale-105 overflow-hidden"
                        v-for="(item, i) in this.tab === 'active' ? this.activeItems : this.upcomingItems" :key="i"
                        :ref="'race-ref-' + item.id">
                        <div @click="openModal(item)" class="description p-4 bg-secondary">
                            <p class="text-white font-bold max-[767px]:text-sm">
                                {{ item.name }}
                            </p>
                            <img v-if="item.image_name" :src="getRaceImage(item.image_name)" >
                            <img v-else-if="item.elligible_games==='all'" src='../assets/allgames.webp'>
                            <img v-else-if="item.elligible_games==='slot'" src='../assets/slotgames.webp'>
                            <img v-else-if="item.elligible_games==='live'" src='../assets/livedealers.webp'>
                            <img v-else-if="item.elligible_games==='original'" src='../assets/allgames.webp'>
                            <div
                                class="grid grid-cols-4 gap-1 items-center text-white text-center text-sm max-w-fit my-4">
                                <div class="bg-gray-950">
                                    <div>
                                        {{ this.timeUntilRace(new Date(item.end_time) - this.now).days }}
                                    </div>
                                    <div class="text-slate-400 mx-2 max-w-12">Days</div>
                                </div>
                                <div class="bg-gray-950">
                                    <div>
                                        {{ this.timeUntilRace(new Date(item.end_time) - this.now).hours }}
                                    </div>
                                    <div class="text-slate-400 mx-2 max-w-12">Hours</div>
                                </div>
                                <div class="bg-gray-950">
                                    <div>
                                        {{ this.timeUntilRace(new Date(item.end_time) - this.now).minutes }}
                                    </div>
                                    <div class="text-slate-400 mx-2 max-w-12">Min</div>
                                </div>
                                <div class="bg-gray-950">
                                    <div>
                                        {{ this.timeUntilRace(new Date(item.end_time) - this.now).seconds }}
                                    </div>
                                    <div class="text-slate-400 mx-2 max-w-12">Sec</div>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 pb-2">
                                    <div>Prize: {{ item.prize }} <img v-if="item.game_mode==='SC'" src="/src/assets/coins/cash.png" class="max-w-5 inline"/><img v-else class="h-5 w-5 inline" src="/src/assets/coins/gold.png"></div>
                                    
                                    <div>Winners: {{ JSON.parse(item.payouts).winners.length}}</div>

                            </div>
                            <div class="grid grid-cols-1 pb-2">
                                    <div>Your Played: {{ userProgress.find((progress)=> progress.race_id===item.id)?.score|| 0}} </div>

                            </div>
                            <div class="text-sm">{{ item.description }}</div>
                        </div>
                    </div>
                </div>

                <div v-if="items.length > 0" class="mt-8 flex flex-col items-center justify-center">
                    <span class="flex h-5 items-center justify-center" v-if="loading">
                        <svg class="h-10" id="L4" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                            enable-background="new 0 0 0 0" xml:space="preserve">
                            <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                                <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite"
                                    begin="0.1" />
                            </circle>
                            <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                                <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite"
                                    begin="0.2" />
                            </circle>
                            <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                                <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite"
                                    begin="0.3" />
                            </circle>
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { raceStore } from '../store/pinia/raceStore';
import RaceModal from '@/components/RaceModal.vue';
import { getRaceImage } from "@/utils";
export default {
    name: 'Races',
    components: {
        RaceModal,
    },
    props: {
        type: {
            default: null,
        },
    },
    setup() {
        const store = raceStore();
        return { store }
    },
    computed: {
        ...mapGetters(['me', 'isLoggedIn']),
    },

    async created() {
        this.init();
        this.clockInterval = setInterval(() => {
            this.now = new Date()
        }, 1000)
    },

    unmounted(){
    clearInterval(this.clockInterval);
  },

    data() {
        return {
            isCreate: false,
            loading: false,
            items: [],
            activeItems: [],
            futureItems: [],
            activeRace: null,
            current_page: 0,
            last_page: 0,
            per_page: 0,
            total: 0,
            tab: 'active',
            now: new Date(),
            showModal: false,
            userProgress:[],

            providers: [],
            sortOrde33r: '',
            sortType: 'desc',
        };
    },
    methods: {
        getRaceImage,
        async init() {
            try {
                const response = await this.store.fetchAll()
                const userResponse = await this.store.fetchUserLeaderboardProgress(this.me.id);
                this.items = response.data;
                this.activeItems = response.data.filter((item) => new Date(item.start_time) < this.now && new Date(item.end_time) > this.now)
                this.upcomingItems = response.data.filter((item) => new Date(item.start_time) > this.now)
                this.userProgress=userResponse.data;
                
            } catch (e) {
            } finally {
                this.loading = false;
            }
        },

        toggleActiveTab() {
            this.tab = 'active';
        },

        toggleUpcomingTab() {
            this.tab = 'upcoming';
        },

        openModal(item) {
            this.activeRace = (item)
            this.showModal = true;
        },

        closeModal() {
            this.showModal = false;
        },

        timeUntilRace(distance) {
            const seconds = Math.floor((distance / 1000) % 60);
            const minutes = Math.floor((distance / 1000 / 60) % 60);
            const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            return ({ 'seconds': seconds, "minutes": minutes, "hours": hours, "days": days })
        },


    },
};
</script>
