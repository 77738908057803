import axios from 'axios';
import { abortBlocked, isBlocked } from './utils';

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
axios.defaults.withCredentials = false;
axios.defaults.validateStatus = function (status) {
  return true;
};
axios.interceptors.request.use(
  (config) => {
    config.headers.Accept = `application/json`;
    if (!config.headers.Authorization) {
      const token =
        'nexus-token' in window.localStorage ? window.localStorage['nexus-token'] : null;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    if (!config.headers['X-Socket-ID'] && window.Echo) {
      config.headers['X-Socket-ID'] = window.Echo.socketId();
    }

    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['Access-Control-Allow-Methods'] = '*';
    config.headers['Access-Control-Max-Age'] = '86400';

    return config;
  },
  (error) => Promise.reject(error),
);
axios.interceptors.response.use(
  function (response) {
    if (isBlocked()) {
      //window.location.replace('/')
    }
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    if (response.status === 401) {
      delete window.localStorage['nexus-token'];
      window.location.reload();
    }
    if (response.status === 409) {
      const isBanned = window.localStorage['ip-banned'] !== undefined;
      if (!isBanned) {
        window.localStorage['ip-banned'] = true;
        window.location.reload();
      }
    }
    if (response.status === 451) {
      abortBlocked();
    }
    if (response.status === 503) {
      window.location.href = "/maintenance.html";
    }

    return Promise.reject(response);
  },
  function (error) {
    const { status } = error;
    if (status && status === 451) {
      abortBlocked();
    }
    if (status && status === 409) {
      const isBanned = window.localStorage['ip-banned'] !== undefined;
      if (!isBanned) {
        window.localStorage['ip-banned'] = true;
        window.location.reload();
      }
    }
    return Promise.reject(error);
  },
);

export default axios;
